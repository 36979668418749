const quote_lines = [
	{
		id: 'description',
		heading: 'Item Description',
	},
	{
		id: 'erect',
		heading: 'Erect & Dismantle',
	},
	{
		id: 'fee',
		heading: 'Weekly Hire Fee',
	},
]

const quote_addons = [
	{
		id: 'additionalDesc',
		heading: 'Description',
	},
	{
		id: 'additionalTotal',
		heading: 'Additional Costs',
	},
]

const totals = [
	{
		id: 'additionalDesc',
		heading: '',
	},
	{
		id: 'additionalTotal',
		heading: '',
	},
]

const summary = [
	{
		id: 'service',
		heading: 'Service',
	},
	{
		id: 'price',
		heading: 'Price',
	},
]

export const columns = {
	quote_lines,
	quote_addons,
	totals,
	summary,
}

export enum ColumnTypes {
	quote_lines = 'quote_lines',
	quote_addons = 'quote_addons',
	totals = 'totals',
	summary = 'summary',
}
