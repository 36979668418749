import { useContext, useState } from 'react'
import moment from 'moment'
import { DateSelect, Button, ConfirmationDialog } from 'common'
import { InvoiceServices, JobsServices } from 'services'
import { NotificationsContext } from 'context/notifications/toastContext'
import { useUpdateEDInvoice } from 'services/invoices'

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	invoicesSelected: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setInvoicesSelected: any
}

export const RevertInvoiceApproval = ({
	invoicesSelected,
	setInvoicesSelected,
}: IProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const [completed, setCompleted] = useState(false)
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { updateEdInvoice } = InvoiceServices.useUpdateEDInvoice()
	const { updateWeeklyHire } = InvoiceServices.useUpdateWeeklyHireInvoice()

	const revertInvoiceApprovalHandler = async () => {
		if (invoicesSelected) {
			setIsLoading(true)

			const invoicePromises: Promise<void>[] = invoicesSelected.map(
				async (invoice: { id: number; invoiceType: string }) => {
					if (invoice.invoiceType === 'ED Invoice') {
						await updateEdInvoice(invoice.id, {
							status: 'Pending',
							myobReference: null,
						})
					} else {
						await updateWeeklyHire(invoice.id, {
							status: 'Pending',
							myobReference: null,
						})
					}
				}
			)

			Promise.all(invoicePromises)
				.then(() => {
					showSuccess('All invoices reverted to pending successfully!')
				})
				.catch((error) => {
					console.log({ error })
					showError('Something went wrong reverting the invoices.')
				})

			setInvoicesSelected(null)
			setIsLoading(false)
			setCompleted(true)
		}
	}

	return (
		<ConfirmationDialog
			isDone={completed}
			icon="info"
			title="Revert Invoice Approval"
			body="This action will revert the invoices selected back to pending status."
			triggerButton={
				<Button
					size="sm"
					variant="primary"
					onClick={() => {
						null
					}}>
					Revert Invoice Approval
				</Button>
			}
			confirmButton={
				<Button
					size="sm"
					isLoading={isLoading}
					variant="approve"
					onClick={revertInvoiceApprovalHandler}>
					Confirm
				</Button>
			}></ConfirmationDialog>
	)
}
