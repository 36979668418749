import { CheckIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { Input, TextArea, Button, ConfirmationDialog } from 'common'
import { AppStore } from 'redux/store'
import { useSelector } from 'react-redux'
import { useUpdateQuoteStatus } from 'services/quotes/updateQuoteStatus'
import { QuoteAdditionalLineDTO } from 'models'
import { numberFormat } from 'utilities'

interface Props {
	line: QuoteAdditionalLineDTO
	index: number
}

export const QuoteAcceptAdditionalLine = ({ line, index }: Props) => {
	const [isAccepted, setAccepted] = useState(false)

	return (
		<div
			className="border border-gray-300 p-4 my-4 rounded-lg grid grid-cols-1 sm:grid-cols-3 gap-4"
			key={index}>
			{/* Left Column */}
			<div className="col-span-1 sm:col-span-2">
				<div className="text-gray-500 text-sm">{`${line.type}`}</div>
				<h3 className="text-gray-700 font-bold">Description: <span className="text-gray-700 font-normal">{line.itemDescription}</span></h3>
			</div>
			{/* Right Column */}
			<div className="col-span-1">
				<table className="w-full">
					<thead>
						<tr className="border border-gray-300">
							<td className="text-center border w-40">Total Price (ex. GST)</td>
						</tr>
					</thead>
					<tbody>
						<tr className="border border-gray-300">
							<td className="text-center font-semibold w-40 px-4">
								{numberFormat.format(line.price)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}
