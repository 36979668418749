import { Document, Image, Link, Page, Text, View } from '@react-pdf/renderer'
import { QuoteData, IQuoteLine, IQuoteAdditionalLines } from 'models'
import { styles } from './Styles'
import { Heading, ClientInfo } from './Heading'
import { Description } from './Description'
import { ColumnTypes, Table } from './Table'
import { Footer } from './Footer'
import { Greetings } from './Greetings'
import { Closing } from './Closing'

interface QuotePDFDocumentProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
}

export const QuotePDFDocument = ({
	quote_data,
	quote_lines,
	quote_addons,
}: QuotePDFDocumentProps) => {
	const linesTotal = quote_lines
		.map((line) => Number(line.erect_and_dismantle))
		.reduce((acc, curr) => acc + curr, 0)
	const linesHireTotal = quote_lines
		.map((line) =>
			Number(line.hire_period) > 0
				? Number(line.weekly_hire_fee * line.hire_period)
				: 0
		)
		.reduce((acc, curr) => acc + curr, 0)
	const additionalTotal = quote_addons
		.map((addon) => Number(addon.total_cost))
		.reduce((acc, curr) => acc + curr, 0)
	const total = linesTotal + additionalTotal + linesHireTotal
	const files = quote_data?.files
		? quote_data?.files.filter((file: any) => {
				return file.include_in_quote == true
		  })
		: []

	const isImage = (fileUrl: string) => {
		if (!fileUrl) return false
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
		const extension = fileUrl.split('.').pop()?.toLowerCase()
		return extension ? imageExtensions.includes(extension) : false
	}

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={styles.content}>
					<Text style={styles.quoteText}>Quotation</Text>
					<ClientInfo quote={quote_data} />
					<Greetings quote={quote_data} />
					<Description description={quote_data.scope_of_work} />
				</View>
				{/* <Text style={styles.subTextBoldRed}>All prices exclude GST</Text> */}
				<Footer />
			</Page>
			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={styles.content}>
					<Text style={styles.quoteText}>Quotation</Text>
					<ClientInfo quote={quote_data} />
					<View>
						<Text style={styles.heading}>Quote Lines</Text>
						<Table type={ColumnTypes.quote_lines} data={quote_lines} />
					</View>
					<View>
						<Text style={styles.heading}>Additional Items</Text>
						<Table type={ColumnTypes.quote_addons} data={quote_addons} />
					</View>
					<View style={{ marginTop: 20 }}>
						<Table type={ColumnTypes.totals} data={total} />
					</View>
				</View>
				<Closing quote={quote_data} />
				{/* <Text style={styles.subTextBoldRed}>All prices exclude GST</Text> */}
				<Footer />
			</Page>

			{files.length > 0
				? files.reduce((acc: any[], _: any, index: number) => {
						if (index % 4 === 0) {
							acc.push(
								<Page key={index} size="A4" style={styles.page}>
									<Heading />
									<View style={styles.content}>
										<View style={styles.imageRow}>
											{files.slice(index, index + 4).map(
												(
													image: {
														url: string
														description: string
														section_label: string
													},
													imageIndex: number
												) => (
													<View key={imageIndex} style={styles.image}>
														{isImage(image.url) ? (
															<Image
																src={image.url}
																style={{ maxWidth: '100%', maxHeight: '100%' }}
															/>
														) : (
															<Link src={image.url}>
																<Text
																	style={{
																		color: 'blue',
																		textDecoration: 'underline',
																	}}>
																	Click here to view file
																</Text>
															</Link>
														)}
														<View
															style={{
																flexDirection: 'row',
																gap: 5,
																alignItems: 'center',
																marginTop: 5,
															}}>
															<Text
																style={{
																	...styles.subTextBold,
																	fontSize: 10,
																	margin: 0,
																}}>
																Description:
															</Text>
														</View>
														<Text style={styles.imageText}>
															{image.description}
														</Text>
														<View
															style={{
																flexDirection: 'row',
																gap: 5,
																alignItems: 'center',
																marginTop: 20,
															}}>
															<Text
																style={{
																	...styles.subTextBold,
																	fontSize: 10,
																	margin: 0,
																}}>
																Section:
															</Text>
														</View>
														<Text style={styles.imageText}>
															{image.section_label}
														</Text>
													</View>
												)
											)}
										</View>
									</View>
									<Footer />
								</Page>
							)
						}
						return acc
				  }, [])
				: null}

			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={styles.content}>
					<Text style={styles.heading}>Additional Conditions</Text>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						{quote_data.terms}
					</Text>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						<Link src="https://protrade.net.nz/wp-content/uploads/2023/03/ProtradeTermsConditions.pdf">
							View our Terms and Conditions here
						</Link>
					</Text>
				</View>
				<Footer />
			</Page>
		</Document>
	)
}
