import { Spinner, Table } from 'common'
import { PencilIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from 'config'
import { QuoteServices } from 'services'
import { numberFormat } from 'utilities'

interface ClientQuotesTableProps {
	client_id?: number
}

export const ClientQuotesTable = ({ client_id }: ClientQuotesTableProps) => {
	const location = useLocation()
	const [openCreate, setOpenCreate] = useState(false)
	const { data: allQuotes, isLoading } = QuoteServices.useQuotes()
	const quotesForClient = allQuotes?.filter((quote: any) => quote.client === client_id) || []

	if (isLoading) {
		return <Spinner />
	}
	// Columns are: quote_num, clientData.client_name, contactData.name, contactData.email, street, scope_of_work, job_type, estimatorData.staff_name, total, email_status, approveComment, updatedAt
	// 
	const columns = [
		{
			header: 'Quote Number',
			field: 'quote_num',
			body: (rowData: any) => (
				<Link
					to={AppRoutes.privateRoutes.QuotesDetail.replace(':id', rowData.id)}
					className="text-blue-500 hover:underline">
					#{rowData.quote_num}
				</Link>
			),
		},
		{
			header: 'Client',
			field: 'clientData.client_name',
			body: (rowData: any) => (
				<>
					<span className="">{rowData.clientData.client_name}</span>
				</>
			),
		},
		{
			header: 'Contact Name',
			field: 'contactData.name',
			body: (rowData: any) => (
				<>
					<span className="">{rowData.contactData.name}</span>
				</>
			),
		},
		{
			header: 'Contact Email',
			field: 'contactData.email',
			body: (rowData: any) => (
				<>
					<span className="">{rowData.contactData.email}</span>
				</>
			),
		},
		{
			header: 'Site',
			field: 'street',
		},
		{
			header: 'Scope of Work',
			field: 'scope_of_work',
		},
		{
			header: 'Job Type',
			field: 'job_type',
		},
		{
			header: 'Estimator',
			field: 'estimatorData.staff_name',
			body: (rowData: any) => (
				<>
					<span className="">{rowData.estimatorData.staff_name}</span>
				</>
			),
		},
		{
			header: 'Total',
			field: 'total',
			body: (rowData: any) => (
				<>
					<span className="font-bold">{numberFormat.format(rowData.total)}</span>
				</>
			),
		},
		{
			header: 'Email Status',
			field: 'emailStatus',
		},
		{
			header: 'Customer Comment',
			field: 'approveComment',
		},
		{
			header: 'Updated At',
			field: 'updatedAt',
			body: (rowData: any) => (
				<>
					<span className="">{new Date(rowData.updatedAt).toLocaleString()}</span>
				</>
			),
		}
	]


	return (
		<>
			<Table
				title="Quotes"
				columns={columns}
				data={quotesForClient}
				isLoading={isLoading}
				disableButtons
			/>
		</>
	)
}
