import { Text, View } from '@react-pdf/renderer'

import { styles } from './styles'
import { QuoteData } from 'models'
import moment from 'moment'

interface ClosingProps {
	quote: QuoteData
}

export const Closing = ({ quote }: ClosingProps) => {
	const contactName = quote.contactData.name
	const contactEmail = quote.contactData.email
	const estimatorName = quote.estimatorData.staff_name
	const validity = moment(quote.created_at).add(90, 'days').format('DD/MM/YYYY')

	return (
		<>
			<View style={styles.closingContainer}>
				<Text style={styles.body}>
					If you have any further questions or requirements, please don't hesitate to contact us.
				</Text>
				<Text style={styles.body}>
					Regards,
				</Text>
				<Text style={styles.subText}>{estimatorName}</Text>
			</View>
		</>
	)
}
