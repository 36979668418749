import { Font, StyleSheet } from '@react-pdf/renderer'

Font.register({
	family: 'Open Sans',
	src: 'https://fonts.gstatic.com/s/opensans/v23/mem8YaGs126MiZpBA-UFVZ0e.ttf',
})

export const stylesFooter = StyleSheet.create({
	footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    display: 'flex',
		paddingBottom: 20,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  logo: {
		objectFit: 'contain',
		height: 'auto',
		width: '28%',
	},
  contact: {
    fontFamily: 'Open Sans',
    paddingRight: 50,
		fontSize: '8',
  },
  link: {
    textDecoration: 'none',
		fontWeight: 'bold',
    color: 'black'
  }
})
