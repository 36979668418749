import {
	DateSelect,
	Dropdown,
	Input,
	SideModal,
	Spinner,
	TextArea,
} from 'common'
import { useFormik } from 'formik'
import { active_inactive_options } from 'models'
import moment from 'moment'
import { StaffServices, VehicleServices } from 'services'
import { OptionsForDropdown, OptionsForDropdownFilter } from 'utilities'
import * as Yup from 'yup'

const OperationalStatusOptions = [
	{
		value: 'Operational',
		label: 'Operational',
	},
	{
		value: 'Issue',
		label: 'Issue',
	},
]

/*
Hastings, Dunedin, Gisborne, Taupo , Wairarapa, Hawkes Bay, Cromwell
*/

const LocationOptions = [
	{
		value: 'Hastings',
		label: 'Hastings',
	},
	{
		value: 'Dunedin',
		label: 'Dunedin',
	},
	{
		value: 'Gisborne',
		label: 'Gisborne',
	},
	{
		value: 'Taupo',
		label: 'Taupo',
	},
	{
		value: 'Wairarapa',
		label: 'Wairarapa',
	},
	{
		value: 'Hawkes Bay',
		label: 'Hawkes Bay',
	},
	{
		value: 'Cromwell',
		label: 'Cromwell',
	},
]

interface IProps {
	vehicle_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IinitialValues {
	Rego: string
	Make: string
	Model: string
	CodeName: string
	RegoDue: string
	WOFDate: string
	ServiceDueDate: string
	ServiceDueKm: string
	Odometer: string
	Hubometer: string
	RUC: string
	OperationalStatus: string
	Status: string
	Date: string
	Comment: string
	location: string
	assigned_to: string
}

export const VehiclesForm = ({
	vehicle_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data: vehicleData, isLoading: vehicleLoading } =
		VehicleServices.useVehicleById(vehicle_id)
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()

	const { createVehicle } = VehicleServices.useCreateVehicle()
	const { updateVehicle } = VehicleServices.useEditVehicle()

	const initialValues: IinitialValues = {
		Rego: vehicleData?.Rego || '',
		Make: vehicleData?.Make || '',
		Model: vehicleData?.Model || '',
		CodeName: vehicleData?.CodeName || '',
		RegoDue: vehicleData?.RegoDue || '',
		WOFDate: vehicleData?.WOFDate || '',
		ServiceDueDate: vehicleData?.ServiceDueDate || '',
		ServiceDueKm: vehicleData?.ServiceDueKm || '',
		Odometer: vehicleData?.Odometer || '',
		Hubometer: vehicleData?.Hubometer || '',
		RUC: vehicleData?.RUC || '',
		OperationalStatus: vehicleData?.OperationalStatus || 'Operational',
		Status: vehicleData?.Status || 'Active',
		Date: vehicleData?.Date || '',
		Comment: vehicleData?.Comment || '',
		location: vehicleData?.location || 'Hastings',
		assigned_to: vehicleData?.assigned_to || null,
	}

	const validationSchema = Yup.object({
		Rego: Yup.string().required('Rego is required'),
		location: Yup.string().required('Location is required'),
		// Date: Yup.string().required('Date is required'),
		ServiceDueDate: Yup.string().required('Service Due Date is required'),
		WOFDate: Yup.string().required('WOF Date is required'),
		RegoDue: Yup.string().required('Rego Due is required'),
	})

	const formik = useFormik({
		initialValues,
		isInitialValid: (!staffLoading && !vehicleLoading && vehicle_id != undefined),
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				createVehicle(values)
			}
			if (formType === 'update' && vehicle_id) {
				updateVehicle(vehicle_id, values)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (vehicle_id && vehicleLoading && staffLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							id="Rego"
							title="Rego"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Rego"
							type="text"
							autoComplete="off"
							value={formik.values.Rego}
							error={formik.errors.Rego}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="Make"
							title="Make"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Make"
							type="text"
							autoComplete="off"
							value={formik.values.Make}
							error={formik.errors.Make}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							id="Model"
							title="Model"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Model"
							type="text"
							autoComplete="off"
							value={formik.values.Model}
							error={formik.errors.Model}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="CodeName"
							title="Code Name"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Code Name"
							type="text"
							autoComplete="off"
							value={formik.values.CodeName}
							error={formik.errors.CodeName}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<DateSelect
							id="RegoDue"
							onChange={formik.setFieldValue}
							title="Rego Due"
							value={formik.values.RegoDue}
							error={formik.errors.RegoDue}
						/>
					</div>
					<div className="w-1/2">
						<DateSelect
							id="WOFDate"
							onChange={formik.setFieldValue}
							title="WOF Date"
							value={formik.values.WOFDate}
							error={formik.errors.WOFDate}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<DateSelect
							id="ServiceDueDate"
							onChange={formik.setFieldValue}
							title="Service Due Date"
							value={formik.values.ServiceDueDate}
							error={formik.errors.ServiceDueDate}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="ServiceDueKm"
							title="Service Due Km"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Service Due Km"
							type="text"
							autoComplete="off"
							value={formik.values.ServiceDueKm}
							error={formik.errors.ServiceDueKm}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							id="Odometer"
							title="Odometer"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Odometer"
							type="text"
							autoComplete="off"
							value={formik.values.Odometer}
							error={formik.errors.Odometer}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="Hubometer"
							title="Hubometer"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="Hubometer"
							type="text"
							autoComplete="off"
							value={formik.values.Hubometer}
							error={formik.errors.Hubometer}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							id="RUC"
							title="RUC"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							labelInline
							placeholder="RUC"
							type="text"
							autoComplete="off"
							value={formik.values.RUC}
							error={formik.errors.RUC}
						/>
					</div>
					<div className="w-1/2">
						<Dropdown
							id="OperationalStatus"
							label="Operational Status"
							onChange={formik.setFieldValue}
							options={OperationalStatusOptions}
							value={formik.values.OperationalStatus}
							error={formik.errors.OperationalStatus}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="assigned_to"
							label="Assigned to"
							options={OptionsForDropdown(staffData, 'id', 'staff_name')}
							value={formik.values.assigned_to}
							onChange={formik.setFieldValue}
							error={formik.errors.assigned_to}
						/>
					</div>
					<div className="w-1/2">
						<Dropdown
							id="location"
							label="Location"
							onChange={formik.setFieldValue}
							options={LocationOptions}
							value={formik.values.location}
							error={formik.errors.location}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="Status"
							label="Status"
							onChange={formik.setFieldValue}
							options={active_inactive_options}
							value={formik.values.Status}
							error={formik.errors.Status}
						/>
					</div>
					{/* <div className="w-1/2">
						<DateSelect
							id="Date"
							onChange={formik.setFieldValue}
							title="Date"
							value={formik.values.Date}
							error={formik.errors.Date}
						/>
					</div> */}
				</div>
				<div className="flex items-center justify-between">
					<div className="w-full">
						<TextArea
							id="Comment"
							placeholder="Comments"
							rows={4}
							title="Comments"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.Comment}
							error={formik.errors.Comment}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
