import { useState } from 'react'
import { DateSelect, Dropdown, Input, TextArea } from 'common'
import { ContacsForm } from 'components/Contacts'
import { FormikProps } from 'formik'
import { useEffect } from 'react'
import { OptionsForDropdown } from 'utilities'
import { PlusCircleIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

interface IProps {
	formik: FormikProps<{
		client_id: number
		job_type: string
		branding: string
		site: string
		start_date: string
		end_date: string
		job_status: string
		operation_notes: string
		status: string
		PO_Number: string
		Requester: string
		type: string
		description: string
		total_hours: number
		percentage_erect: number
		percentage_dismantle: number
		percentage_complete: number
		LastEditDate: string
		hire_rate: number | null
		task_value: number | null
		branch: string
		sqm_rate: number,
		hourly_rate: number,
		protrade_sales_person: string,
		distance: string,
	}>
	formType: 'create' | 'update'
	clientsData: [{ id: string }]
	contactsData: [{ client_id: string }]
	client_id: string | number
}

const optionsForType = [
	{ label: 'Install', value: 'Install' },
	{ label: 'Dismantle', value: 'Dismantle' },
	{
		label: 'Alteration',
		value: 'Alteration',
	},
]
export const VariationTaskForm = ({
	formik,
	formType,
	clientsData,
	client_id,
	contactsData,
}: IProps) => {
	const client = clientsData?.filter((client) => client.id === client_id)
	const contacts = contactsData?.filter(
		(contact) => client?.[0]?.id === contact.client_id
	)
	const [contactForm, setContactForm] = useState(false)

	useEffect(() => {
		const percentage_total = Number(
			(
				Number(formik.values.percentage_erect * 0.7) +
				Number(formik.values.percentage_dismantle * 0.3)
			).toFixed(2)
		)
		formik.setFieldValue('percentage_complete', percentage_total)
	}, [formik.values.percentage_erect, formik.values.percentage_dismantle])

	if (!client) {
		return null
	}

	return (
		<>
			<div className="flex items-center px-2">
				<div className="w-1/2 pb-6">
					<Input
						title="PO Number"
						id="PO_Number"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.PO_Number}
						placeholder="PO Number"
						error={formik.errors.PO_Number}
					/>
				</div>
				<div className="w-1/2 pb-6">
					<Input
						title="Distance to Office"
						id="distance"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={
							formik.values.distance
								? `${formik.values.distance} KM`
								: '0 KM'
						}
						placeholder="Distance"
						error={formik.errors.distance}
						disabled
					/>
				</div>
				{/* <div className="w-1/2">
					<Dropdown
						id="Requester"
						label="Contact"
						options={OptionsForDropdown(contacts, 'name', 'name')}
						value={formik.values.Requester}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
						error={formik.errors.Requester}
					/>
					<div className="flex items-center pl-2 ">
						<PlusCircleIcon className="w-6 h-6 text-indigo-500" />
						<button
							type="button"
							className={clsx(
								!(client?.[0]?.id ? true : false)
									? 'text-gray-200'
									: 'text-gray-600 hover:text-gray-800',
								'pl-1 font-semibold leading-5 text-sm'
							)}
							disabled={(client?.[0]?.id ? false : true)}
							onClick={() => setContactForm(true)}>
							Add New Contact
						</button>
					</div>
				</div> */}
			</div>
			{/* {formType === 'create' && (
				<div className="flex items-center px-2">
					<Dropdown
						id="type"
						label="Type"
						options={optionsForType}
						value={formik.values.type}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
						error={formik.errors.type}
					/>
				</div>
			)} */}
			<div className="flex items-center">
				<TextArea
					id="description"
					title="Description"
					value={formik.values.description}
					error={formik.errors.description}
					rows={6}
					handleBlur={formik.handleBlur}
					handleChange={formik.handleChange}
				/>
			</div>
			<div className="flex items-center">
				<TextArea
					id="operation_notes"
					title="Operation Notes"
					value={formik.values.operation_notes}
					error={formik.errors.operation_notes}
					rows={6}
					handleBlur={formik.handleBlur}
					handleChange={formik.handleChange}
				/>
			</div>
			{/* {formType === 'update' && (
				<div className="flex items-center px-2">
					<Input
						title="Percentage Dismantle"
						id="percentage_dismantle"
						type="number"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.percentage_dismantle}
						placeholder="Percentage Dismantle"
						error={formik.errors.percentage_dismantle}
					/>
					<Input
						title="Percentage Erect"
						id="percentage_erect"
						type="number"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.percentage_erect}
						placeholder="Percentage Erect"
						error={formik.errors.percentage_erect}
					/>
					<Input
						title="Percentage Complete"
						id="percentage_complete"
						type="number"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.percentage_complete}
						placeholder="Percentage Complete"
						error={formik.errors.percentage_complete}
					/>
				</div>
			)} */}

			{/* <div className="flex items-center px-2">
				<Input
					title="Total Hours"
					id="total_hours"
					type="number"
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					value={formik.values.total_hours}
					placeholder="Total Hours"
					error={formik.errors.total_hours}
				/>
				{formType === 'update' && (
					<DateSelect
						title="Last Edit Date"
						id="LastEditDate"
						value={formik.values.LastEditDate}
						onChange={formik.setFieldValue}
					/>
				)}
			</div> */}
			{/* {formType === 'create' && (
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							title="Weekly Hire Rate"
							id="hire_rate"
							type="number"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={`${formik.values.hire_rate}`}
							placeholder="Hire Rate"
							error={formik.errors.hire_rate}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Task Value"
							id="task_value"
							type="number"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={`${formik.values.task_value}`}
							placeholder="Task Value"
							error={formik.errors.task_value}
						/>
					</div>
				</div>
			)} */}
			<ContacsForm
				formType="create"
				heading="Create Contact"
				client_id={Number(client[0]?.id)}
				open={contactForm}
				setOpen={setContactForm}
			/>
		</>
	)
}
