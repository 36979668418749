export const active_inactive_options = [
	{
		value: 'Active',
		label: 'Active',
	},
	{
		value: 'Inactive',

		label: 'Inactive',
	},
]

export const yes_no_options = [
	{
		value: 'Yes',
		label: 'Yes',
	},
	{
		value: 'No',
		label: 'No',
	},
]

export const item_codes_options = [
	{ value: 'ACT', label: 'ACT' },
	{ value: 'COL', label: 'COL' },
	{ value: 'DC', label: 'DC' },
	{ value: 'DEL', label: 'DEL' },
	{ value: 'FHH', label: 'FHH' },
	{ value: 'FHW', label: 'FHW' },
	{ value: 'FS', label: 'FS' },
	{ value: 'FSB', label: 'FSB' },
	{ value: 'FSD', label: 'FSD' },
	{ value: 'FSFU', label: 'FSFU' },
	{ value: 'FSH', label: 'FSH' },
	{ value: 'FSM', label: 'FSM' },
	{ value: 'FSR', label: 'FSR' },
	{ value: 'FSRI', label: 'FSRI' },
	{ value: 'FSV', label: 'FSV' },
	{ value: 'H', label: 'H' },
	{ value: 'INS', label: 'INS' },
	{ value: 'LAB', label: 'LAB' },
	{ value: 'MAT', label: 'MAT' },
	{ value: 'MS1', label: 'MS1' },
	{ value: 'MS2', label: 'MS2' },
	{ value: 'MS3', label: 'MS3' },
	{ value: 'MS4', label: 'MS4' },
	{ value: 'MS5', label: 'MS5' },
	{ value: 'MS6', label: 'MS6' },
	{ value: 'MSB', label: 'MSB' },
	{ value: 'MSD', label: 'MSD' },
	{ value: 'MSM', label: 'MSM' },
	{ value: 'ONCH', label: 'ONCH' },
	{ value: 'REP', label: 'REP' },
	{ value: 'REPB', label: 'REPB' },
	{ value: 'REPD', label: 'REPD' },
	{ value: 'REPR', label: 'REPR' },
	{ value: 'SALE', label: 'SALE' },
	{ value: 'SHR', label: 'SHR' },
	{ value: 'SN', label: 'SN' },
	{ value: 'SNB', label: 'SNB' },
	{ value: 'SND', label: 'SND' },
	{ value: 'TF', label: 'TF' },
]
