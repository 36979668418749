import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { PageHeading, Container, DataTableHeader } from 'common'
import { FolderOpenIcon } from '@heroicons/react/24/solid'
import { Column } from 'primereact/column'
import { DataTableServices, StaffServices } from 'services'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { DataTable } from 'primereact/datatable'
import { Calendar } from 'primereact/calendar'
import { IStaffRow } from 'models/staff.model'
import { Nullable } from 'primereact/ts-helpers'
import { AppRoutes } from 'config'
import { parse } from 'date-fns'
import moment from 'moment'

interface StaffDataType {
	staff_id: string | undefined
	staff_name: string
	competency: string
	issue_date: Date | null
	expiry_date: Date | null
	assessed_by: string
	[key: string]: Date | string | undefined | null
}

const lookup = {
	'Driver License Class 1': [
		'endorsement_complete_date',
		'endorsement_expiry',
		'license_assessed_by',
	],
	'Driver License Class 2': [
		'endorsement_complete_date',
		'endorsement_expiry',
		'license_assessed_by',
	],
	'Driver License Class 4': [
		'endorsement_complete_date',
		'endorsement_expiry',
		'license_assessed_by',
	],
	'Driver License Class 5': [
		'endorsement_complete_date',
		'endorsement_expiry',
		'license_assessed_by',
	],
	'Confined Space Training': [
		'confined_date',
		'confined_expiry_date',
		'confined_uploaded_by',
	],
	'Health & Safety Induction': [
		'induction_date',
		'expiry_date',
		'hs_assessed_by',
	],
	'Building Construction Site Safe Passport': [
		'passport_issue',
		'passport_expiry',
		'site_safe_assessed_by',
	],
	'First Aid Certificate': [
		'first_aid_issue',
		'first_aid_expiry',
		'firstaid_assessed_by',
	],
	'Scaffolding Certificate of Competence': [
		'cert_issue_date',
		'cert_expiry_date',
		'scaff_cert_assessed_by',
	],
	'Height Training': [
		'height_training_issue',
		'height_training_expiry',
		'height_training_assessed_by',
	],
}

function convertDate(date: string) {
	const dateParts = date.split('/')
	const month = parseInt(dateParts[1], 10)
	return new Date(+dateParts[2], month, +dateParts[0])
}

function transformData(data: IStaffRow[] | undefined) {
	if (!data) return []
	const result: StaffDataType[] = []
	for (let i = 0; i < data.length; i++) {
		for (const [key, value] of Object.entries(lookup)) {
			const singleData = data[i]
			const [issueProp, expiryProp, assessedByProp] = value as [
				keyof IStaffRow,
				keyof IStaffRow,
				keyof IStaffRow
			]
			const issueDate = singleData[issueProp]
			const expiryDate = singleData[expiryProp]
			result.push({
				staff_id: data[i].id,
				staff_name: data[i]?.staff_name || '',
				competency: key,
				issue_date: issueDate ? convertDate(issueDate.toString()) : null,
				expiry_date: expiryDate ? convertDate(expiryDate.toString()) : null,
				assessed_by: singleData[assessedByProp]?.toString() || '',
			})
		}
	}
	return result
}

export function CompetenciesMain() {
	const { data, isLoading } = StaffServices.useStaff()

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
		globalFilterFields,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'branch',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Hawkes Bay',
					'Gisborne',
					'Wairarapa',
					'Taupo',
					'Dunedin',
					'Cromwell',
				],
				typeFilterOperator: FilterOperator.OR,
			},
			{
				filterName: 'cert_level',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Elementary',
					'Intermediate',
					'Advanced',
					'Restricted Advanced',
					'Suspended',
				],
				typeFilterOperator: FilterOperator.OR,
			},
			{
				filterName: 'license_class2',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.containsOrNot,
				filterOptions: ['Class 1', 'Class 2', 'Class 4', 'Class 5'],
				typeFilterOperator: FilterOperator.OR,
			},
		],
		additionalGlobalFilterFields: [
			'full_name',
			'staff_name',
			'position',
			'confined_expiry_date',
			'passport_expiry',
			'first_aid_expiry',
			'working_at_heights_refresher_due',
			'safety_nets_faset_expires',
			'cert_level',
			'cert_expiry_date',
			'elevated_walk_platform_refresher_due',
			'incline_lifts_training_date_completed',
			'forklift_operator_cert_refresher_due',
			'mobile_crane_3795_refresher_due',
			'license_class2',
			'endorsement_expiry',
			'expiry_date',
			'emergency_response_fa_26551_refresher_due',
			'emergency_response_fa_26552_refresher_due',
			'rope_access_equipment_19359_refresher_due',
			'status',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
		customFilters: [
			{
				field: 'branch',
				value: 'Hawkes Bay',
				color: 'success',
			},
			{
				field: 'branch',
				value: 'Gisborne',
				color: 'danger',
			},
			{
				field: 'branch',
				value: 'Wairarapa',
				color: 'warning',
			},
			{
				field: 'branch',
				value: 'Taupo',
				color: 'success',
			},
			{
				field: 'branch',
				value: 'Dunedin',
				color: 'warning',
			},
			{
				field: 'branch',
				value: 'Cromwell',
				color: 'danger',
			},
		],
	})

	const headerTemplate = (data: StaffDataType) => (
		<td key={`${data?.staff_name}_headerLabel`} colSpan={4}>
			<Link
				key={`details${data.staff_id}`}
				to={`/staff/${data.staff_id}/details`}
				className="flex items-center">
				<FolderOpenIcon className="h-4 w-4 text-gray-500 mr-2" />
				<span className="text-gray-900 font-bold text-xs">
					{data?.staff_name || ''} (Details)
				</span>
			</Link>
		</td>
	)

	// const formatDate = (value: Date) => {
	// 	return value.toLocaleDateString('en-NZ', {
	// 		day: '2-digit',
	// 		month: '2-digit',
	// 		year: 'numeric',
	// 	})
	// }

	// const dateBodyTemplate = (rowData: string | Date | null) => {
	// 	if (!rowData) return ''
	// 	return formatDate(rowData)
	// }
	// const dateFilterTemplate = (options: {
	// 	value: string | Date | Date[] | null | undefined
	// 	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	// 	filterCallback: (arg0: Nullable<string | Date | Date[]>, arg1: any) => void
	// 	index: number | string
	// }) => {
	// 	return (
	// 		<Calendar
	// 			value={options.value}
	// 			onChange={(e) => options.filterCallback(e.value, options.index)}
	// 			dateFormat="dd/mm/yy"
	// 			placeholder="dd/mm/yyyy"
	// 			mask="99/99/9999"
	// 		/>
	// 	)
	// }
	const filteredData = data
		?.filter((data: any) => data.status === 'Active')
		.map((data: any) => {
			return {
				...data,
				formattedConfinedExpiryDate: moment(
					data.confined_expiry_date,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedPassportExpiry: moment(
					data.passport_expiry,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedFirstAidExpiry: moment(
					data.first_aid_expiry,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedWorkingAtHeights: moment(
					data.working_at_heights_refresher_due,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedSafetyNets: moment(
					data.safety_nets_faset_expires,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedCertExpiryDate: moment(
					data.cert_expiry_date,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedEWP: moment(
					data.elevated_walk_platform_refresher_due,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedInclineLifts: moment(
					data.incline_lifts_training_date_completed,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedForklift: moment(
					data.forklift_operator_cert_refresher_due,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedMobileCrane: moment(
					data.mobile_crane_3795_refresher_due,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedEndorsementExpiry: moment(
					data.endorsement_expiry,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedExpiryDate: moment(data.expiry_date, 'DD/MM/YYYY').format(
					'YYYY-MM-DD'
				),
				formattedERFA26551: moment(
					data.emergency_response_fa_26551_refresher_due,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedERFA26552: moment(
					data.emergency_response_fa_26552_refresher_due,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
				formattedRopeAccess: moment(
					data.rope_access_equipment_19359_refresher_due,
					'DD/MM/YYYY'
				).format('YYYY-MM-DD'),
			}
		})

	console.log({ filteredData })

	return (
		<div>
			<PageHeading title="Competencies" />

			<Container>
				<div className="mx-auto mt-8">
					<DataTable
						ref={dataTableReference}
						value={filteredData}
						loading={isLoading}
						header={header}
						paginator
						paginatorPosition="both"
						showGridlines
						globalFilter={globalFilterValue}
						rows={100}
						sortOrder={1}
						sortField="full_name"
						rowsPerPageOptions={[100, 300, 500]}
						dataKey="id"
						filters={filters}
						filterDisplay="menu"
						scrollable={true}
						globalFilterFields={globalFilterFields}
						emptyMessage="No competencies found."
						stripedRows
						scrollHeight="600px">
						<Column
							header="Full Name"
							field="full_name"
							body={(row) => (
								<Link
									to={AppRoutes.privateRoutes.StaffDetail.replace(
										':id',
										row.id || ''
									)}
									className="text-blue-600">
									{row.full_name}
								</Link>
							)}
						/>
						<Column header="Position" field="position" dataType="date" />
						<Column
							sortable
							header="Confined Space"
							field="formattedConfinedExpiryDate"
							dataType="date"
							body={(rowData: { confined_expiry_date: string }) => {
								if (!rowData.confined_expiry_date) return null

								const [day, month, year] = rowData.confined_expiry_date
									.split('/')
									.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.confined_expiry_date}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="Site Safe"
							field="formattedPassportExpiry"
							dataType="date"
							body={(rowData: { passport_expiry: string }) => {
								if (!rowData.passport_expiry) return null
								const [day, month, year] = rowData.passport_expiry
									.split('/')
									.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.passport_expiry}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="First Aid"
							field="formattedFirstAidExpiry"
							dataType="date"
							body={(rowData: { first_aid_expiry: string }) => {
								if (!rowData.first_aid_expiry) return null
								const [day, month, year] = rowData.first_aid_expiry
									.split('/')
									.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.first_aid_expiry}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="Working at Heights"
							field="formattedWorkingAtHeights"
							dataType="date"
							body={(rowData: {
								id: number
								working_at_heights_refresher_due: string
							}) => {
								if (!rowData.working_at_heights_refresher_due) return null

								const [day, month, year] =
									rowData.working_at_heights_refresher_due
										.split('/')
										.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.working_at_heights_refresher_due}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="Safety Nets"
							field="formattedSafetyNets"
							dataType="date"
							body={(rowData: { safety_nets_faset_expires: string }) => {
								if (!rowData.safety_nets_faset_expires) return null
								const [day, month, year] = rowData.safety_nets_faset_expires
									.split('/')
									.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.safety_nets_faset_expires}
									</div>
								)
							}}
						/>
						<Column
							header="COC Level"
							field="cert_level"
							dataType="text"
							{...FilterColumn.cert_level}
							showFilterMenuOptions={false}
						/>
						<Column
							sortable
							header="COC Expiry"
							field="formattedCertExpiryDate"
							dataType="date"
							body={(rowData: { cert_expiry_date: string }) => {
								if (!rowData.cert_expiry_date) return null
								const [day, month, year] = rowData.cert_expiry_date
									.split('/')
									.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.cert_expiry_date}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="EWP"
							field="formattedEWP"
							dataType="date"
							body={(rowData: {
								elevated_walk_platform_refresher_due: string
							}) => {
								if (!rowData.elevated_walk_platform_refresher_due) return null
								const [day, month, year] =
									rowData.elevated_walk_platform_refresher_due
										.split('/')
										.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.elevated_walk_platform_refresher_due}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="Incline Lift"
							field="formattedInclineLifts"
							dataType="date"
							body={(rowData: {
								incline_lifts_training_date_completed: string
							}) => {
								if (!rowData.incline_lifts_training_date_completed) return null
								const [day, month, year] =
									rowData.incline_lifts_training_date_completed
										.split('/')
										.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.incline_lifts_training_date_completed}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="Forklift"
							field="formattedForklift"
							dataType="date"
							body={(rowData: {
								forklift_operator_cert_refresher_due: string
							}) => {
								if (!rowData.forklift_operator_cert_refresher_due) return null
								const [day, month, year] =
									rowData.forklift_operator_cert_refresher_due
										.split('/')
										.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.forklift_operator_cert_refresher_due}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="Mobile Crane"
							field="formattedMobileCrane"
							dataType="date"
							body={(rowData: { mobile_crane_3795_refresher_due: string }) => {
								if (!rowData.mobile_crane_3795_refresher_due) return null
								const [day, month, year] =
									rowData.mobile_crane_3795_refresher_due.split('/').map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.mobile_crane_3795_refresher_due}
									</div>
								)
							}}
						/>
						<Column
							header="License Class"
							field="license_class2"
							body={(row) => {
								const licenseClassList: string[] = JSON.parse(
									row.license_class2
								)
								const classes = licenseClassList.map((licenseClass: any) => (
									<span>
										{licenseClass}
										<br />
									</span>
								))
								return classes
							}}
							dataType="text"
							{...FilterColumn.license_class2}
							showFilterMenuOptions={false}
						/>
						<Column
							sortable
							header="License Expiry"
							field="formattedEndorsementExpiry"
							dataType="date"
							body={(rowData: { endorsement_expiry: string }) => {
								if (!rowData.endorsement_expiry) return null
								const [day, month, year] = rowData.endorsement_expiry
									.split('/')
									.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.endorsement_expiry}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="H&S Induction"
							field="formattedExpiryDate"
							dataType="date"
							body={(rowData: { expiry_date: string }) => {
								if (!rowData.expiry_date) return null
								const [day, month, year] = rowData.expiry_date
									.split('/')
									.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.expiry_date}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="Emergency Response FA US 26551"
							field="formattedERFA26551"
							dataType="date"
							body={(rowData: {
								emergency_response_fa_26551_refresher_due: string
							}) => {
								if (!rowData.emergency_response_fa_26551_refresher_due)
									return null
								const [day, month, year] =
									rowData.emergency_response_fa_26551_refresher_due
										.split('/')
										.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.emergency_response_fa_26551_refresher_due}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="Emergency Response FA US 26552"
							field="formattedERFA26552"
							dataType="date"
							body={(rowData: {
								emergency_response_fa_26552_refresher_due: string
							}) => {
								if (!rowData.emergency_response_fa_26552_refresher_due)
									return null
								const [day, month, year] =
									rowData.emergency_response_fa_26552_refresher_due
										.split('/')
										.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.emergency_response_fa_26552_refresher_due}
									</div>
								)
							}}
						/>
						<Column
							sortable
							header="Inspect and Maintain Rope Access"
							field="formattedRopeAccess"
							dataType="date"
							body={(rowData: {
								rope_access_equipment_19359_refresher_due: string
							}) => {
								if (!rowData.rope_access_equipment_19359_refresher_due)
									return null
								const [day, month, year] =
									rowData.rope_access_equipment_19359_refresher_due
										.split('/')
										.map(Number)
								const serviceDueDate = parse(
									`${year}-${month}-${day}`,
									'yyyy-MM-dd',
									new Date()
								)
								const today = new Date()
								const thirtyDaysFromNow = new Date()
								thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

								let bgColorClass = 'bg-green-200'

								if (serviceDueDate < today) {
									bgColorClass = 'bg-red-200'
								} else if (serviceDueDate <= thirtyDaysFromNow) {
									bgColorClass = 'bg-orange-200'
								}

								return (
									<div className={`inline-block ${bgColorClass}`}>
										{rowData.rope_access_equipment_19359_refresher_due}
									</div>
								)
							}}
						/>
						<Column header="Status" field="status" dataType="text" />
					</DataTable>
				</div>
			</Container>
		</div>
	)
}
