import React from 'react'
import { DateSelect, CreateFile } from 'common'

type SafetyNetsProps = {
	values: {
		safety_nets_faset_file: string
		safety_nets_faset_completed: string
		safety_nets_faset_expires: string
	}

	setFieldValue: (
		field: string,
		value: string | number | boolean | Date
	) => void
}

export const SafetyNets: React.FC<SafetyNetsProps> = ({
	values,
	setFieldValue,
}) => {
	return (
		<div>
			<h3 className="px-4 pt-2 text-lg font-semibold leading-5">
				Safety Nets FASET
			</h3>
			<div>
				<div className="flex items-center px-2">
					<DateSelect
						title="Completed"
						id="safety_nets_faset_completed"
						value={values.safety_nets_faset_completed}
						onChange={setFieldValue}
					/>
					<DateSelect
						title="Expires"
						id="safety_nets_faset_expires"
						value={values.safety_nets_faset_expires}
						onChange={setFieldValue}
					/>
				</div>
				<div className="w-1/2 pl-2">
					<CreateFile
						field="safety_nets_faset_file"
						setFieldValue={setFieldValue}
						value={values.safety_nets_faset_file}
					/>
				</div>
			</div>
		</div>
	)
}
