import { Spinner, Table } from 'common'
import { PencilIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from 'config'
import { JobsServices, QuoteServices } from 'services'
import { numberFormat } from 'utilities'

interface ClientJobsTableProps {
	client_id?: number
}

export const ClientJobsTable = ({ client_id }: ClientJobsTableProps) => {
	const location = useLocation()
	const [openCreate, setOpenCreate] = useState(false)
	const { data: allJobs, isLoading } = JobsServices.useJobs()
	const jobsForClient = allJobs?.filter((job: any) => Number(job.client_id) === Number(client_id)) || []

	if (isLoading) {
		return <Spinner />
	}
	// Columns are: 
	const columns = [
		{
			header: 'Job #',
			field: 'id',
			body: (rowData: any) => (
				<Link
					to={AppRoutes.privateRoutes.JobDetails.replace(':id', rowData.id)}
					className="text-blue-500 hover:underline">
					#{rowData.job_num}
				</Link>
			),
		},
		{
			header: 'Client',
			field: 'clientData.client_name',
			body: (rowData: any) => (
				<>
					<span className="">{rowData.clientData.client_name}</span>
				</>
			),
		},
		{
			header: 'Site',
			field: 'site',
		},
		{
			header: 'Description',
			field: 'descriptionOfQuote',
		},
		{
			header: 'Job Type',
			field: 'job_type',
		},
		{
			header: 'Start Date',
			field: 'start_date',
		},
		{
			header: 'Finish Date',
			field: 'end_date',
		},
		{
			header: 'On Hire?',
			field: 'on_hire',
		},
		{
			header: 'Confined Spaces?',
			field: 'confined_spaces',
		},
		{
			header: 'Notes',
			field: 'notes',
		},
		{
			header: 'Status',
			field: 'status',
		},
	]


	return (
		<>
			<Table
				title="Jobs"
				columns={columns}
				data={jobsForClient}
				isLoading={isLoading}
				disableButtons
			/>
		</>
	)
}
