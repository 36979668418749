import { Dropdown, Input, SideModal, Spinner, DateSelect } from 'common'
import { useFormik } from 'formik'
import { InvoiceServices } from 'services'
import moment from 'moment'
import * as Yup from 'yup'
import { item_codes_options, yes_no_options } from 'models'
import { useEffect, useRef, useState } from 'react'

const sqmRateOptions = [
	{
		value: 1.8,
		label: '$1.80',
	},
	{
		value: 2.2,
		label: '$2.20',
	},
	{
		value: 2.5,
		label: '$2.50',
	},
]

const onHireOptions = [
	{
		value: 'Yes',
		label: 'Yes',
	},
	{
		value: 'No',
		label: 'No',
	},
	{
		value: 'On Hire Inc',
		label: 'On Hire Inc',
	},
]

interface IProps {
	invoice_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
	total: number
}

interface IinitialValues {
	on_hire: string
	zone_label: string
	type: string
	description: string
	erect_percent: number
	date_on_hire: string | null
	completed_date: string | null
	weekly_hire_rate: number
	sqm_rate: number
	item_code: string
	total: number
	hire_period_included: number
	is_total_manually_changed: boolean
	installed_sqm: number
}

export const WeeklyHireInvoiceEditForm = ({
	invoice_id,
	heading,
	setOpen,
	formType,
	open,
	total,
}: IProps) => {
	const { data: invoiceData, isLoading: invoiceLoading } =
		InvoiceServices.useWeeklyHireInvoiceById(invoice_id)

	// console.log({invoiceData})

	const { updateWeeklyHire } = InvoiceServices.useUpdateWeeklyHireInvoice()
	const [isDayworks, setIsDayworks] = useState(false)

	useEffect(() => {
		if (!invoiceLoading && invoiceData)
			if (!invoiceData?.jobData?.quote_id) {
				setIsDayworks(true)
			}
	}, [invoiceData, invoiceLoading])

	const initialValues: IinitialValues = {
		on_hire: invoiceData?.on_hire || '',
		zone_label: invoiceData?.taskData?.zone_label || '',
		type: invoiceData?.taskData?.type || '',
		description: 'Weekly Hire',
		erect_percent: invoiceData?.erect_percent || 0,
		date_on_hire: invoiceData?.date_on_hire
			? moment(invoiceData?.date_on_hire, 'DD/MM/YYYY').format('DD/MM/YYYY')
			: null,
		completed_date: invoiceData?.completed_date
			? moment(invoiceData?.completed_date, 'DD/MM/YYYY').format('DD/MM/YYYY')
			: null,
		weekly_hire_rate: invoiceData?.weekly_hire_rate || 0,
		sqm_rate: invoiceData?.sqm_rate || 1.8,
		item_code: invoiceData?.item_code || '',
		total: invoiceData?.total || total || 0,
		hire_period_included: invoiceData?.hire_period_included || 0,
		is_total_manually_changed: invoiceData?.is_total_manually_changed || false,
		installed_sqm: invoiceData?.installed_sqm || 0,
	}

	const validationSchema = Yup.object({
		erect_percent: Yup.number().max(
			100,
			'Percent Erect must be smaller than or equal to 100'
		),
		item_code: Yup.string().required('Item Code is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'update' && invoice_id) {
				updateWeeklyHire(invoice_id, values)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	const prevTotalRef = useRef(formik.values.total)
	const prevDatesRef = useRef({
		date_on_hire: formik.values.date_on_hire,
		completed_date: formik.values.completed_date,
	})

	useEffect(() => {
		if (prevTotalRef.current !== formik.values.total) {
			formik.setFieldValue('is_total_manually_changed', true)
		}
		prevTotalRef.current = formik.values.total
	}, [formik.values.total])

	useEffect(() => {
		const { date_on_hire, completed_date } = formik.values

		if (
			prevDatesRef.current.date_on_hire !== date_on_hire ||
			prevDatesRef.current.completed_date !== completed_date
		) {
			formik.setFieldValue('is_total_manually_changed', false)
		}

		prevDatesRef.current = { date_on_hire, completed_date }
	}, [formik.values.date_on_hire, formik.values.completed_date])

	if (invoice_id && invoiceLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="Section"
							id="zone_label"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Section"
							value={formik.values.zone_label}
							error={formik.errors.zone_label}
							disabled
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Type"
							id="type"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Type"
							value={formik.values.type}
							error={formik.errors.type}
							disabled
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-full">
						<Input
							id="description"
							title="Description"
							placeholder="Description"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.description}
							error={formik.errors.description}
						/>
					</div>
				</div>
				<div className="flex items-stretch px-2">
					<div className="w-1/2">
						<Dropdown
							label="SQM Rate"
							id="sqm_rate"
							options={sqmRateOptions}
							value={formik.values.sqm_rate}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							error={formik.errors.sqm_rate}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="weekly_hire_rate"
							title="Weekly Hire Rate"
							placeholder=""
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.weekly_hire_rate}
							error={formik.errors.weekly_hire_rate}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Dropdown
							id="item_code"
							label="Item Code"
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							options={item_codes_options}
							value={formik.values.item_code}
							error={formik.errors.item_code}
						/>
					</div>
					<div className="w-1/2">
						<Dropdown
							id="on_hire"
							label="On Hire"
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							options={onHireOptions}
							value={formik.values.on_hire}
							error={formik.errors.on_hire}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<DateSelect
							id="date_on_hire"
							title="Date On Hire"
							onChange={formik.setFieldValue}
							value={formik.values.date_on_hire}
							error={formik.errors.date_on_hire}
						/>
					</div>
					<div className="w-1/2">
						<DateSelect
							id="completed_date"
							title="Completed Date"
							onChange={formik.setFieldValue}
							value={formik.values.completed_date}
							error={formik.errors.completed_date}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="erect_percent"
							title="Percent Erect"
							placeholder="Percent Erect"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.erect_percent}
							error={formik.errors.erect_percent}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Total Invoiceable"
							id="total"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Total"
							value={formik.values.total}
							error={formik.errors.total}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="hire_period_included"
							title="Hire Period Included"
							placeholder="Hire Period Included"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.hire_period_included}
							error={formik.errors.hire_period_included}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="installed_sqm"
							title="Installed SQM"
							placeholder="Installed SQM"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.installed_sqm}
							error={formik.errors.installed_sqm}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
