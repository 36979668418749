import { Document, Image, Link, Page, Text, View } from '@react-pdf/renderer'
import { QuoteData, IQuoteLine, IQuoteAdditionalLines } from 'models'
import { styles, tableStyles } from './Styles'
import { Heading, ClientInfo } from './Heading'
import { Description } from './Description'
import { ColumnTypes, Table } from './Table'
import { Footer } from './Footer'
import { Greetings } from './Greetings'
import { Closing } from './Closing'
import { numberFormat } from 'utilities'

interface QuotePDFDocumentProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
}

export const TableRow = ({ item }: { item?: any }) => {
	return (
		<View
			style={[
				tableStyles.tableRow,
				{ backgroundColor: item?.backgroundColor || '' },
			]}>
			<View style={tableStyles.tableColStart}>
				<Text style={[tableStyles.tableCell, { paddingLeft: '8' }]}>
					{item?.service}
				</Text>
			</View>
			<View style={[tableStyles.tableColEnd]}>
				<Text style={tableStyles.tableCell}>{item?.price}</Text>
			</View>
		</View>
	)
}

export const TableRowEnd = ({ item }: { item?: any }) => (
	<View style={[tableStyles.tableRow, { borderBottom: 1 }]} fixed>
		<View style={tableStyles.tableColStart}>
			<Text style={tableStyles.tableCell}>{item?.service}</Text>
		</View>
		<View style={tableStyles.tableColEnd}>
			<Text style={tableStyles.tableCell}>{item?.price}</Text>
		</View>
	</View>
)

export const SummaryQuotePDFDocument = ({
	quote_data,
	quote_lines,
	quote_addons,
}: QuotePDFDocumentProps) => {
	// const linesTotal = quote_lines.map((line) => Number(line.erect_and_dismantle)).reduce((acc, curr) => acc + curr, 0)
	// const linesHireTotal = quote_lines.map((line) => Number(line.weekly_hire_fee)).reduce((acc, curr) => acc + curr, 0)
	// const additionalTotal = quote_addons.map((addon) => Number(addon.total_cost)).reduce((acc, curr) => acc + curr, 0)
	// const total = linesTotal + additionalTotal + linesHireTotal
	const files =
		quote_data?.files.filter((file: any) => {
			return file.include_in_quote == true
		}) || []

	const stages = quote_lines.map((line) => line.zone_label)

	const uniqueStages = Array.from(new Set([...stages]))

	const quote_stages = uniqueStages.map((stage) => {
		const stage_lines = quote_lines.filter((line) => line.zone_label === stage)
		const stage_total = stage_lines.reduce((acc, line) => {
			const line_total =
				line.total - line.weekly_hire_fee * (line.hire_period || 1)
			return Number(acc) + Number(line_total)
		}, 0)
		const total_hire_fee = stage_lines.reduce((acc, line) => {
			const line_total = line.weekly_hire_fee * (line.hire_period || 1)
			return Number(acc) + Number(line_total)
		}, 0)
		const hire_period = stage_lines[0].hire_period || 1

		return {
			stage,
			stage_lines,
			stage_total,
			hire_period,
			total_hire_fee,
		}
	})

	quote_lines.map((line) => {
		return { zone: line.zone_label, invoice: line }
	})

	let quote_lines_subtotal = quote_lines.reduce((acc, line) => {
		const line_total = line.total
		return Number(acc) + Number(line_total)
	}, 0)

	const deliveryQuoteAddons = quote_addons.filter((addon) =>
		uniqueStages.includes(addon.zone_label)
	)
	quote_stages.forEach((line) => {
		const stage = deliveryQuoteAddons.filter(
			(addon) => addon.zone_label === line.stage
		)
		if (stage) {
			stage.forEach((item) => {
				line.stage_total += Number(item?.total_cost!)
			})
		}
	})

	const delivery_addon_lines_subtotal = deliveryQuoteAddons.reduce(
		(acc, line) => {
			return Number(acc) + Number(line.total_cost)
		},
		0
	)
	quote_lines_subtotal += delivery_addon_lines_subtotal

	const remainingQuoteAddons = quote_addons.filter(
		(addon) => !uniqueStages.includes(addon.zone_label)
	)

	const addon_lines_subtotal = remainingQuoteAddons.reduce((acc, line) => {
		return Number(acc) + Number(line.total_cost)
	}, 0)

	const subtotal = quote_lines_subtotal + addon_lines_subtotal
	const subtotal_gst = subtotal * 0.15
	const total = subtotal + subtotal_gst

	const additionals_total = addon_lines_subtotal

	const isImage = (fileUrl: string) => {
		if (!fileUrl) return false
		const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
		const extension = fileUrl.split('.').pop()?.toLowerCase()
		return extension ? imageExtensions.includes(extension) : false
	}

	return (
		<Document>
			{quote_stages.length === 1 && remainingQuoteAddons.length <= 2 ? (
				<Page size="A4" style={styles.page}>
					<Heading />
					<View style={styles.content}>
						<Text style={styles.quoteText}>Quotation</Text>
						<ClientInfo quote={quote_data} />
						<Greetings quote={quote_data} />
						<Description description={quote_data.scope_of_work} />
						<View style={tableStyles.tableHeader} fixed>
							<View style={[tableStyles.tableHeaderColStart]}>
								<Text style={tableStyles.tableCell}>Fixed Term Quote</Text>
							</View>
							<View style={tableStyles.tableHeaderColEnd}>
								<Text style={tableStyles.tableCell}>Price</Text>
							</View>
						</View>

						{quote_stages.map((stage, index) => (
							<View key={index}>
								<View style={tableStyles.tableRow}>
									<View style={tableStyles.tableColStart}>
										<Text style={tableStyles.tableCell}>
											<Text style={{ fontWeight: 'bold' }}>
												Stage - {stage.stage}
											</Text>
										</Text>
									</View>
									<View style={tableStyles.tableColEnd}>
										<Text style={tableStyles.tableCell}></Text>
									</View>
								</View>
								<TableRow
									item={{
										service: `Total Labour, Services, and Delivery incl. ${stage.hire_period} weeks hire`,
										price: numberFormat.format(
											Number(stage.total_hire_fee + Number(stage.stage_total))
										),
										backgroundColor: 'whitesmoke',
									}}
								/>
								<TableRow
									item={{
										service: `Weekly Hire Fee after initial period ${numberFormat.format(
											stage.total_hire_fee / stage.hire_period
										)} p/w`,
										backgroundColor: '#E8E8E8',
									}}
								/>
							</View>
						))}

						{remainingQuoteAddons.length <= 2 && (
							<>
								<View style={tableStyles.tableRow}>
									<View style={tableStyles.tableColStart}>
										<Text style={tableStyles.tableCell}>
											<Text style={{ fontWeight: 'bold' }}>
												Additional Services
											</Text>
										</Text>
									</View>
									<View style={tableStyles.tableColEnd}>
										<Text style={tableStyles.tableCell}></Text>
									</View>
								</View>
								{remainingQuoteAddons.map((addon, index) => (
									<TableRow
										key={index}
										item={{
											service: `${addon.description}`,
											price: numberFormat.format(addon.total_cost),
										}}
									/>
								))}
							</>
						)}
						<TableRowEnd />

						<View style={[tableStyles.tableRow]}>
							<View style={tableStyles.tableSummaryStart}>
								<Text style={tableStyles.tableCell}>
									<Text style={{ fontWeight: 'semibold', textAlign: 'right' }}>
										SUB TOTAL
									</Text>
								</Text>
							</View>
							<View style={tableStyles.tableSummaryEnd}>
								<Text style={[tableStyles.tableCell, { textAlign: 'right' }]}>
									{numberFormat.format(subtotal)}
								</Text>
							</View>
						</View>
						<View style={[tableStyles.tableRow]}>
							<View style={tableStyles.tableSummaryStart}>
								<Text style={tableStyles.tableCell}>
									<Text style={{ fontWeight: 'semibold', textAlign: 'right' }}>
										GST
									</Text>
								</Text>
							</View>
							<View style={tableStyles.tableSummaryEnd}>
								<Text style={[tableStyles.tableCell, { textAlign: 'right' }]}>
									{numberFormat.format(subtotal_gst)}
								</Text>
							</View>
						</View>
						<View style={[tableStyles.tableRow]}>
							<View style={tableStyles.tableSummaryStart}>
								<Text style={tableStyles.tableCell}>
									<Text style={{ fontWeight: 'semibold', textAlign: 'right' }}>
										TOTAL (inc. GST)
									</Text>
								</Text>
							</View>
							<View style={tableStyles.tableSummaryEnd}>
								<Text style={[tableStyles.tableCell, { textAlign: 'right' }]}>
									{numberFormat.format(total)}
								</Text>
							</View>
						</View>
					</View>
					<Closing quote={quote_data} />
					<Footer />
				</Page>
			) : (
				<>
					<Page size="A4" style={styles.page}>
						<Heading />
						<View style={styles.content}>
							<Text style={styles.quoteText}>Quotation</Text>
							<ClientInfo quote={quote_data} />
							<Greetings quote={quote_data} />
							<Description description={quote_data.scope_of_work} />

							<View style={tableStyles.tableHeader} fixed>
								<View style={[tableStyles.tableHeaderColStart]}>
									<Text style={tableStyles.tableCell}>Fixed Term Quote</Text>
								</View>
								<View style={tableStyles.tableHeaderColEnd}>
									<Text style={tableStyles.tableCell}>Price</Text>
								</View>
							</View>

							{quote_stages.slice(0, 2).map((stage, index) => (
								<View key={index}>
									<View style={tableStyles.tableRow}>
										<View style={tableStyles.tableColStart}>
											<Text style={tableStyles.tableCell}>
												<Text style={{ fontWeight: 'bold' }}>
													Stage - {stage.stage}
												</Text>
											</Text>
										</View>
										<View style={tableStyles.tableColEnd}>
											<Text style={tableStyles.tableCell}></Text>
										</View>
									</View>
									<TableRow
										item={{
											service: `Total Labour, Services, and Delivery incl. ${stage.hire_period} weeks hire`,
											price: numberFormat.format(
												Number(stage.total_hire_fee + Number(stage.stage_total))
											),
											backgroundColor: 'whitesmoke',
										}}
									/>
									<TableRow
										item={{
											service: `Weekly Hire Fee after initial period ${numberFormat.format(
												stage.total_hire_fee / stage.hire_period
											)} p/w`,
											backgroundColor: '#E8E8E8',
										}}
									/>
								</View>
							))}
							<TableRowEnd />
						</View>
						<Footer />
					</Page>

					{(quote_stages.slice(2).length > 0 ||
						remainingQuoteAddons.length > 0) && (
						<Page size="A4" style={styles.page}>
							<Heading />
							<View style={styles.content}>
								{/* Table Header (if needed) */}
								<View style={tableStyles.tableHeader} fixed>
									<View style={[tableStyles.tableHeaderColStart]}>
										<Text style={tableStyles.tableCell}>Fixed Term Quote</Text>
									</View>
									<View style={tableStyles.tableHeaderColEnd}>
										<Text style={tableStyles.tableCell}>Price</Text>
									</View>
								</View>

								{quote_stages.slice(2).map((stage, index) => (
									<View key={index}>
										<View style={tableStyles.tableRow}>
											<View style={tableStyles.tableColStart}>
												<Text style={tableStyles.tableCell}>
													<Text style={{ fontWeight: 'bold' }}>
														Stage - {stage.stage}
													</Text>
												</Text>
											</View>
											<View style={tableStyles.tableColEnd}>
												<Text style={tableStyles.tableCell}></Text>
											</View>
										</View>
										<TableRow
											item={{
												service: `Total Labour, Services, and Delivery incl. ${stage.hire_period} weeks hire`,
												price: numberFormat.format(
													Number(
														stage.total_hire_fee + Number(stage.stage_total)
													)
												),
												backgroundColor: 'whitesmoke',
											}}
										/>
										<TableRow
											item={{
												service: `Weekly Hire Fee after initial period ${numberFormat.format(
													stage.total_hire_fee / stage.hire_period
												)} p/w`,
												backgroundColor: '#E8E8E8',
											}}
										/>
									</View>
								))}

								{remainingQuoteAddons?.length > 0 && (
									<>
										<View style={tableStyles.tableRow}>
											<View style={tableStyles.tableColStart}>
												<Text style={tableStyles.tableCell}>
													<Text style={{ fontWeight: 'bold' }}>
														Additional Services
													</Text>
												</Text>
											</View>
											<View style={tableStyles.tableColEnd}>
												<Text style={tableStyles.tableCell}></Text>
											</View>
										</View>
										{remainingQuoteAddons.map((addon, index) => (
											<TableRow
												key={index}
												item={{
													service: `${addon.description}`,
													price: numberFormat.format(addon.total_cost),
												}}
											/>
										))}
									</>
								)}

								<TableRowEnd />
								<View style={[tableStyles.tableRow]}>
									<View style={tableStyles.tableSummaryStart}>
										<Text style={tableStyles.tableCell}>
											<Text
												style={{ fontWeight: 'semibold', textAlign: 'right' }}>
												SUB TOTAL
											</Text>
										</Text>
									</View>
									<View style={tableStyles.tableSummaryEnd}>
										<Text
											style={[tableStyles.tableCell, { textAlign: 'right' }]}>
											{numberFormat.format(subtotal)}
										</Text>
									</View>
								</View>
								<View style={[tableStyles.tableRow]}>
									<View style={tableStyles.tableSummaryStart}>
										<Text style={tableStyles.tableCell}>
											<Text
												style={{ fontWeight: 'semibold', textAlign: 'right' }}>
												GST
											</Text>
										</Text>
									</View>
									<View style={tableStyles.tableSummaryEnd}>
										<Text
											style={[tableStyles.tableCell, { textAlign: 'right' }]}>
											{numberFormat.format(subtotal_gst)}
										</Text>
									</View>
								</View>
								<View style={[tableStyles.tableRow]}>
									<View style={tableStyles.tableSummaryStart}>
										<Text style={tableStyles.tableCell}>
											<Text
												style={{ fontWeight: 'semibold', textAlign: 'right' }}>
												TOTAL (inc. GST)
											</Text>
										</Text>
									</View>
									<View style={tableStyles.tableSummaryEnd}>
										<Text
											style={[tableStyles.tableCell, { textAlign: 'right' }]}>
											{numberFormat.format(total)}
										</Text>
									</View>
								</View>
							</View>
							<Closing quote={quote_data} />
							<Footer />
						</Page>
					)}
				</>
			)}

			{files.length > 0 ? (
				<Page size="A4" style={styles.page}>
					<Heading />
					<View style={styles.content}>
						<View style={styles.imageRow}>
							{files.map(
								(
									image: {
										url: string
										description: string
										section_label: string
									},
									index: number
								) => (
									<View style={styles.image}>
										{isImage(image.url) ? (
											<Image
												key={index}
												src={image.url}
												style={{ maxWidth: '100%', maxHeight: '100%' }}
											/>
										) : (
											<Link key={index} src={image.url}>
												<Text
													style={{
														color: 'blue',
														textDecoration: 'underline',
													}}>
													Click here to view file
												</Text>
											</Link>
										)}
										<View
											style={{
												flexDirection: 'row',
												gap: 5,
												alignItems: 'center',
												marginTop: 5,
											}}>
											<Text
												style={{
													...styles.subTextBold,
													fontSize: 10,
													margin: 0,
												}}>
												Description:
											</Text>
										</View>
										<Text style={styles.imageText}>{image.description}</Text>
										<View
											style={{
												flexDirection: 'row',
												gap: 5,
												alignItems: 'center',
												marginTop: 50,
											}}>
											<Text
												style={{
													...styles.subTextBold,
													fontSize: 10,
													margin: 0,
												}}>
												Section:
											</Text>
										</View>
										<Text style={styles.imageText}>{image.section_label}</Text>
									</View>
								)
							)}
						</View>
					</View>
					<Footer />
				</Page>
			) : null}

			<Page size="A4" style={styles.page}>
				<Heading />
				<View style={styles.content}>
					<Text style={styles.heading}>Additional Conditions</Text>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						{quote_data.terms}
					</Text>
					<Text style={{ ...styles.subTextMargin, marginLeft: 4 }}>
						<Link src="https://protrade.net.nz/wp-content/uploads/2023/03/ProtradeTermsConditions.pdf">
							View our Terms and Conditions here
						</Link>
					</Text>
				</View>
				<Footer />
			</Page>
		</Document>
	)
}
