import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { ApproveInvoices, DeleteInvoice } from 'components/Invoices'
import { InvoiceServices, DataTableServices } from 'services'
import { Badge, Container, DataTableHeader, PageHeading } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { numberFormat } from 'utilities'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { MYOBStatus } from 'components/Invoices/MYOBStatus'

export const MainInvoicesTable = () => {
	const location = useLocation()
	const { data, isLoading, enableCreateUpdate } =
		InvoiceServices.useInvoices('Pending')
	const [invoicesSelected, setInvoicesSelected] = useState([])

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'invoiceType',
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterInitialValue: '',
				filterOptions: [],
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
			},
			{
				filterName: 'branch',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterInitialValue: '',
				filterOptions: [
					'Hawkes Bay',
					'Gisborne',
					'Wairarapa',
					'Taupo',
					'Dunedin',
					'Cromwell'
				],
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
			},
			{
				filterName: 'on_hire',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterInitialValue: '',
				filterOptions: ['Yes', 'No', ''],
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
			},
		],
		additionalGlobalFilterFields: [
			'invoiceType',
			'zone_label',
			'type',
			'description',
			'on_hire',
			'taskData.actual_square_meters',
			'erect_percent',
			'date_on_hire',
			'completed_date',
			'days_on_hire',
			'invoice_id',
			'weekly_hire_rate',
			'total',
			'updatedAt',
			'jobData.job_num',
			'jobData.site',
			'total_hours',
			'hourly_rate',
			'sqm_rate',
			'jobData.clientData.client_name',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	const handleRowClick = (event: any) => {
    if (!event.originalEvent.target.closest('a')) {
			setInvoicesSelected(event.value);
    } 
  }

	// <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
	// 				<Column field="invoiceType" header="Invoice Type" />
	// 				<Column field="zone_label" header="Section Label" />
	// 				<Column field="type" header="Type" />
	// 				<Column field="description" header="Description" />
	// 				<Column field="on_hire" header="On Hire" />
	// 				<Column field="erect_percent" header="% Erect" />
	// 				<Column field="date_on_hire" header="Date On Hire" />
	// 				<Column field="completed_date" header="Date Completed" />
	// 				<Column field="days_on_hire" header="Days on Hire" />
	// 				<Column
	// 					field="weekly_hire_rate"
	// 					header="Weekly Hire Rate"
	// 					body={(row: { weekly_hire_rate: number }) => {
	// 						return numberFormat.format(row.weekly_hire_rate || 0) || ''
	// 					}}
	// 				/>
	// 				<Column
	// 					field="total"
	// 					header="Total"
	// 					body={(row: { total: number }) => {
	// 						return numberFormat.format(row.total || 0) || ''
	// 					}}
	// 				/>
	// 				{/* <Column field="quote_num" header="Quote" /> */}
	// 				<Column
	// 					field="handover_url"
	// 					header="Handover Certificate"
	// 					body={(row: { handover_url: string }) => {
	// 						if (row.handover_url) {
	// 							return (
	// 								<a href={row.handover_url} target="_blank" rel="noreferrer">
	// 									Link
	// 								</a>
	// 							)
	// 						}
	// 						return <></>
	// 					}}
	// 				/>
	// 				<Column
	// 					field="updatedAt"
	// 					header="Last Time updated"
	// 					body={(row: { updatedAt: string }) => {
	// 						return new Date(row.updatedAt).toLocaleDateString('en-NZ')
	// 					}}
	// 				/>

	const rowData: any[] | undefined = data?.map((item: any) => {
		const isDayworks = item.jobData?.quote_id ? false : true
		return {
			invoiceType: item.invoiceType,
			zone_label: item.zone_label,
			type: item.type,
			description: item.invoiceType === 'Weekly Hire' ? 'Weekly Hire' : item.description,
			on_hire: item.on_hire,
			actual_square_meters: item?.installed_sqm,
			erect_percent: item.erect_percent,
			date_on_hire: item.date_on_hire,
			completed_date: item.completed_date,
			days_on_hire: item.days_on_hire,
			invoice_id: item.invoice_id,
			weekly_hire_rate: item.weekly_hire_rate,
			total: item.total,
			handover_url: item.handover_url,
			updatedAt: item.updatedAt,
			id: item.id,
			job_id: item.job_id,
			jobData: item.jobData,
			taskData: item.taskData,
			total_hours: item.total_hours,
			sqm_rate: isDayworks ? item.sqm_rate : 0,
			hourly_rate: isDayworks ? item.hourly_rate : 0,
			isDayworks,
			item_code: item.item_code,
			branch: item.jobData.branch,
			complete_percent: item?.complete_percent || 0,
			quote_id: item?.jobData?.quote_id,
			quote_number: item?.Quote_Number,
		}
	})

	return (
		<div className="card">
			<PageHeading title="Invoices" />
			<div className="mb-4 mx-2">
				<MYOBStatus />
			</div>
			<div className="px-8 flex justify-start space-x-4">
				<ApproveInvoices
					invoicesSelected={invoicesSelected}
					setInvoicesSelected={setInvoicesSelected}
				/>
				<ApproveInvoices
					invoicesSelected={invoicesSelected}
					setInvoicesSelected={setInvoicesSelected}
					endOfMonth
				/>
			</div>
			<br />
			<div className="px-8"></div>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={rowData}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading}
					dataKey="invoice_id"
					groupRowsBy="job_id"
					sortField="job_id"
					sortOrder={-1}
					rowGroupMode="subheader"
					rowGroupHeaderTemplate={headerRow}
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					selection={invoicesSelected}
					stripedRows
					onSelectionChange={(e) => {
						handleRowClick(e)
					}}
					emptyMessage="No Invoices found.">
					<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
					<Column field="jobData.job_num" header="Job Number" hidden />
					<Column field="invoiceType" header="Invoice Type" />
					<Column field="zone_label" header="Section Label" />
					<Column field="type" header="Type" />
					<Column field="item_code" header="Item Code" />
					<Column field="branch" header="Branch" {...FilterColumn.branch} />
					<Column field="description" header="Description" />
					<Column field="on_hire" header="On Hire" {...FilterColumn.on_hire} />
					<Column field="actual_square_meters" header="Installed SQM" />
					<Column field="total_hours" header="Total Hours" />
					<Column
						field="complete_percent"
						header="% Complete"
						body={(row: { complete_percent: number }) => {
							return row.complete_percent === 100 ? (
								<Badge type={'Green'} text={String(row.complete_percent)} />
							) : (
								<Badge type={'Orange'} text={String(row.complete_percent)} />
							)
						}}
					/>
					{/* <Column field="erect_percent" header="% Erect" /> */}
					<Column field="date_on_hire" header="Date On Hire" />
					<Column field="completed_date" header="Date Completed" />
					<Column field="days_on_hire" header="Days on Hire" />
					<Column field="hire_period_included" header="Hire Period Included" />
					<Column
						field="weekly_hire_rate"
						header="Weekly Hire Rate"
						body={(row: { weekly_hire_rate: number }) => {
							return numberFormat.format(row.weekly_hire_rate || 0) || ''
						}}
					/>
					<Column
						field="sqm_rate"
						header="SQM Rate"
						body={(row: { sqm_rate: number }) => {
							return numberFormat.format(row.sqm_rate || 0) || ''
						}}
					/>
					<Column
						field="hourly_rate"
						header="Hourly Rate"
						body={(row: { hourly_rate: number }) => {
							return numberFormat.format(row.hourly_rate || 0) || ''
						}}
					/>
					<Column
						field="total"
						header="Total"
						body={(row: { total: number }) => {
							return numberFormat.format(row.total || 0) || ''
						}}
					/>
					<Column
						field="quote_id"
						header="Quote Number"
						body={(row: { quote_id: string; quote_number: string }) => {
							if (row.quote_id && row.quote_number) {
								return (
									<Link
										to={AppRoutes.privateRoutes.QuotesDetail.replace(
											':id',
											row.quote_id
										)}
										className="flex items-center">
										{row.quote_number}
									</Link>
								)
							}
							return <></>
						}}
					/>
					<Column
						field="updatedAt"
						header="Last Time updated"
						body={(row: { updatedAt: string }) => {
							return new Date(row.updatedAt).toLocaleDateString('en-NZ')
						}}
					/>
					<Column
						header="Edit"
						body={(rowData) => {
							return (
								<Link
									to={{
										pathname: AppRoutes.privateRoutes.editInvoice
											.replace(':id', rowData.id)
											.replace(
												':invoiceType',
												rowData.invoiceType === 'Weekly Hire'
													? 'weeklyHire'
													: 'edInvoice'
											),
									}}
									state={{
										background: location,
										name: 'editInvoice',
										isDayworks: rowData.isDayworks,
										job_id: rowData.job_id,
										total: rowData.total,
										hours: rowData.total_hours,
									}}>
									<PencilSquareIcon className="h-4 w-4 text-gray-500" />
								</Link>
							)
						}}
					/>
					{enableCreateUpdate && (
						<Column
							header="Delete"
							body={(row) => <DeleteInvoice invoice={row} />}
						/>
					)}
				</DataTable>
			</Container>
		</div>
	)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const headerRow = (data: any) => (
	<span className="text-gray-900 font-bold">{`${data?.jobData?.job_num} - ${data?.jobData?.clientData?.client_name} - ${data?.jobData?.site}`}</span>
)
