import { ContactServices } from 'services'
import { Spinner, Table } from 'common'
import { PencilIcon } from '@heroicons/react/24/solid'
import { ContacsForm } from 'components/Contacts/ContactForm'
import { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from 'config'
import moment from 'moment'

interface ContactsTableProps {
	type: string
	id?: number | string
}

export const ContactsTable = ({ type, id }: ContactsTableProps) => {
	const location = useLocation()
	const [openCreate, setOpenCreate] = useState(false)
	const { data: contacts, isLoading } = type === 'client' ? 
		ContactServices.useContactsByClientId(id) : ContactServices.useContactsByProjectId(id)

	const preprocessedContacts = useMemo(() => {
		if (!isLoading && contacts) {
			const formattedContacts = contacts.map((contact: any) => {
				return {
					...contact,
					createdAt: moment(contact.createdAt).format('DD/MM/YYYY'),
				}
			})

			return formattedContacts
		}
		return []
	}, [contacts, isLoading])

	const columns = [
		{
			field: 'name',
			header: 'Name',
		},
		{
			field: 'email',
			header: 'Email',
		},
		{
			field: 'phone',
			header: 'Phone',
		},
		{
			field: 'status',
			header: 'Status',
		},
		{
			field: 'createdAt',
			header: 'Created At',
		},
		{
			field: 'id',
			header: 'Edit',
			body: (rowData: { id: string }) => (
				<Link
					to={{
						pathname: AppRoutes.privateRoutes.ClientContactEdit.replace(
							':id',
							rowData.id
						),
					}}
					state={{
						background: location,
						name: type === 'client' ? 'editContact' : 'editProjectContact',
					}}>
					<PencilIcon className="text-gray-600 h-4 w-4" />
				</Link>
			),
		},
	]

	return (
		<>
			<Table
				title="Contact Details"
				columns={columns}
				data={preprocessedContacts}
				isLoading={isLoading}
				ActionName="Add New Contact"
				setOpen={setOpenCreate}
				disableButtons
			/>
			<ContacsForm
				client_id={id}
				project_id={id}
				formType="create"
				heading={`Create ${type === 'client' ? 'Client' : 'Project'} Contact`}
				open={openCreate}
				setOpen={setOpenCreate}
				type={type}
			/>
		</>
	)
}
