import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectsComponents } from 'components'

export const EditProjectForm = () => {
	const [openProjectForm, setOpenProjectForm] = useState(true)
	const navigate = useNavigate()
	const { id } = useParams()

	useEffect(() => {
		if (!openProjectForm) {
			navigate(-1)
		}
	}, [openProjectForm])

	return (
		<ProjectsComponents.EditProjectForm
			formType="update"
			heading="Edit Project"
			open={openProjectForm}
			setOpen={setOpenProjectForm}
			project_id={id}
		/>
	)
}
