import { SectionedQuoteLineDTO } from 'models'
import { numberFormat } from 'utilities'

interface Props {
	section: SectionedQuoteLineDTO
	index: number
}

export const QuoteAcceptLine = ({ section, index }: Props) => {
	const visibleLineImages = section.images.filter(
		(image) => image.includeInQuote
	)
	console.log('Images: ', visibleLineImages)
	return (
		<div
			className="border border-gray-300 p-4 my-4 rounded-lg grid grid-cols-1"
			key={index}>
			<div className="overflow-x-auto">
				<table className="w-full border-collapse mb-4">
					<thead>
						<tr>
							<td className="px-4 py-2 text-left text-gray-500 text-sm">
								Section {section.number} - {section.name}
							</td>
							<td className="border border-gray-300 px-4 py-2 text-center">
								Total Price (ex. GST)
							</td>
							<td className="border border-gray-300 px-4 py-2 text-center">
								Weekly Hire (ex. GST)
							</td>
						</tr>
					</thead>
					<tbody>
						{section.lines?.map((line, lineIndex) => (
							<tr key={`line-${index}-${lineIndex}`} className="h-9">
								<td className="px-4 py-2 text-xl font-semibold mb-2">
									{line.itemDescription}
								</td>
								<td className="border-l border-b border-gray-300 font-semibold w-48 px-4 text-center">
									{numberFormat.format(Number(line.price))}
								</td>
								<td className="border-r border-b border-gray-300 font-semibold w-48 px-4 text-center">
									{numberFormat.format(Number(line.weekly_hire))}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			{visibleLineImages?.map((image, img_index) => {
				const isImage = (fileUrl: string) => {
					if (!fileUrl) return false
					const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp']
					const extension = fileUrl.split('.').pop()?.toLowerCase()
					return extension ? imageExtensions.includes(extension) : false
				}

				return (
					<div
						key={`P${index}-${img_index}`}
						className="p-5 mb-5 mt-5 border rounded-lg bg-gray-100">
						<p className="text-gray-700 font-bold">
							{section.name} File {img_index + 1}:
						</p>
						<p className="text-gray-700 italic">{image.imageDescription}</p>
						{isImage(image.imageUrl) ? (
							<img
								src={image.imageUrl}
								alt={image.imageDescription}
								className="mt-2 max-w-full"
							/>
						) : (
							<div className="flex items-center pt-4">
								<a
									href={image.imageUrl}
									target="_blank"
									rel="noreferrer"
									className="text-blue-400 text-xl">
									Link
								</a>
							</div>
						)}
					</div>
				)
			})}
		</div>
	)
}
