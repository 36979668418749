import { useMemo, useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTableServices } from 'services'
import { Button, ConfirmationDialog, DataTableHeader, Input, Table, TextArea } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Link } from 'react-router-dom'
import { FolderOpenIcon, PencilIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { useNotes } from 'services/notes/getAllNotes'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { NotesForm } from 'components/Notes/Form'
import moment from 'moment'
import { useUpdateNotes } from 'services/notes'
import { numberFormat } from 'utilities'

export const TasksMainTable = () => {
	const { UpdateNotes } = useUpdateNotes()
	const { data: notesData, isLoading: notesLoading } = useNotes()
	const user = useSelector((store: AppStore) => store.user)
	const [showNotesForm, setShowNotesForm] = useState(false)

	const [noteID, setNoteID] = useState('')
	const [type, setType] = useState('')
	const [id, setId] = useState('')

	const preprocessedData = useMemo(() => {
		if (!notesLoading && notesData && user) {
			const filteredNotes = notesData.filter((note: any) => {
				if (note.followUpDate) {
					if (
						note.clientData?.protradeSalesPersonData?.email === user.email ||
						note.projectData?.protradeSalesPersonData?.email === user.email ||
						note.staffData?.email === user.email
					) {
						return true
					}
				}
				return false
			})

			const sortedNotes = filteredNotes.sort((a: any, b: any) => {
				const dateA = moment(a.followUpDate, 'DD/MM/YYYY')
				const dateB = moment(b.followUpDate, 'DD/MM/YYYY')
				return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0
			})

			return sortedNotes
		}
		return []
	}, [notesData, notesLoading, user])

	console.log({preprocessedData})

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [],
		additionalGlobalFilterFields: [
			'projectData.project_name',
			'clientData.client_name',
			'fileName',
			'notes',
			'followUpDate',
			'createdDate',
			'estimatedValue',
			'status'
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	const columns = [
		{
			header: 'Project (Details)',
			field: 'projectData.project_name',
			body: (row: { projectData: any }) => {
				return row.projectData ? (
					<Link
						key={`details${row.projectData.id}`}
						to={AppRoutes.privateRoutes.ProjectsDetail.replace(
							':id',
							row.projectData.id || ''
						)}
						className="flex items-center">
						<FolderOpenIcon className="h-4 w-4 text-gray-500 mr-2" />
						<span className="hover:text-gray-800">
							{row.projectData.project_name}
						</span>
					</Link>
				) : (
					''
				)
			},
		},
		{
			header: 'Client (Details)',
			field: 'clientData.client_name',
			body: (row: { clientData: any }) => {
				return row.clientData ? (
					<Link
						key={`details${row.clientData.id}`}
						to={AppRoutes.privateRoutes.ClientsDetail.replace(
							':id',
							row.clientData.id || ''
						)}
						className="flex items-center">
						<FolderOpenIcon className="h-4 w-4 text-gray-500 mr-2" />
						<span className="hover:text-gray-800">
							{row.clientData.client_name}
						</span>
					</Link>
				) : (
					''
				)
			},
		},
		{
			header: 'Quote (Details)',
			field: 'quoteData.quote_num',
			body: (row: { quoteData: any }) => {
				return row.quoteData ? (
					<Link
						key={`details${row.quoteData.id}`}
						to={AppRoutes.privateRoutes.ClientsDetail.replace(
							':id',
							row.quoteData.id || ''
						)}
						className="flex items-center">
						<FolderOpenIcon className="h-4 w-4 text-gray-500 mr-2" />
						<span className="hover:text-gray-800">
							{row.quoteData.quote_num}
						</span>
					</Link>
				) : (
					''
				)
			},
		},
		{
			header: 'Note Title',
			field: 'fileName',
		},
		{
			header: 'Note Type',
			field: 'fileType',
		},
		{
			header: 'Estimated $ Value',
			field: 'estimatedValue',
			body: (row: { fileType: string; estimatedValue: number }) =>
				row.fileType === 'Lead' ? (
					<>{numberFormat.format(row.estimatedValue)}</>
				) : (
					<></>
				),
		},
		{
			header: 'Status',
			field: 'status',
		},
		{
			header: 'File',
			field: 'fileUrl',
			body: (row: { fileUrl: string }) => {
				return row.fileUrl != '' ? (
					<a className="text-blue-600" href={row.fileUrl}>
						Link
					</a>
				) : (
					<a>No File Link</a>
				)
			},
		},
		{
			header: 'File Description',
			field: 'fileDescription',
		},
		{
			header: 'Note',
			field: 'notes',
			body: (row: { notes: string }) => {
				const notes = row.notes.split('\n').map((note: string) => (
					<span key={note}>
						{note}
						<br />
					</span>
				))
				return <>{notes}</>
			},
		},
		{
			header: 'Follow Up Date',
			field: 'followUpDate',
			bodyClassName: (row: { followUpDate: string }) => {
				const followUpDate = moment(row.followUpDate, 'DD/MM/YYYY')
				const isPastDate = followUpDate.isBefore(moment(), 'day')
				return isPastDate ? 'bg-red-400' : ''
			},
		},
		{
			header: 'Created Date',
			field: 'createdDate',
		},
		{
			field: 'id',
			header: 'Edit',
			body: (row: any) => {
				return row.id != null ? (
					<Button
						type="button"
						variant="primary"
						onClick={() => {
							setNoteID(row.id)
							setShowNotesForm(true)
							if (row?.projectData) {
								setId(row?.projectData?.id)
								setType('project')
							} else {
								setId(row?.clientData?.id)
								setType('client')
							}
						}}
						size="sm">
						<PencilIcon className=" h-4 w-4" />
					</Button>
				) : (
					<div></div>
				)
			},
		},
		{
			field: 'id',
			header: 'Close',
			body: (row: any) => {
				return row.id && row.followUpDate ? (
					<ConfirmationDialog
						icon="danger"
						title="Close Task"
						body="Are you sure you want to close this task?"
						triggerButton={
							<button type="button">
								<XCircleIcon className="h-4 w-4 text-red-500" />
							</button>
						}
						confirmButton={
							<Button
								size="sm"
								variant="danger"
								onClick={async () => {
									const note = {
										...row,
										followUpDate: null,
										notes: `${row.notes}\n${moment().format(
											'DD/MM/YYYY'
										)} - Closed`,
									}
									await UpdateNotes(note, row.id)
								}}>
								Close
							</Button>
						}></ConfirmationDialog>
				) : (
					<div></div>
				)
			},
		},
	]

	return (
		<div>
			<NotesForm
				heading="Create Notes / File "
				formType="create"
				open={showNotesForm}
				setOpen={setShowNotesForm}
				id={id}
				noteID={noteID}
				type={type}
				setNoteID={setNoteID}
				setType={setType}
				setId={setId}
			/>
			<Table
				columns={columns}
				data={preprocessedData}
				isLoading={notesLoading}
				title="Tasks"
				setShowNotesForm={setShowNotesForm}
			/>
		</div>
	)
}
