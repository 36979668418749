import { PencilIcon, TrashIcon, XCircleIcon } from '@heroicons/react/24/solid'
import { Button, ConfirmationDialog } from 'common'
import moment from 'moment'
import { useDeleteNote } from 'services/notes/deleteNote'
import { useUpdateNotes } from 'services/notes'

interface ColumnsProps {
	setShowNotesForm: (open: boolean) => void
	setNoteID: (id: string) => void
	type?: string
}

const Columns = ({ setShowNotesForm, setNoteID, type }: ColumnsProps) => {
	const { deleteNote } = useDeleteNote()
	const { UpdateNotes } = useUpdateNotes()

	return [
		{
			header: 'Created Date',
			field: 'createdDate',
			body: (row: { createdDate: Date }) => {
				return row.createdDate
					? moment(row.createdDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
					: ''
			},
		},
		{
			header: 'File Name',
			field: 'fileName',
		},
		{
			header: 'File Type',
			field: 'fileType',
		},
		...(type !== 'job'
			? [
					{
						header: 'File Description',
						field: 'fileDescription',
					},
			  ]
			: []),
		{
			header: 'Notes',
			field: 'notes',
			body: (row: { notes: string }) => {
				const notes = row.notes.split('\n').map((note: string) => (
					<span key={note}>
						{note}
						<br />
					</span>
				))
				return <>{notes}</>
			},
		},
		{
			header: 'File',
			field: 'fileUrl',
			body: (row: { fileUrl: string }) => {
				return row.fileUrl != '' ? (
					<a className="text-blue-600" href={row.fileUrl}>
						Link
					</a>
				) : (
					<a>Invalid Link</a>
				)
			},
		},
		{
			field: 'id',
			header: 'Edit',
			body: (row: { id: string }) => {
				return row.id != null ? (
					<Button
						type="button"
						variant="primary"
						onClick={() => {
							setNoteID(row.id)
							setShowNotesForm(true)
						}}
						size="sm">
						<PencilIcon className=" h-4 w-4" />
					</Button>
				) : (
					<div></div>
				)
			},
		},
		{
			field: 'Delete',
			header: 'Delete',
			body: (row: { id: number }) => {
				return row.id != null ? (
					<ConfirmationDialog
						icon="danger"
						title="Delete Task"
						body="Are you sure you want to delete this task? This action is unrecoverable!"
						triggerButton={
							<button type="button">
								<TrashIcon className="h-4 w-4 text-gray-500" />
							</button>
						}
						confirmButton={
							<Button
								size="sm"
								variant="danger"
								onClick={async () => deleteNote(row.id)}>
								Delete
							</Button>
						}
					/>
				) : (
					<div></div>
				)
			},
		},
		{
			field: 'Close',
			header: 'Close',
			body: (row: any) => {
				return row.id && row.followUpDate ? (
					<ConfirmationDialog
						icon="danger"
						title="Close Task"
						body="Are you sure you want to close this task?"
						triggerButton={
							<button type="button">
								<XCircleIcon className="h-4 w-4 text-red-500" />
							</button>
						}
						confirmButton={
							<Button
								size="sm"
								variant="danger"
								onClick={async () => {
									const note = {
										...row,
										followUpDate: null,
										notes: `${row.notes}\n${moment().format(
											'DD/MM/YYYY'
										)} - Closed`,
									}
									await UpdateNotes(note, row.id)
								}}>
								Close
							</Button>
						}></ConfirmationDialog>
				) : (
					<div></div>
				)
			},
		},
	]
}

export default Columns
