import { IQuoteZones } from 'models'
import { IQuoteAdditionalLines, IRates } from 'models/simple_quotes.model'

const initLine: IQuoteAdditionalLines = {
	id: null,
	type: '',
	description: '',
	duration_quantity: 0,
	fixed_charge: 0,
	total_cost: 0,
	fixed_charge_is_dirty: false,
	total_cost_is_dirty: false,
	zone_id: null,
	zone_label: '',
}

const addNewQuoteLine = (
	lines: IQuoteAdditionalLines[],
	newLine?: IQuoteAdditionalLines
): IQuoteAdditionalLines[] => {
	let lineToAdd = initLine
	if (newLine) {
		lineToAdd = newLine
	}
	lines.push(lineToAdd)
	return lines
}

const removeQuoteLine = (
	index: number,
	quote_lines: IQuoteAdditionalLines[]
): IQuoteAdditionalLines[] => {
	const newQuoteLines = [...quote_lines]
	newQuoteLines.splice(index, 1)
	return newQuoteLines
}

const updateQuoteLine = (
	index: number,
	quote_lines: IQuoteAdditionalLines[],
	field: string,
	value: string | number
): IQuoteAdditionalLines[] => {
	const newQuoteLines = quote_lines.map((line, i) => {
		if (i === index) {
			return {
				...line,
				[field]: value,
			}
		}
		return line
	})
	return newQuoteLines
}

const calculateLines = (
	index: number,
	lines: IQuoteAdditionalLines[],
	rates: IRates[],
	field: string,
	manual?: boolean,
): IQuoteAdditionalLines[] => {
	const newLines = lines.map((line, i) => {
		const rate = rates.find((rate) => rate.service === line.type)?.fee
		if (!rate) return line
		if (i === index) {
			if (field === 'type') {
				line.description = line.type.split(' (')[0]
			}
			if (
				!line.fixed_charge_is_dirty &&
				!line.total_cost_is_dirty &&
				(field === 'duration_quantity' || field === 'type')
			) {
				const transportTypes = [
					'Delivery/Collection A (10kms from branch)',
					'Delivery/Collection B (20kms from branch)',
					'Delivery/Collection C (Over 45kms from branch)',
				]
				let type = ''
				let newRate: number = rate
				let total_cost = Number(rate) * Number(line.duration_quantity)
				let newQuantity = Number(line.duration_quantity)
				if (transportTypes.includes(line.type)) {
					type =
						newQuantity <= 20
							? 'Delivery/Collection A (10kms from branch)'
							: newQuantity <= 45
							? 'Delivery/Collection B (20kms from branch)'
							: 'Delivery/Collection C (Over 45kms from branch)'
					if (type === 'Delivery/Collection A (10kms from branch)') {
						total_cost = 190
						newRate = 190
					} else if (type === 'Delivery/Collection B (20kms from branch)') {
						total_cost = 270
						newRate = 270
					} else {
						const transportRate = rates.find(
							(rate) => rate.service === 'Delivery/Collection C (Over 45kms from branch)'
						)?.fee
						total_cost = Number(transportRate) * newQuantity * 2
						newRate = transportRate as number
					}
				}
				const newLine: IQuoteAdditionalLines = {
					...line,
					...(!manual && {duration_quantity: newQuantity}),
					fixed_charge: Number(newRate),
					total_cost: parseFloat(total_cost.toFixed(2)),
					...(transportTypes.includes(line.type) && { type }),
				}
				return newLine
			} else {
				if (field === 'duration_quantity') {
					if (!line.total_cost_is_dirty) {
						const transportTypes = [
							'Delivery/Collection A (10kms from branch)',
							'Delivery/Collection B (20kms from branch)',
							'Delivery/Collection C (Over 45kms from branch)',
						]
						let type = ''
						let newRate: number = rate
						let total_cost = Number(rate) * Number(line.duration_quantity)
						let newQuantity = Number(line.duration_quantity)
						if (transportTypes.includes(line.type)) {
							type =
								newQuantity <= 20
									? 'Delivery/Collection A (10kms from branch)'
									: newQuantity <= 45
									? 'Delivery/Collection B (20kms from branch)'
									: 'Delivery/Collection C (Over 45kms from branch)'
							if (type === 'Delivery/Collection A (10kms from branch)') {
								total_cost = 190
								newRate = 190
							} else if (type === 'Delivery/Collection B (20kms from branch)') {
								total_cost = 270
								newRate = 270
							} else {
								const transportRate = rates.find(
									(rate) => rate.service === 'Delivery/Collection C (Over 45kms from branch)'
								)?.fee
								total_cost =
									Number(transportRate) * newQuantity * 2
								newRate = transportRate as number
							}
						}
						
						const newLine: IQuoteAdditionalLines = {
							...line,
							...(!manual && {duration_quantity: newQuantity}),
							total_cost: parseFloat(total_cost.toFixed(2)),
							fixed_charge: line.fixed_charge_is_dirty
								? line.fixed_charge
								: Number(newRate),
							...(transportTypes.includes(line.type) && { type }),
						}
						return newLine
					} else {
						const transportTypes = [
							'Delivery/Collection A (10kms from branch)',
							'Delivery/Collection B (20kms from branch)',
							'Delivery/Collection C (Over 45kms from branch)',
						]
						let type = ''
						let newRate: number = rate
						let newQuantity = Number(line.duration_quantity)
						if (transportTypes.includes(line.type)) {
							// newQuantity = newQuantity * 2
							type =
								newQuantity <= 20
									? 'Delivery/Collection A (10kms from branch)'
									: newQuantity <= 45
									? 'Delivery/Collection B (20kms from branch)'
									: 'Delivery/Collection C (Over 45kms from branch)'
							if (type === 'Delivery/Collection A (10kms from branch)') {
								newRate = 190
							} else if (type === 'Delivery/Collection B (20kms from branch)') {
								newRate = 270
							} else {
								const transportRate = rates.find(
									(rate) => rate.service === 'Delivery/Collection C (Over 45kms from branch)'
								)?.fee
								newRate = transportRate as number
							}
						}
						const newLine: IQuoteAdditionalLines = {
							...line,
							...(!manual && {duration_quantity: newQuantity}),
							fixed_charge: line.fixed_charge_is_dirty
								? line.fixed_charge
								: Number(newRate),
							...(transportTypes.includes(line.type) && { type }),
						}
						return newLine
					}
				} else if (field === 'fixed_charge') {
					if (!line.total_cost_is_dirty) {
						const newLine: IQuoteAdditionalLines = {
							...line,
							total_cost: parseFloat(
								(
									Number(line.fixed_charge) * Number(line.duration_quantity)
								).toFixed(2)
							),
							fixed_charge_is_dirty: true,
						}
						return newLine
					}
				} else if (field === 'total_cost') {
					const newLine: IQuoteAdditionalLines = {
						...line,
						total_cost: parseFloat(Number(line.total_cost).toFixed(2)),
						total_cost_is_dirty: true,
					}
					return newLine
				}
			}
		}
		return line
	})
	return newLines
}

const checkZones = (
	zones: IQuoteZones[],
	lines: IQuoteAdditionalLines[],
	index: number,
	value: number | string
): IQuoteAdditionalLines[] => {
	const newQuoteLines = lines.map((line, idx) => {
		if (idx === index) {
			const zone = zones.find((zone) => zone.zone_id === value)
			if (value === zones.length + 1) {
				line.zone_label = 'Additional'
				line.zone_id = value
			} else if (!zone) {
				line.zone_id = null
				line.zone_label = ''
			} else {
				line.zone_label = zone.zone_label
				line.zone_id = Number(zone.zone_id)
			}
		}
		return line
	})

	return newQuoteLines
}

export const quoteAdditionalLinesFn = {
	addNewQuoteLine,
	removeQuoteLine,
	updateQuoteLine,
	calculateLines,
	checkZones,
}
