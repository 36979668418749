import { PencilIcon } from '@heroicons/react/24/solid'
import { Badge, Spinner, Table } from 'common'
import { AppRoutes } from 'config'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { InvoiceServices } from 'services'
import { numberFormat } from 'utilities'
import { EdInvoiceCreateForm } from './CreateEdInvoice'
import { FolderOpenIcon } from '@heroicons/react/24/outline'

interface WeeklyHireTableProps {
	job_id?: number
}

export const EDInvoicesTable = ({ job_id }: WeeklyHireTableProps) => {
	const location = useLocation()
	const [openCreate, setOpenCreate] = useState(false)
	const { data, isLoading } = InvoiceServices.useEdInvoicesByJobId(job_id)
	// console.log({data})

	if (isLoading) {
		return <Spinner />
	}

	let isDayworks = false
	const rowData = data?.map((item: any) => {
		isDayworks = item?.jobData?.quote_id ? false : true
		return {
			zone_label: item.zone_label,
			type: item.type,
			description: item.description,
			erect: item.erect,
			dismantle: item.dismantle,
			total: item.total,
			complete_percent: item.complete_percent,
			PO_Number: item.PO_Number,
			quote_id: item?.jobData?.quote_id,
			quote_number: item?.Quote_Number,
			id: item.id,
			actual_square_meters:
				item.erect && !isDayworks ? item?.taskData?.actual_square_meters : null,
			status: item.status,
			hourly_rate: item?.jobData?.quote_id ? 0 : item.hourly_rate,
			invoice_number: item.myobReference,
			total_hours: item.total_hours,
		}
	})

	console.log({ rowData })

	const columns = [
		{ field: 'zone_label', header: 'Section' },
		{ field: 'type', header: 'Type' },
		{
			field: 'description',
			header: 'Description',
		},
		{
			field: 'actual_square_meters',
			header: 'Installed SQM',
		},
		{
			field: 'total_hours',
			header: 'Total Hours',
		},
		// {
		// 	field: 'erect',
		// 	header: 'Erect Cost',
		// 	body: (row: { erect: number }) => {
		// 		return numberFormat.format(row.erect || 0)
		// 	},
		// },
		// {
		// 	field: 'dismantle',
		// 	header: 'Dismantle Cost',
		// 	body: (row: { dismantle: number }) => {
		// 		return numberFormat.format(row.dismantle || 0)
		// 	},
		// },
		{
			field: 'hourly_rate',
			header: 'Hourly Rate',
			body: (row: { hourly_rate: number }) => {
				return numberFormat.format(row.hourly_rate || 0)
			},
		},
		{
			field: 'total',
			header: 'Total',
			body: (row: { total: number }) => {
				return numberFormat.format(row.total || 0)
			},
		},
		{
			field: 'complete_percent',
			header: '% Complete',
			body: (row: { complete_percent: number }) => {
				return row.complete_percent === 100 ? (
					<Badge type={'Green'} text={String(row.complete_percent)} />
				) : (
					<Badge type={'Orange'} text={String(row.complete_percent)} />
				)
			},
		},
		{
			field: 'PO_Number',
			header: 'PO Number',
		},
		{
			field: 'quote_id',
			header: 'Quote Number',
			body: (row: { quote_id: string; quote_number: string }) => {
				if (row.quote_id) {
					return (
						<Link
							to={AppRoutes.privateRoutes.QuotesDetail.replace(
								':id',
								row.quote_id
							)}
							className="flex items-center">
							{row.quote_number}
						</Link>
					)
				}
				return <></>
			},
		},
		{
			field: 'invoice_number',
			header: 'Invoice Number',
		},
		{
			field: 'Edit',
			header: 'Edit',
			body: (row: {
				id: number
				status: string
				total: number
				total_hours: number
			}) => {
				if (row.status === 'Pending') {
					return (
						<Link
							to={{
								pathname: AppRoutes.privateRoutes.editInvoice
									.replace(':id', row.id.toString())
									.replace(':invoiceType', 'edInvoice'),
							}}
							state={{
								background: location,
								name: 'editInvoice',
								job_id: job_id,
								isDayworks,
								total: row.total,
								hours: row.total_hours,
							}}>
							<PencilIcon className="h-4 w-4 text-gray-500" />
						</Link>
					)
				}
				return <></>
			},
		},
	]

	return (
		<>
			<Table
				columns={columns}
				data={rowData}
				isLoading={isLoading ?? false}
				title="E&D Invoices"
				ActionName="Create E&D Invoice"
				setOpen={setOpenCreate}
				disableButtons
			/>
			<EdInvoiceCreateForm
				formType="create"
				heading="Create E&D Invoice"
				open={openCreate}
				setOpen={setOpenCreate}
				job_id={job_id}
				isDayworks={isDayworks}
			/>
		</>
	)
}
