import React from 'react'
import { Dropdown, DateSelect, Input } from 'common'
import { IStaffRow } from 'models/staff.model'
import { CreateFile } from 'common'

interface Props {
	values: IStaffRow
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void
	setFieldValue: (
		field: string,
		value: unknown,
		shouldValidate?: boolean | undefined
	) => void
	setFieldTouched: (
		field: string,
		touched?: boolean,
		shouldValidate?: boolean
	) => void
}

const levelOptions = [
	{ value: 'Elementary', label: 'Elementary' },
	{ value: 'Intermediate', label: 'Intermediate' },
	{ value: 'Advanced', label: 'Advanced' },
	{ value: 'Restricted Advanced', label: 'Restricted Advanced' },
	{ value: 'Suspended', label: 'Suspended' },
]

export const CertificateInScaffolding: React.FC<Props> = ({
	values,
	handleChange,
	handleBlur,
	setFieldValue,
	setFieldTouched,
}) => {
	return (
		<div>
			<h3 className="px-4 pt-2 text-lg font-semibold leading-5">
				Certificate in Scaffolding
			</h3>
			<div>
				<div className="flex items-center px-2">
					<Input
						title="COC Certificate Number"
						id="cert_num"
						type="text"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.cert_num}
						placeholder="COC Certificate Number"
					/>
					<DateSelect
						title="Issue Date"
						id="cert_issue_date"
						value={values.cert_issue_date}
						onChange={setFieldValue}
					/>
					<DateSelect
						title="Expiry Date"
						id="cert_expiry_date"
						value={values.cert_expiry_date}
						onChange={setFieldValue}
					/>
				</div>
				<div className="flex items-center px-2">
					<Dropdown
						label="Level"
						id="cert_level"
						value={values.cert_level}
						onChangeVal="label"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						options={levelOptions}
					/>
				</div>
				<div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-4">Photo Front</h4>
					<CreateFile
						field="cert_photo"
						setFieldValue={setFieldValue}
						value={values.cert_photo}
					/>
				</div>
			</div>
		</div>
	)
}
