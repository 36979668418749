import React from 'react'
import { DateSelect, Dropdown } from 'common'

type WorkingAtHeightsProps = {
	values: {
		working_at_heights_level: string
		working_at_heights_refresher_due: string
	}

	setFieldValue: (
		field: string,
		value: string | number | boolean | Date
	) => void
	setFieldTouched: (field: string, touched?: boolean) => void
}

const levelOptions = [
	{ value: 'Basic', label: 'Basic' },
	{ value: 'Advanced', label: 'Advanced' },
]

export const WorkingAtHeights: React.FC<WorkingAtHeightsProps> = ({
	values,
	setFieldValue,
	setFieldTouched,
}) => {
	return (
		<div>
			<h3 className="px-4 pt-2 text-lg font-semibold leading-5">
				Working at Heights
			</h3>
			<div>
				<div className="flex items-center px-2">
					<Dropdown
						label="Level"
						id="working_at_heights_level"
						value={values.working_at_heights_level}
						onChangeVal="label"
						onChange={setFieldValue}
						onBlur={setFieldTouched}
						options={levelOptions}
					/>
					<DateSelect
						title="Refresher Due"
						id="working_at_heights_refresher_due"
						value={values.working_at_heights_refresher_due}
						onChange={setFieldValue}
					/>
				</div>
			</div>
		</div>
	)
}
