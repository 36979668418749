import { PDFViewer } from '@react-pdf/renderer'
import { QuotePDFDocument } from './Output'
import { QuoteData, IQuoteLine, IQuoteAdditionalLines } from 'models'
import { SummaryQuotePDFDocument } from './SummaryOutput'

interface QuotePDFViewerProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
}

export const QuotePDFViewer = ({
	quote_data,
	quote_lines,
	quote_addons,
}: QuotePDFViewerProps) => {
	return (
		<>
			<PDFViewer width="100%" height="1000">
			{quote_data?.quote_output_type === 'Hire Inclusive' ? (
					<SummaryQuotePDFDocument
						quote_data={quote_data}
						quote_lines={quote_lines}
						quote_addons={quote_addons}
					/>
				) : (
					<QuotePDFDocument
						quote_data={quote_data}
						quote_lines={quote_lines}
						quote_addons={quote_addons}
					/>
				)}
			</PDFViewer>
		</>
	)
}
