import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

export const useQuoteFilesByQuoteId = (quote_id?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteFilesByQuoteId = async (quote_id: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.getQuoteFilesById,
				quote_id
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting quote files')
			throw new Error('Something went wrong getting quote files')
		}
	}

	if (quote_id === undefined) {
		return {
			data: undefined,
			isLoading: undefined,
			error: undefined,
			fetchQuoteFilesByQuoteId,
		}
	}

	const { data, isLoading, error } = useQuery(['quote_files', quote_id], () =>
		fetchQuoteFilesByQuoteId(quote_id)
	)

	return { data, isLoading, error, fetchQuoteFilesByQuoteId }
}
