import { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { TwoColumnDetails, Section } from 'common/Details'
import { Tabs, Spinner, ErrorComponent } from 'common'
import { StaffServices } from 'services'
import { AppRoutes } from 'config'
import { Notes } from 'components/Notes'

export const DetailsPage = () => {
	const [tabIndex, setTabIndex] = useState(0)

	const { id } = useParams()
	const location = useLocation()

	const { data, error, isLoading, enableCreateUpdate } =
		StaffServices.useStaffById(id)

	const items = [
		{ label: 'Notes & Files', id: 0 },
		{ label: 'Leave', id: 1 },
	]

	if (isLoading) {
		return (
			<div className="w-full h-48 flex justify-center items-center">
				<Spinner />
			</div>
		)
	}

	if (error) {
		return <ErrorComponent />
	}

	return (
		<div className="w-full mx-auto mt-8 mb-28">
			{data && (
				<TwoColumnDetails
					heading="Staff Details"
					editBtn="Edit Staff"
					isEditable={enableCreateUpdate}
					editLink={{
						to: AppRoutes.privateRoutes.StaffEdit.replace(':id', id || ''),
						state: { background: location, name: 'editStaff' },
					}}>
					<Section title="Staff Name" content={data.staff_name} />
					<Section title="Full Name" content={data.full_name} />
					<Section title="Date of Birth" content={data.dob} />
					<Section title="Start Date" content={data.start_date} />
					<Section title="Address" content={data.street} />
					<Section title="Contact #" content={data.mobile} />
					<Section title="Email" content={data.email} />
					<Section title="Status" content={data.status} />

					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Confined Space Training
					</h2>
					<div />
					<Section title="Issue Date" content={data.confined_date} />
					<Section title="Expiry Date" content={data.confined_expiry_date} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">File</h4>
						<img
							className="object-contain w-56"
							alt={data?.confined_file || ''}
							src={data?.confined_file || ''}
						/>
					</div>
					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Driver License
					</h2>
					<div />
					<Section title="License Number" content={data.driver_license} />
					<Section title="License Type" content={data.license_type} />
					<Section title="License Class" content={data.license_class2} />
					<Section title="Endorsement" content={data.endorsement} />
					<Section
						title="Completion Date"
						content={data.endorsement_complete_date}
					/>
					<Section title="Expiry Date" content={data.endorsement_expiry} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo Front</h4>
						<img
							className="object-contain w-56"
							alt={data?.photo_front || ''}
							src={data?.photo_front || ''}
						/>
					</div>
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo Back</h4>
						<img
							className="object-contain w-56"
							alt={data?.photo_back || ''}
							src={data?.photo_back || ''}
						/>
					</div>

					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Health and Safety Induction
					</h2>
					<div />
					<Section title="Induction Date" content={data.induction_date} />
					<Section title="Expiry Date" content={data.expiry_date} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						<img
							className="object-contain w-56"
							alt={data?.photo || ''}
							src={data?.photo || ''}
						/>
					</div>
					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Building Construction Site Safe Passport
					</h2>
					<div />
					<Section title="Passport Number" content={data.passport_num} />
					<Section title="Type" content={data.passport_type} />
					<Section title="Issue Date" content={data.passport_issue} />
					<Section title="Expiry Date" content={data.passport_expiry} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">
							Passport Photo
						</h4>
						<img
							className="object-contain w-56"
							alt={data?.passport_photo || ''}
							src={data?.passport_photo || ''}
						/>
					</div>
					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						First Aid Certificate
					</h2>
					<div />
					<Section title="Issue Date" content={data.first_aid_issue} />
					<Section title="Expiry Date" content={data.first_aid_expiry} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">
							First Aid Photo
						</h4>
						<img
							className="object-contain w-56"
							alt={data?.first_aid_photo || ''}
							src={data?.first_aid_photo || ''}
						/>
					</div>
					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Scaffolding Certificate of Competence
					</h2>
					<div />
					<Section title="Certificate Number" content={data.cert_num} />
					<Section title="Issue Date" content={data.cert_issue_date} />
					<Section title="Expiry Date" content={data.cert_expiry_date} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						<img
							className="object-contain w-56"
							alt={data?.cert_photo || ''}
							src={data?.cert_photo || ''}
						/>
					</div>
					{/* <h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Safe Operating Procedure
					</h2>
					<div />
					<Section title="SOP Training" content={data.sop_train} />
					<div /> */}
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Working at Heights
					</h2>
					<div />
					<Section title="Level" content={data.working_at_heights_level} />
					<Section
						title="Refresher Due"
						content={data.working_at_heights_refresher_due}
					/>
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Safety Nets FASET
					</h2>
					<div />
					<Section
						title="Completed"
						content={data.safety_nets_faset_completed}
					/>
					<Section title="Expires" content={data.safety_nets_faset_expires} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						<img
							className="object-contain w-56"
							alt={data?.safety_nets_faset_file || ''}
							src={data?.safety_nets_faset_file || ''}
						/>
					</div>
					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Fall Arrest
					</h2>
					<div />
					<Section
						title="US 23229 Refresher Due"
						content={data.fall_arrest_23229_refresher_due}
					/>
					<Section
						title="US 15757 Refresher Due"
						content={data.fall_arrest_15757_refresher_due}
					/>
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						<img
							className="object-contain w-56"
							alt={data?.fall_arrest_file || ''}
							src={data?.fall_arrest_file || ''}
						/>
					</div>
					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Emergency Response First Aid
					</h2>
					<div />
					<Section
						title="US 26551 Refresher Due"
						content={data.emergency_response_fa_26551_refresher_due}
					/>
					<Section
						title="US 26552 Refresher Due"
						content={data.emergency_response_fa_26552_refresher_due}
					/>
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						<img
							className="object-contain w-56"
							alt={data?.emergency_response_fa_file || ''}
							src={data?.emergency_response_fa_file || ''}
						/>
					</div>
					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Drug & Alcohol Test
					</h2>
					<div />
					<Section title="Date of Last Test" content={data.last_drug_test} />
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						<img
							className="object-contain w-56"
							alt={data?.last_drug_test_photo || ''}
							src={data?.last_drug_test_photo || ''}
						/>
					</div>
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Employment Contract
					</h2>
					<div />
					<Section
						title="File"
						content={
							data.employment_contract ? (
								<a
									href={data.employment_contract}
									target="_blank"
									rel="noreferrer">
									Link
								</a>
							) : (
								<></>
							)
						}
					/>
					<div />
					<h2 className="py-2 text-lg font-medium leading-5 tracking-wide">
						Other
					</h2>
					<div />
					<Section
						title="Inspect/Maintain Rope Access Equipment US 19359 Refresher Due"
						content={data.rope_access_equipment_19359_refresher_due}
					/>
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						<img
							className="object-contain w-56"
							alt={data?.rope_access_equipment_19359_file || ''}
							src={data?.rope_access_equipment_19359_file || ''}
						/>
					</div>
					<Section
						title="Elevated Walk Platform Refresher Due"
						content={data.elevated_walk_platform_refresher_due}
					/>
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						<img
							className="object-contain w-56"
							alt={data?.elevated_walk_platform_file || ''}
							src={data?.elevated_walk_platform_file || ''}
						/>
					</div>
					<Section
						title="Incline Lifts Training Date Completed"
						content={data.incline_lifts_training_date_completed}
					/>
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						<img
							className="object-contain w-56"
							alt={data?.incline_lifts_training_file || ''}
							src={data?.incline_lifts_training_file || ''}
						/>
					</div>
					<Section
						title="Forklift Operator Certificate Refresher Due"
						content={data.forklift_operator_cert_refresher_due}
					/>
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						<img
							className="object-contain w-56"
							alt={data?.forklift_operator_cert_file || ''}
							src={data?.forklift_operator_cert_file || ''}
						/>
					</div>
					<Section
						title="Mobile Crane US 3795 Refresher Due"
						content={data.mobile_crane_3795_refresher_due}
					/>
					<div>
						<h4 className="text-sm font-medium text-gray-500">Photo</h4>
						<img
							className="object-contain w-56"
							alt={data?.other_file || ''}
							src={data?.other_file || ''}
						/>
					</div>
					{/* <Section title="IRD #" content={data.ird_num} />
					<Section title="Kiwisaver" content={data.kiwisaver} /> */}
				</TwoColumnDetails>
			)}
			<div className="px-8">
				<Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={items} />
			</div>
			{tabIndex === 0 && <Notes type="staff" id={id || ''} />}

			{tabIndex === 1 && <div>Tab For Leave</div>}
		</div>
	)
}
