import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useUpdateProject = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const updateProject = async (Project: unknown, project_id: number) => {
		try {
			const response = await postRequest(
				AppRoutes.serverProjectRoutes.updateProject,
				Project,
				project_id
			)
			queryClient.refetchQueries(['projects'])
			showSuccess('Project updated successfully')
			return response
		} catch (error) {
			showError('Something went wrong updating Project')
		}
	}
	return { updateProject }
}
