import { useContext, useState } from 'react'
import moment from 'moment'
import { DateSelect, Button, ConfirmationDialog } from 'common'
import { InvoiceServices, JobsServices } from 'services'
import { NotificationsContext } from 'context/notifications/toastContext'

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	invoicesSelected: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setInvoicesSelected: any
	endOfMonth?: boolean
}

export const ApproveInvoices = ({
	invoicesSelected,
	setInvoicesSelected,
	endOfMonth,
}: IProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const [completed, setCompleted] = useState(false)
	const [date, setDate] = useState(moment().format('DD/MM/YYYY'))
	const { showSuccess, showError } = useContext(NotificationsContext)

	const { approveInvoices } = InvoiceServices.useApproveInvoices()
	const { update } = JobsServices.useUpdateJob()

	const approveInvoicesHandler = async () => {
		if (invoicesSelected) {
			const hasItemCode = invoicesSelected.every(
				(invoice: { item_code: any }) => invoice.item_code
			)
			if (hasItemCode) {
				setIsLoading(true)
				const invoicesGroupedByJobID = invoicesSelected.reduce(
					(acc: any, invoice: any) => {
						const key = invoice.job_id
						if (!acc[key]) {
							acc[key] = []
						}
						acc[key].push(invoice)
						return acc
					},
					{}
				)

				const invoicePromises: Promise<void>[] = []
				for (const jobID in invoicesGroupedByJobID) {
					invoicePromises.push(
						new Promise(async () => {
							const invoices = invoicesGroupedByJobID[jobID]
							const formattedInvoices = invoices.map(
								(invoice: { id: number; invoiceType: string }) => {
									return {
										id: invoice.id,
										invoiceType: invoice.invoiceType,
									}
								}
							)
	
							await approveInvoices(formattedInvoices, date, endOfMonth)

							if (!endOfMonth) {
								await update(Number(jobID), { job_status: 'Invoiced' })
							}
						})
					)
				}

				Promise.all(invoicePromises)
					.then(() => {
						showSuccess('All Invoices approved successfully!')
					})
					.catch((error) => {
						console.log({ error })
						showError('Something went wrong approving the Invoices')
					})

				setInvoicesSelected(null)
				setIsLoading(false)
				setCompleted(true)
			} else {
				showError('Item codes required before approving')
			}
		}
	}

	return (
		<ConfirmationDialog
			isDone={completed}
			icon="info"
			title="Approve Invoices"
			body="This action will approve the invoices selected. Select a Date for the process"
			triggerButton={
				<Button
					size="sm"
					variant="primary"
					onClick={() => {
						null
					}}>
					{endOfMonth ? 'End of Month' : 'Approve Invoices'}
				</Button>
			}
			confirmButton={
				<Button
					size="sm"
					isLoading={isLoading}
					variant="approve"
					onClick={approveInvoicesHandler}>
					Approve Invoices
				</Button>
			}>
			<div className="flex">
				<div className="mt-4 ml-12 mb-56">
					<DateSelect
						title="Approve Invoices"
						id="date"
						value={date}
						onChange={(e, val) => {
							setDate(val)
						}}
					/>
				</div>
			</div>
		</ConfirmationDialog>
	)
}
