import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { useQuery } from '@tanstack/react-query'

export const useValidateToken = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()
	const userState = useSelector((store: AppStore) => store.user)
	const email = userState.email

	const validateToken = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverInvoiceRoutes.validateToken,
				'',
				{ email }
			)
			return response
		} catch (error) {
			console.log({ error })
			showError('Something went wrong validating token')
			return null
		}
	}

	const { data, isLoading, error } = useQuery(
		['validateToken', email],
		validateToken,
		{
			enabled: !!email,
		}
	)

	return { data, isLoading, error, validateToken }
}
