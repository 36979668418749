import { DateSelect, Dropdown, Input, SideModal, Spinner } from 'common'
import { useFormik } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { ClientServices, ContactServices, StaffServices } from 'services'
import { OptionsForDropdown } from 'utilities'
import * as Yup from 'yup'

const yesNoCashOptions = [
	{
		value: 'Yes',
		label: 'Yes',
	},
	{
		value: 'No',
		label: 'No',
	},
	{
		value: 'Cash Customer',
		label: 'Cash Customer',
	},
]

interface IProps {
	client_id?: string | number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

const statusOptions = [
	{ label: 'Active', value: 'Active' },
	{ label: 'Inactive', value: 'Inactive' },
]

const branchOptions = [
	{
		value: 'Hawkes Bay',
		label: 'Hawkes Bay',
	},
	{
		value: 'Gisborne',
		label: 'Gisborne',
	},
	{
		value: 'Wairarapa',
		label: 'Wairarapa',
	},
	{
		value: 'Taupo',
		label: 'Taupo',
	},
	{
		value: 'Dunedin',
		label: 'Dunedin',
	},
	{
		value: 'Cromwell',
		label: 'Cromwell',
	},
]

const hourlyRateOptions = [
	{
		value: 75,
		label: '$75',
	},
	{
		value: 85,
		label: '$85',
	},
	{
		value: 93,
		label: '$93',
	},
	{
		value: 120,
		label: '$120',
	},
	{
		value: 150,
		label: '$150',
	},
]

const sqmRateOptions = [
	{
		value: 1.80,
		label: '$1.80',
	},
	{
		value: 2.2,
		label: '$2.20',
	},
	{
		value: 2.50,
		label: '$2.50',
	},
]

interface createClientRow {
	client_name: string
	email: string
	phone: string
	status: string
	branch: string
	sqm_rate: number
	hourly_rate: number
	main_contact: string
	contactName: string
	contactEmail: string
	contactphone: string
	credit_check: string
	credit_check_who: string
	credit_check_when: string
	protrade_sales_person: number | null
}

export const CreateClientsForm = ({
	client_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()
	const { data, isLoading } = ClientServices.useClientById(client_id)
	const { createClient } = ClientServices.useCreateClients()
	const { updateClient } = ClientServices.useUpdateClients()
	const { createContact } = ContactServices.useCreateContact()
	const [activeStaffData, setActiveStaffData] = useState<any>([])

	useEffect(() => {
		if (!staffLoading) {
			const activeStaff = staffData
				?.filter((staff) => staff.status === 'Active')
				?.sort((a, b) => a.staff_name.localeCompare(b.staff_name))
			setActiveStaffData(activeStaff)
		}
	}, [staffData, staffLoading])

	const initialValues: createClientRow = {
		client_name: data?.client_name || '',
		email: data?.email || '',
		phone: data?.phone || '',
		status: data?.status || 'Active',
		branch: data?.branch || '',
		sqm_rate: data?.sqm_rate || 1.80,
		hourly_rate: data?.hourlyRate || 93,
		main_contact: data?.main_contact || null,
		contactName: data?.contactName || '',
		contactEmail: data?.contactEmail || '',
		contactphone: data?.contactphone || '',
		credit_check: data?.credit_check || 'Cash Customer',
		credit_check_who: data?.credit_check_who || '',
		credit_check_when:
			data?.credit_check_when !== 'Invalid date' &&
			data?.credit_check_when !== ' ' &&
			data?.credit_check_when
				? moment(data?.credit_check_when, 'DD/MM/YYYY').format('DD/MM/YYYY')
				: '',
		protrade_sales_person: data?.protrade_sales_person || null,
}

	const validationSchema = Yup.object({
		client_name: Yup.string().required('Company is required'),
		// email: Yup.string()
		// 	.email('Invalid email address')
		// 	.required('Email is required'),
		phone: Yup.string(),
		branch: Yup.string().required('Branch is required'),
		contactName: Yup.string().required('Main Contact Name is required'),
		contactEmail: Yup.string()
			.email('Invalid email address')
			.required('Main Contact Email is required'),
		contactphone: Yup.string(),
		protrade_sales_person: Yup.string().required('Protrade Sales Person is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			const {
				client_name,
				email,
				phone,
				status,
				branch,
				sqm_rate,
				hourly_rate,
				credit_check,
				credit_check_when,
				credit_check_who,
				protrade_sales_person,
			} = values

			const ClientData = {
				client_name,
				email,
				phone,
				status,
				branch,
				sqm_rate,
				hourly_rate,
				credit_check,
				credit_check_who,
				credit_check_when,
			}

			const clientCreatedResult = await createClient(ClientData)
			if(!clientCreatedResult.id)
			{
				console.error('Error creating client')
				return
			}
			const { contactName, contactEmail, contactphone } = values

			const ContactData = {
				name: contactName,
				email: contactEmail,
				phone: contactphone,
				status: 'Active',
				client_id: Number(clientCreatedResult.id),
			}
			const resultContact = await createContact(ContactData)
			console.log(resultContact)
			const newClientUpdateData = {
				...clientCreatedResult.client_data,
				main_contact: resultContact.data.id,
				protrade_sales_person,
			}
			console.log(newClientUpdateData)
			const updatedContact = await updateClient(newClientUpdateData, clientCreatedResult.id)
			console.log(updatedContact)

			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (client_id && (isLoading || staffLoading)) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center">
					<Input
						title="Company"
						id="client_name"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.client_name}
						placeholder=""
						error={formik.errors.client_name}
					/>
				</div>
				<div className="flex items-center">
					<Input
						title="Contact #"
						id="phone"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.phone}
						placeholder=""
						error={formik.errors.phone}
					/>
					<Input
						title="Email"
						id="email"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.email}
						placeholder=""
						error={formik.errors.email}
					/>
				</div>
				<div className="flex items-center">
					<Dropdown
						label="Status"
						id="status"
						options={statusOptions}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						value={formik.values.status}
						error={formik.errors.status}
					/>
					<Dropdown
						label="Branch"
						id="branch"
						options={branchOptions}
						value={formik.values.branch}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						error={formik.errors.branch}
					/>
				</div>
				<div className="flex items-center">
					<Dropdown
						label="Hourly Rate"
						id="hourly_rate"
						options={hourlyRateOptions}
						value={formik.values.hourly_rate}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						error={formik.errors.hourly_rate}
					/>
					<Dropdown
						label="SQM Rate"
						id="sqm_rate"
						options={sqmRateOptions}
						value={formik.values.sqm_rate}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						error={formik.errors.sqm_rate}
					/>
				</div>
				<>
					<div className="flex items-center">
						<Input
							title="Contact Name"
							id="contactName"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.contactName}
							placeholder=""
							error={formik.errors.contactName}
						/>
					</div>
					<div className="flex items-center">
						<Input
							title="Contact #"
							id="contactphone"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.contactphone}
							placeholder=""
							error={formik.errors.contactphone}
						/>
						<Input
							title="Email"
							id="contactEmail"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.contactEmail}
							placeholder=""
							error={formik.errors.contactEmail}
						/>
					</div>
					<div className="flex items-center">
						<Dropdown
							id="protrade_sales_person"
							label="Protrade Sales Person"
							options={OptionsForDropdown(activeStaffData, 'id', 'staff_name')}
							value={formik.values.protrade_sales_person}
							onChange={formik.setFieldValue}
							error={formik.errors.protrade_sales_person}
						/>
					</div>
					<div>
						<Dropdown
							label="Credit Check Completed?"
							id="credit_check"
							value={formik.values.credit_check}
							error={formik.errors.credit_check}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={yesNoCashOptions}
						/>
					</div>
					{formik.values.credit_check === 'Yes' ? (
						<div className="flex items-center">
							<Input
								title="By Who?"
								id="credit_check_who"
								placeholder=""
								type="text"
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
								value={formik.values.credit_check_who}
								error={formik.errors.credit_check_who}
							/>
							<DateSelect
								title="When?"
								id="credit_check_when"
								value={formik.values.credit_check_when}
								error={formik.errors.credit_check_when}
								onChange={formik.setFieldValue}
							/>
						</div>
					) : (
						<></>
					)}
				</>
				<div className="flex items-center">
					<Input
						title="Creation Date"
						id="creationDate"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={moment(new Date()).format('DD/MM/YYYY')}
						placeholder=''
						disabled
					/>
				</div>
			</SideModal>
		</>
	)
}
