import { PlusIcon } from '@heroicons/react/24/solid'
import {
	IQuoteAdditionalLines,
	IRates,
	IQuoteAdditionalLinesHandlers,
	columnsQuoteAdditionalLines,
} from 'models/simple_quotes.model'
import { SimpleQuoteAdditionalLine } from './AdditionalLine'
import { IQuoteZones } from 'models'
import { useEffect } from 'react'

interface QuoteAdditionalLinesProps {
	zones: IQuoteZones[]
	rates: IRates[]
	additionalLines: IQuoteAdditionalLines[]
	additionalLinesHandlers: IQuoteAdditionalLinesHandlers
	main_origin: string
	job_type: string
	quoteID?: number
}

export const SimpleQuoteAdditionalLines = ({
	zones,
	rates,
	additionalLines,
	additionalLinesHandlers,
	main_origin,
	job_type,
	quoteID,
}: QuoteAdditionalLinesProps) => {
	const createDefaultAdditionalLine = () => {
		const {type, charge} = calculateTypeAndCharge(main_origin)
		let total_cost = 0
		if (type === 'Delivery/Collection A (10kms from branch)') {
			total_cost = 190
		} else if (type === 'Delivery/Collection B (20kms from branch)') {
			total_cost = 270
		} else {
			total_cost = Number(main_origin) * charge * 2
		}
		const deliveryLine: IQuoteAdditionalLines = {
			id: null,
			type: type,
			description: type.split(' (')[0],
			duration_quantity: Number(main_origin) * 2,
			fixed_charge: Number(charge),
			fixed_charge_is_dirty: false,
			zone_id: zones.length + 1,
			zone_label: 'Additional',
			total_cost: parseFloat(total_cost.toFixed(2)),
			total_cost_is_dirty: false,
		}
		additionalLinesHandlers.addNewQuoteAdditionalLine(deliveryLine)
		const weeklyRate = rates.find(rate => rate.service === 'Weekly inspection (Cost per week)')?.fee! || 0
		const weeklyRateLine: IQuoteAdditionalLines = {
			id: null,
			type: 'Weekly inspection (Cost per week)',
			description: 'Weekly inspection',
			duration_quantity: job_type !== 'Roof Edge' ? 1 : 0,
			fixed_charge: Number(weeklyRate),
			fixed_charge_is_dirty: false,
			zone_id: zones.length + 1,
			zone_label: 'Additional',
			total_cost: job_type !== 'Roof Edge' ? parseFloat((1 * weeklyRate).toFixed(2)) : 0 ,
			total_cost_is_dirty: false,
		}
		additionalLinesHandlers.addNewQuoteAdditionalLine(weeklyRateLine)
	}
	const updateDefaultAdditionalLine = () => {
		additionalLinesHandlers.updateQuoteAdditionalLine(0, 'duration_quantity', Number(main_origin), false)
	}

	const calculateTypeAndCharge = (origin: string) => {
		const quantity = Number(origin) * 2
		const type =
			quantity <= 20
				? 'Delivery/Collection A (10kms from branch)'
				: quantity <= 45
				? 'Delivery/Collection B (20kms from branch)'
				: 'Delivery/Collection C (Over 45kms from branch)'
		const charge = quantity <= 20 ? 190 : quantity <= 45 ? 270 : 6
		return { type, charge }
	}

	useEffect(() => {
		if (!quoteID && main_origin) {
			const transportTypes = [
				'Delivery/Collection A (10kms from branch)',
				'Delivery/Collection B (20kms from branch)',
				'Delivery/Collection C (Over 45kms from branch)',
			]
			const transportLine = additionalLines.find((line) =>
				transportTypes.includes(line.type)
			)

			if (!transportLine) {
				createDefaultAdditionalLine()
			} else {
				updateDefaultAdditionalLine()
			}
		}
	}, [main_origin, quoteID])

	return (
		<>
			<div className="w-4/5 mb-8 px-2">
				<h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
					Additional Items
				</h2>
				<table className="w-full divide-y divide-gray-200">
					<thead className="bg-gray-100">
						<tr>
							{columnsQuoteAdditionalLines.map((column, index) => (
								<th
									key={index}
									className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
									{column}
								</th>
							))}
							{/* Additional column to delete button */}
							<th></th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{additionalLines.map((line, index) => (
							<SimpleQuoteAdditionalLine
								zones={zones}
								rates={rates}
								key={index}
								line={line}
								additionalLineHandlers={additionalLinesHandlers}
								index={index}
								job_type={job_type}
							/>
						))}
					</tbody>
				</table>
				<div className="mt-6 mb-16">
					<button
						type="button"
						className="flex items-center"
						onClick={() => additionalLinesHandlers.addNewQuoteAdditionalLine()}>
						<PlusIcon
							className="flex-shrink-0 h-5 w-5 text-green-500"
							aria-hidden="true"
						/>
						<span className="ml-2 text-sm">Add Item</span>
					</button>
				</div>
			</div>
		</>
	)
}
