import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

export const useNotes = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAllNotes = async () => {
		try {
			const response = await getRequest(AppRoutes.serverNotesAndFilesRoutes.getAllNotesAndFiles)
			return response.data
		} catch (error) {
			showError('Something went wrong getting the Notes')
			throw new Error('Something went wrong getting the Notes')
		}
	}
	const { data, isLoading, error } = useQuery(['notes'], fetchAllNotes)

	return { data, isLoading, error }
}
