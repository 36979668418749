import { IStaffRow } from 'models/staff.model'
import { Input } from 'common'
interface EmergencyContactDetailsProps {
	values: IStaffRow
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
}

export function EmergencyContactDetails({
	values,
	handleChange,
	handleBlur,
}: EmergencyContactDetailsProps) {
	return (
		<div>
			<h3 className="px-4 pt-2 text-lg font-semibold leading-5">
				Emegency Contact Details/Next of Kin?
			</h3>
			<div>
				<div className="flex items-center px-2">
					<Input
						title="Name"
						id="emergency_contact_name"
						type="text"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.emergency_contact_name}
						placeholder=""
					/>
					<Input
						title="Relationship To"
						id="emergency_contact_relationship_to"
						type="text"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.emergency_contact_relationship_to}
						placeholder=""
					/>
				</div>
				<div className="flex items-center px-2">
					<Input
						title="Phone Number"
						id="emergency_contact"
						type="text"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.emergency_contact_number}
						placeholder=""
					/>
					<Input
						title="Email Address"
						id="emergency_contact_email"
						type="text"
						handleChange={handleChange}
						handleBlur={handleBlur}
						value={values.emergency_contact_email}
						placeholder=""
					/>
				</div>
			</div>
		</div>
	)
}
