import { Dropdown, Input, SideModal, Spinner, DateSelect } from 'common'
import { useFormik } from 'formik'
import { InvoiceServices, JobsServices } from 'services'
import moment from 'moment'
import * as Yup from 'yup'
import { item_codes_options, yes_no_options } from 'models'
import { useEffect, useState } from 'react'
import { OptionsForDropdownFilter } from 'utilities'

const sqmRateOptions = [
	{
		value: 1.8,
		label: '$1.80',
	},
	{
		value: 2.2,
		label: '$2.20',
	},
	{
		value: 2.5,
		label: '$2.50',
	},
]

const onHireOptions = [
	{
		value: 'Yes',
		label: 'Yes',
	},
	{
		value: 'No',
		label: 'No',
	},
	{
		value: 'On Hire Inc',
		label: 'On Hire Inc',
	},
]

interface IProps {
	job_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
	total?: number
}

interface IinitialValues {
	on_hire: string
	zone_label: string
	type: string
	description: string
	erect_percent: number
	date_on_hire: string | null
	completed_date: string | null
	weekly_hire_rate: number
	sqm_rate: number
	item_code: string
	total: number
	hire_period_included: number
	task_id: number | null
  days_on_hire: number
  completed: number
  handover_url: string
  myobReference: string
	installed_sqm: number
}

export const WeeklyHireInvoiceCreateForm = ({
	job_id,
	heading,
	setOpen,
	formType,
	open,
	total,
}: IProps) => {
	const { data: TaskData, isLoading: taskLoading } =
		JobsServices.useJobTask(job_id)

	const { createWeeklyHire } = InvoiceServices.useCreateWeeklyHireInvoice()

	const initialValues: IinitialValues = {
		on_hire: '',
		zone_label: '',
		type: '',
		description: 'Weekly Hire',
		erect_percent: 0,
		date_on_hire: '',
		days_on_hire: 0,
		completed_date: '',
		weekly_hire_rate: 0,
		sqm_rate: 1.8,
		item_code: 'H',
		total: 0,
		hire_period_included: 0,
		task_id: null,
    completed: 0,
    handover_url: '',
    myobReference: '',
		installed_sqm: 0,
	}

	const validationSchema = Yup.object({
		erect_percent: Yup.number().max(
			100,
			'Percent Erect must be smaller than or equal to 100'
		),
		// item_code: Yup.string().required('Item Code is required'),
    task_id: Yup.number().required('Task is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create' && job_id) {
				createWeeklyHire(job_id, values)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		if (formik.values.task_id) {
			const task = TaskData.find(
				(task: { id: number }) =>
					Number(task.id) === Number(formik.values.task_id)
			)
			formik.setFieldValue('zone', task.zone)
			formik.setFieldValue('type', task.type)
			formik.setFieldValue('zone_label', task.zone_label)
			// formik.setFieldValue('description', task.description)
		}
	}, [formik.values.task_id, TaskData, formik.setFieldValue])

	if (taskLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Dropdown
							id="task_id"
							label="Task"
							options={OptionsForDropdownFilter(
								TaskData,
								'id',
								['zone_label', 'type', 'description'],
								'job_id',
								job_id!
							)}
							onChange={formik.setFieldValue}
							value={formik.values.task_id}
							error={formik.errors.task_id}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="Section"
							id="zone_label"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Section"
							value={formik.values.zone_label}
							error={formik.errors.zone_label}
							disabled
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Type"
							id="type"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Type"
							value={formik.values.type}
							error={formik.errors.type}
							disabled
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-full">
						<Input
							id="description"
							title="Description"
							placeholder="Description"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.description}
							error={formik.errors.description}
						/>
					</div>
				</div>
				<div className="flex items-stretch px-2">
					<div className="w-1/2">
						<Dropdown
							label="SQM Rate"
							id="sqm_rate"
							options={sqmRateOptions}
							value={formik.values.sqm_rate}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							error={formik.errors.sqm_rate}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="weekly_hire_rate"
							title="Weekly Hire Rate"
							placeholder=""
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.weekly_hire_rate}
							error={formik.errors.weekly_hire_rate}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Dropdown
							id="item_code"
							label="Item Code"
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							options={item_codes_options}
							value={formik.values.item_code}
							error={formik.errors.item_code}
              disabled
						/>
					</div>
					<div className="w-1/2">
						<Dropdown
							id="on_hire"
							label="On Hire"
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							options={onHireOptions}
							value={formik.values.on_hire}
							error={formik.errors.on_hire}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<DateSelect
							id="date_on_hire"
							title="Date On Hire"
							onChange={formik.setFieldValue}
							value={formik.values.date_on_hire}
							error={formik.errors.date_on_hire}
						/>
					</div>
					<div className="w-1/2">
						<DateSelect
							id="completed_date"
							title="Completed Date"
							onChange={formik.setFieldValue}
							value={formik.values.completed_date}
							error={formik.errors.completed_date}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="erect_percent"
							title="Percent Erect"
							placeholder="Percent Erect"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.erect_percent}
							error={formik.errors.erect_percent}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Total Invoiceable"
							id="total"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Total"
							value={formik.values.total}
							error={formik.errors.total}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="hire_period_included"
							title="Hire Period Included"
							placeholder="Hire Period Included"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.hire_period_included}
							error={formik.errors.hire_period_included}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="installed_sqm"
							title="Installed SQM"
							placeholder="Installed SQM"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.installed_sqm}
							error={formik.errors.installed_sqm}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
