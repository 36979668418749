import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline'
import { Dropdown, Input, SideModal, Spinner, TextArea } from 'common'
import { useFormik } from 'formik'
import { item_codes_options } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { InvoiceServices, JobsServices, QuoteServices } from 'services'
import { OptionsForDropdown, OptionsForDropdownFilter } from 'utilities'
import * as Yup from 'yup'

const hourlyRateOptions = [
	{
		value: 75,
		label: '$75',
	},
	{
		value: 85,
		label: '$85',
	},
	{
		value: 93,
		label: '$93',
	},
	{
		value: 120,
		label: '$120',
	},
	{
		value: 150,
		label: '$150',
	},
]

interface IProps {
	job_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
	isDayworks: boolean
}

interface IinitialValues {
	zone: string
	zone_label: string
	type: string
	description: string
	complete_percent: number
	total: number
	PO_Number: string
	hourly_rate: number
	total_hours: number
	task_id: number | null
	item_code: string
}

export const EdInvoiceCreateForm = ({
	job_id,
	heading,
	setOpen,
	formType,
	open,
	isDayworks,
}: IProps) => {
	const { data: rates, isLoading } = QuoteServices.useServiceRates()
	const { data: TaskData, isLoading: taskIsLoading } = JobsServices.useJobTask(job_id)
	const { createEdInvoice } = InvoiceServices.useCreateEDInvoice()

	const [totalLocked, setTotalLocked] = useState(false)

	const initialValues: IinitialValues = {
		zone: '',
		zone_label: '',
		type: isDayworks ? 'Day Works' : '',
		description: '',
		complete_percent: 0,
		total: 0,
		PO_Number: '',
		hourly_rate: 93,
		total_hours: 0,
		task_id: null,
		item_code: ''
	}

	const validationSchema = Yup.object({
		task_id: Yup.number().min(1).required('Task is required'),
		item_code: Yup.string().required('Item Code is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				createEdInvoice(Number(job_id), {
					zone: values.zone,
					zone_label: values.zone_label,
					type: values.type,
					description: values.description,
					complete_percent: Number(values.complete_percent),
					total: Number(values.total),
					PO_Number: values.PO_Number,
					task_id: Number(values.task_id),
					item_code: values.item_code,
				})
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		if (formik.values.type) {
			const type = rates?.find(
				(rate: any) => rate.service === formik.values.type
			)
			if (type) {
				formik.setFieldValue('hourly_rate', type.fee)
				formik.setFieldValue('item_code', type.item_code)
				if (type.service === 'Inspection') {
					formik.setFieldValue('zone', 99)
					formik.setFieldValue('zone_label', 'Additional Items')
				}
			}
		}
	}, [formik.values.type, rates])

	useEffect(() => {
		if (isDayworks) {
			const handleTotalCalculation = () => {
				if (!totalLocked) {
					const total = formik.values.total_hours * formik.values.hourly_rate
					formik.setFieldValue('total', Number(total).toFixed(2))
				}
			}
	
			handleTotalCalculation()
		}
	}, [formik.values.total_hours, formik.values.hourly_rate, totalLocked])

	useEffect(() => {
		if (formik.values.task_id) {
			const task = TaskData.find(
				(task: { id: string }) =>
					task.id === String(formik.values.task_id)
			)
			formik.setFieldValue('zone', task.zone)
			formik.setFieldValue('zone_label', task.zone_label)
			formik.setFieldValue('description', task.description)
		}
	}, [formik.values.task_id])

	const handleTotalBlur = (event: any) => {
		let value = event.target.value

		if (!isNaN(value) && value !== '') {
			value = parseFloat(value).toFixed(2)
			formik.setFieldValue('total', value)
		}
	}

	if (isLoading || taskIsLoading) {
		return <Spinner />
	}

	const formattedRates = rates.map((rate: any) => {
		if (rate.item_code === 'FSD') {
			return {
				...rate,
				formattedService: `${rate.service} - D`
			}
		} else if (rate.item_code === 'FSB') {
			return {
				...rate,
				formattedService: `${rate.service} - B`
			}
		}

		return {
			...rate,
			formattedService: rate.service
		}
	})

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Dropdown
							id="task_id"
							label="Task"
							options={OptionsForDropdownFilter(
								TaskData,
								'id',
								['zone_label', 'type', 'description'],
								'job_id',
								job_id!
							)}
							onChange={formik.setFieldValue}
							value={formik.values.task_id}
							error={formik.errors.task_id}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="Section"
							id="zone_label"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Section"
							value={formik.values.zone_label}
							error={formik.errors.zone_label}
							disabled
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="PO Number"
							id="PO_Number"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="e.g. PO-1234"
							value={formik.values.PO_Number}
							error={formik.errors.PO_Number}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						{isDayworks ? (
							<Input
								title="Type"
								id="type"
								type="text"
								handleBlur={formik.handleBlur}
								handleChange={formik.handleChange}
								placeholder="Type"
								value={formik.values.type}
								error={formik.errors.type}
								disabled
							/>
						) : (
							<Dropdown
								id="type"
								label="Type"
								onChange={formik.setFieldValue}
								onBlur={formik.handleBlur}
								value={formik.values.type}
								error={formik.errors.type}
								options={OptionsForDropdown(formattedRates, 'service', 'formattedService')}
							/>
						)}
					</div>
					<div className="w-1/2">
						<Dropdown
							id="item_code"
							label="Item Code"
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							options={item_codes_options}
							value={formik.values.item_code}
							error={formik.errors.item_code}
							disabled={!isDayworks}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Complete Percent"
							id="complete_percent"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Complete Percent"
							value={formik.values.complete_percent}
							error={formik.errors.complete_percent}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2 h-40">
					<div className="w-full ">
						<TextArea
							title="Description"
							id="description"
							type="text"
							className=" resize-none"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Description"
							rows={5}
							value={formik.values.description}
							error={formik.errors.description}
						/>
					</div>
				</div>
				{isDayworks ? (
					<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							label="Hourly Rate"
							id="hourly_rate"
							options={hourlyRateOptions}
							value={formik.values.hourly_rate}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							error={formik.errors.hourly_rate}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Total Hours"
							id="total_hours"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Total Hours"
							value={formik.values.total_hours}
							error={formik.errors.total_hours}
						/>
					</div>
				</div>
				) : null}
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2 flex items-end">
						<Input
							title="Total"
							id="total"
							type="number"
							handleBlur={handleTotalBlur}
							handleChange={formik.handleChange}
							placeholder="Total"
							value={formik.values.total}
							error={formik.errors.total}
							disabled={!totalLocked}
						/>
						<div>
							<button
								type="button"
								onClick={() => setTotalLocked(!totalLocked)}
								className="ml-2 p-2 mb-2 border rounded-full hover:bg-gray-200 flex items-center justify-center">
								{totalLocked ? (
									<LockClosedIcon className="h-5 w-5 text-gray-700" />
								) : (
									<LockOpenIcon className="h-5 w-5 text-gray-700" />
								)}
							</button>
						</div>
					</div>
				</div>
			</SideModal>
		</>
	)
}
