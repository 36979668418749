import React from 'react'
import { DateSelect, CreateFile } from 'common'
import { IStaffRow } from 'models/staff.model'

type EmergencyResponseFirstAidProps = {
	values: IStaffRow
	setFieldValue: (
		field: string,
		value: string | number | boolean | Date
	) => void
}

export const EmergencyResponseFirstAid: React.FC<EmergencyResponseFirstAidProps> = ({
	values,
	setFieldValue,
}) => {
  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
		const isChecked = e.target.checked;
		setFieldValue(field, isChecked);
	};

	return (
		<div>
			<h3 className="px-4 py-2 text-lg font-semibold leading-5 ">
				Emergency Response First Aid
			</h3>
			<div>
				<div className="flex items-center px-2">
          <label
            htmlFor="emergency_response_fa_26551"
            className="ml-2 w-1/2 text-sm font-medium text-gray-700">
            Emergency Response FA US 26551
          </label>
          <input
            id="emergency_response_fa_26551"
            name="emergency_response_fa_26551"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={values.emergency_response_fa_26551}
            onChange={(e) => handleCheckbox(e, 'emergency_response_fa_26551')}
          />
          <div className="ml-4 w-1/3">
            <DateSelect
              title="Refresher Due"
              id="emergency_response_fa_26551_refresher_due"
              value={values.emergency_response_fa_26551_refresher_due}
              onChange={setFieldValue}
            />
          </div>
				</div>
				<div className="flex items-center px-2">
          <label
            htmlFor="emergency_response_fa_26552"
            className="ml-2 w-1/2 text-sm font-medium text-gray-700">
            Emergency Response FA US 26552
          </label>
          <input
            id="emergency_response_fa_26552"
            name="emergency_response_fa_26552"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={values.emergency_response_fa_26552}
            onChange={(e) => handleCheckbox(e, 'emergency_response_fa_26552')}
          />
          <div className="ml-4 w-1/3">
            <DateSelect
              title="Refresher Due"
              id="emergency_response_fa_26552_refresher_due"
              value={values.emergency_response_fa_26552_refresher_due}
              onChange={setFieldValue}
            />
          </div>
				</div>
				<div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="emergency_response_fa_file"
						setFieldValue={setFieldValue}
						value={values.emergency_response_fa_file}
					/>
				</div>
			</div>
		</div>
	)
}
