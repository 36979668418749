import { co } from '@fullcalendar/core/internal-common'
import { Badge, Spinner, Table } from 'common'
import moment from 'moment'
import {
	JobsServices,
	StaffServices,
	VisitServices,
	VisitTimesheetsServices,
} from 'services'

interface VisitsTimesheetsTableProps {
	job_id?: number
}
export const VisitsTimesheetsTable = ({
	job_id,
}: VisitsTimesheetsTableProps) => {
	const { data: timesheetData, isLoading: timesheetLoading } =
		VisitTimesheetsServices.useVisitTimesheetsByJobId(job_id)
	const { data: taskData, isLoading: taskLoading } = JobsServices.useTask()

	if (timesheetLoading || taskLoading) {
		return <Spinner />
	}

	const rowData = timesheetData?.map((timesheet: any) => {
		console.log('timesheet', timesheet)
		const visit: any = timesheet.visit
		const job: any = timesheet?.job
		const staff: any = timesheet?.staff
		const start_time = moment(timesheet.time_in, 'HH:mm')
		const end_time = moment(timesheet.time_off, 'HH:mm')
		const difference: number = end_time.diff(start_time, 'hours', true) || 0
		const task_list = visit.task_ids
			?.map((task_id: any) => {
				if (!taskData) return null
				const task = taskData.find(
					(item: any) => Number(item.id) === Number(task_id)
				)
				return task ? `${task.zone_label} - ${task.type}` : ''
			})
			.filter((task: any) => task)
			.join(', ')
		const type = visit.type

		return {
			record_id: timesheet?.id,
			staff_id: staff?.id,
			id: String(job?.id) + '_' + String(visit?.id) + '_' + String(staff?.id),
			visit_id: visit?.id,
			name: staff?.staff_name,
			start_time: timesheet?.time_in,
			end_time: timesheet?.time_off,
			date: timesheet?.date,
			job: job?.job_num,
			description: job?.descriptionOfQuote,
			client_name: job?.clientData?.client_name,
			site: job?.site,
			hours: difference,
			confined_spaces: job?.job_type === 'Confined Spaces' ? true : false,
			timesheet_status: timesheet?.status,
			task_list,
			type,
		}
	})

	const calculateTimesheetTotal = (row: { visit_id: number }) => {
		let total = 0

		if (rowData) {
			for (const timesheet of rowData) {
				if (
					Number(timesheet.visit_id) === Number(row.visit_id) &&
					timesheet.status !== 'Pending' &&
					Number(timesheet.hours) >= 0
				) {
					const hours = Number(timesheet.hours)
					total += hours
				}
			}
		}

		return total
	}

	const footerTemplate = (data: any) =>
		data.visit_id ? (
			<>
				<td
					colSpan={6}
					style={{ textAlign: 'right' }}
					className="bg-gray-100 font-normal">
					Total Hours
				</td>
				<td colSpan={1} className="bg-gray-100 font-semibold">
					{calculateTimesheetTotal(data)}
				</td>
			</>
		) : (
			<></>
		)

	const columns = [
		{
			field: 'date',
			header: 'Date',
			body: (row: { date: Date }) => {
				return row.date
					? moment(row.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
					: ''
			},
		},
		{
			field: 'visit_id',
			header: 'Visit #',
			body: (row: { visit_id: number }) => {
				return row.visit_id ? (
					<Badge type={'Green'} text={String(row.visit_id)} />
				) : (
					<Badge type={'Orange'} text={'Manual Entry'} />
				)
			},
		},
		{
			field: 'type',
			header: 'Type',
		},
		{
			field: 'task_list',
			header: 'Tasks',
			body: (row: any) => {
				const tasks = row.task_list.split(', ').map((task: any) => (
					<span>
						{task}
						<br />
					</span>
				))
				return tasks
			},
		},
		{
			field: 'name',
			header: 'Name',
		},
		{ field: 'start_time', header: 'Time In' },
		{ field: 'end_time', header: 'Time Off' },
		{ field: 'hours', header: 'Hours' },
		{ field: 'timesheet_status', header: 'Timesheet Status' },
	]

	return (
		<>
			<Table
				columns={columns}
				data={rowData}
				groupBy="visit_id"
				isLoading={false}
				title="Visits Timesheets"
				disableButtons
				rowFooterTemplate={footerTemplate}
				sortField="visit_id"
				sortOrder={-1}
			/>
		</>
	)
}
