import { DateSelect, Dropdown, Input, SideModal, Spinner } from 'common'
import { useFormik } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { ClientServices, ContactServices, ProjectServices, StaffServices } from 'services'
import { OptionsForDropdown } from 'utilities'
import * as Yup from 'yup'

interface IProps {
	project_id?: string | number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

const statusOptions = [
	{ label: 'Active', value: 'Active' },
	{ label: 'Inactive', value: 'Inactive' },
]

const branchOptions = [
	{
		value: 'Hawkes Bay',
		label: 'Hawkes Bay',
	},
	{
		value: 'Gisborne',
		label: 'Gisborne',
	},
	{
		value: 'Wairarapa',
		label: 'Wairarapa',
	},
	{
		value: 'Taupo',
		label: 'Taupo',
	},
	{
		value: 'Dunedin',
		label: 'Dunedin',
	},
	{
		value: 'Cromwell',
		label: 'Cromwell',
	},
]

interface createProjectRow {
	project_name: string
	email: string
	phone: string
	status: string
	branch: string
	main_contact: number
	protrade_sales_person: number
}

export const EditProjectForm = ({
	project_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data, isLoading } = ProjectServices.useProjectById(project_id)
	const { updateProject } = ProjectServices.useUpdateProject()
	const { data: contacts, isLoading: contactsLoading } =
		ContactServices.useContactsByProjectId(Number(project_id))
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()
	const [activeStaffData, setActiveStaffData] = useState<any>([])

	useEffect(() => {
		if (!staffLoading) {
			const activeStaff = staffData
				?.filter((staff) => staff.status === 'Active')
				?.sort((a, b) => a.staff_name.localeCompare(b.staff_name))
			setActiveStaffData(activeStaff)
		}
	}, [staffData, staffLoading])

	const initialValues: createProjectRow = {
		project_name: data?.project_name || '',
		email: data?.email || '',
		phone: data?.phone || '',
		status: data?.status || 'Active',
		branch: data?.branch || '',
		main_contact: data?.main_contact || 0,
		protrade_sales_person: data?.protrade_sales_person || null,
	}

	const validationSchema = Yup.object({
		project_name: Yup.string().required('Project name is required'),
		// email: Yup.string()
		// 	.email('Invalid email address')
		// 	.required('Email is required'),
		phone: Yup.string(),
		// branch: Yup.string().required('Branch is required'),
		protrade_sales_person: Yup.string().required('Protrade Sales Person is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			const {
				project_name,
				email,
				phone,
				status,
				branch,
				main_contact,
				protrade_sales_person,
			} = values
			const mainContactData = contacts.find(
				(contact: any) => contact.id === main_contact
			)

			const ProjectData = {
				project_name,
				email,
				phone,
				status,
				branch,
				main_contact,
				mainContactData,
				protrade_sales_person,
			}

			// variable name mainContactData which is grabbed by matching main_contact to the contacts array and finding a matching one

			await updateProject(ProjectData, Number(project_id))

			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (project_id && (isLoading || contactsLoading)) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center">
					<Input
						title="Project"
						id="project_name"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.project_name}
						placeholder=""
						error={formik.errors.project_name}
					/>
				</div>
				<div className="flex items-center">
					<Input
						title="Contact #"
						id="phone"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.phone}
						placeholder=""
						error={formik.errors.phone}
					/>
					<Input
						title="Email"
						id="email"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.email}
						placeholder=""
						error={formik.errors.email}
					/>
				</div>
				<div className="flex items-center">
					<Dropdown
						label="Main Contact"
						id="main_contact"
						options={OptionsForDropdown(contacts, 'id', 'name')}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						value={formik.values.main_contact}
						error={formik.errors.main_contact}
					/>
					<Dropdown
						label="Status"
						id="status"
						options={statusOptions}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						value={formik.values.status}
						error={formik.errors.status}
					/>
				</div>
				<div className="flex items-center">
					{/* <Dropdown
						label="Branch"
						id="branch"
						options={branchOptions}
						value={formik.values.branch}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						error={formik.errors.branch}
					/> */}
					<Dropdown
						id="protrade_sales_person"
						label="Protrade Sales Person"
						options={OptionsForDropdown(activeStaffData, 'id', 'staff_name')}
						value={formik.values.protrade_sales_person}
						onChange={formik.setFieldValue}
						error={formik.errors.protrade_sales_person}
					/>
				</div>
			</SideModal>
		</>
	)
}
