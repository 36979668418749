import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Column } from 'primereact/column'
import { PencilIcon } from '@heroicons/react/24/solid'
import { DataTable } from 'primereact/datatable'

import { Badge, Container, DataTableHeader, PageHeading } from 'common'
import { AppRoutes } from 'config'
import { DataTableServices, ScaffoldRegisterServices } from 'services'
import { ScaffoldRegisterFrom } from 'components/ScaffoldRegister'
import { FilterMatchMode } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { dateFormat } from 'utilities'
import moment from 'moment'

export function ScaffoldRegisterMainTable() {
	const location = useLocation()
	const { data, isLoading, enableCreateUpdate } =
		ScaffoldRegisterServices.useScaffoldRegister()
	const [openTagForm, setOpenTagForm] = useState(false)
	// console.log({ data })

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		FilterColumn,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'tag_no',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [],
			},
			{
				filterName: 'last_inspection_date',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
			{
				filterName: 'inspection_due_date',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
			{
				filterName: 'branch',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Hawkes Bay',
					'Gisborne',
					'Wairarapa',
					'Taupo',
					'Dunedin',
					'Cromwell',
				],
			},
			{
				filterName: 'is_safe',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Safe', 'Unsafe'],
			},
		],
		additionalGlobalFilterFields: [
			'jobData.job_num',
			'description',
			'uploaded_by',
			'jobData.site',
			'jobData.clientData.client_name',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
		customFilters: [
			{
				field: 'branch',
				value: 'Hawkes Bay',
				color: 'success',
			},
			{
				field: 'branch',
				value: 'Gisborne',
				color: 'danger',
			},
			{
				field: 'branch',
				value: 'Wairarapa',
				color: 'warning',
			},
			{
				field: 'branch',
				value: 'Taupo',
				color: 'success',
			},
			{
				field: 'branch',
				value: 'Dunedin',
				color: 'warning',
			},
			{
				field: 'branch',
				value: 'Cromwell',
				color: 'danger',
			},
		],
	})

	const formattedData = data
		?.filter((data: any) => data.jobData.job_status !== 'Invoiced')
		?.map((data: any) => {
			return {
				...data,
				branch: data.jobData.branch,
				last_inspection_date_string: data.last_inspection
					? dateFormat.format(data.last_inspection_date)
					: '',
				inspection_due_date_string: data.inspection_due
					? dateFormat.format(data.inspection_due_date)
					: '',
				is_safe: data.is_safe ? 'Safe' : 'Unsafe',
			}
		})

	return (
		<div className="card">
			<PageHeading title="Scaffold Register" setOpen={setOpenTagForm} />
			<Container>
				<DataTable
					ref={dataTableReference}
					value={formattedData}
					paginator
					showGridlines
					rows={100}
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading}
					dataKey="id"
					filters={filters}
					stripedRows
					sortField="jobData.job_num"
					sortOrder={-1}
					groupRowsBy="jobData.job_num"
					globalFilterFields={globalFilterFields}
					rowGroupHeaderTemplate={subHeader}
					rowGroupMode="subheader"
					header={header}
					exportFilename={`Scaffold Register ${moment().format(
						'YYYY-MM-DD HH:mm'
					)}`}
					emptyMessage="No Scaffold Register found.">
					<Column
						header="Tag # (Details)"
						exportHeader="Tag #"
						field="tag_no"
						filter
						showFilterOperator={false}
						filterPlaceholder="Search by tag Number or Name"
						style={{ minWidth: '10rem' }}
						body={(row) => (
							<Link
								to={AppRoutes.privateRoutes.scaffoldRegisterDetail.replace(
									':id',
									row.id || ''
								)}>
								{row.tag_no}
							</Link>
						)}
					/>
					<Column header="Job Number" field="jobData.job_num" hidden={true} />
					<Column
						header="Client Name"
						field="jobData.clientData.client_name"
						hidden={true}
					/>
					<Column
						header="Description"
						field="description"
						style={{ minWidth: '6rem' }}
						{...FilterColumn.supervisor}
					/>
					<Column header="Site Address" field="jobData.site" hidden={true} />
					<Column
						filter
						header="Branch"
						field="branch"
						style={{ minWidth: '6rem' }}
						{...FilterColumn.branch}
					/>
					<Column
						header="Last Inspection"
						field="last_inspection_date"
						exportField="last_inspection_date_string"
						body={(row) =>
							row.last_inspection
								? dateFormat.format(row.last_inspection_date)
								: null
						}
						style={{ minWidth: '10rem' }}
						{...FilterColumn.last_inspection_date}
					/>
					<Column
						header="Inspection Due"
						field="inspection_due_date"
						exportField="inspection_due_date_string"
						body={(row) =>
							row.inspection_due
								? dateFormat.format(row.inspection_due_date)
								: null
						}
						style={{ minWidth: '10rem' }}
						{...FilterColumn.inspection_due_date}
					/>
					<Column
						header="Uploaded By"
						field="uploaded_by"
						style={{ minWidth: '10rem' }}
					/>
					<Column
						header="Status"
						field="status"
						bodyClassName="p-text-center"
						style={{ width: '6rem' }}
						body={(row) => <Badge type={row.status} text={row.status} />}
						{...FilterColumn.status}
					/>
					<Column
						header="Safe / Unsafe"
						field="is_safe"
						bodyClassName="p-text-center"
						style={{ width: '8rem' }}
						// body={(row) => <Badge type={row.status} text={row.status} />}
						{...FilterColumn.is_safe}
					/>
					{enableCreateUpdate && (
						<Column
							header="Edit"
							bodyClassName="p-text-center"
							exportable={false}
							style={{ width: '3rem' }}
							body={(row) => (
								<Link
									to={{
										pathname:
											AppRoutes.privateRoutes.scaffoldRegisterEdit.replace(
												':id',
												row.id || ''
											),
									}}
									state={{
										background: location,
										name: 'editScaffoldRegister',
									}}>
									<PencilIcon className="text-gray-600 h-4 w-4" />
								</Link>
							)}
						/>
					)}
				</DataTable>
			</Container>
			<ScaffoldRegisterFrom
				formType="create"
				heading="Create Scaffold Register"
				open={openTagForm}
				setOpen={setOpenTagForm}
			/>
		</div>
	)
}

const subHeader = (data: any) => (
	<span className="text-gray-900 font-bold">
		Job {data.jobData.job_num} - {data.jobData.site} -{' '}
		{data.jobData.clientData.client_name}
	</span>
)
