import React from 'react'
import { DateSelect } from 'common'
import { IStaffRow } from 'models/staff.model'
import { CreateFile } from 'common'

interface Props {
	values: IStaffRow
	setFieldValue: (
		field: string,
		value: unknown,
		shouldValidate?: boolean | undefined
	) => void
}

export const DrugAlcoholTest: React.FC<Props> = ({ values, setFieldValue }) => {
	return (
		<div>
			<h3 className="px-4 pt-2 text-lg font-semibold leading-5">
				Drug & Alcohol Test
			</h3>
			<div>
				<div className="flex items-center px-2">
          <div className="w-1/2 pl-2">
            <DateSelect
              title="Date of Last Test"
              id="last_drug_test"
              value={values.last_drug_test}
              onChange={setFieldValue}
            />
          </div>
				</div>
				<div className="w-1/2 pl-2">
					<CreateFile
						field="last_drug_test_photo"
						setFieldValue={setFieldValue}
						value={values.last_drug_test_photo}
					/>
				</div>
			</div>
		</div>
	)
}
