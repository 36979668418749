import { Image, Text, View } from '@react-pdf/renderer'
import { AppConfig } from 'config'
import * as logo from 'assets/logo.png'
import { stylesHeading } from './styles'

export const Heading = () => {
	return (
		<View style={stylesHeading.headingContainer} fixed>
			<Image style={stylesHeading.logo} src={logo.default} />
		</View>
	)
}
