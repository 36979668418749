import { useState, useEffect } from 'react'
import { Badge, Button } from 'common'
import { AppStore } from 'redux/store'
import { useSelector } from 'react-redux'
import { useValidateToken } from 'services/invoices'
import { AppConfig } from 'config'

export function MYOBStatus() {
	const userState = useSelector((store: AppStore) => store.user)
	const [fetchedInfo, setFetchedInfo] = useState(false)
	const { data, isLoading } = useValidateToken()
	const [isAuthenticated, setIsAuthenticated] = useState(false)

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search)
		const code = urlParams.get('code')

		if (code) {
			handleCallback(code)
		}
	}, [])

	useEffect(() => {
		if (!isLoading && data) {
			setFetchedInfo(true)
			if (data.isValid) {
				setIsAuthenticated(true)
			} else {
				setIsAuthenticated(false)
			}
		}
	}, [data, isLoading])

	const initiateLogin = async () => {
		try {
			fetch(`${AppConfig.urlBackend}/invoices/myob-auth/login`)
				.then((response) => response.json())
				.then((data) => {
					if (data.url) {
						window.location.href = data.url
					} else {
						console.error('Authorization URL not found')
					}
				})
				.catch((error) => {
					console.error('Error:', error)
				})
		} catch (error) {
			console.error('Failed to get login URL', error)
		}
	}

	const handleCallback = async (code: string) => {
		try {
			const response = await fetch(
				`${AppConfig.urlBackend}/invoices/myob-auth?code=${code}&email=${userState.email}`
			)

			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`)
			}

			setIsAuthenticated(true)
			window.history.replaceState({}, document.title, window.location.pathname)
		} catch (error) {
			console.error('Failed to handle callback', error)
		}
	}

	if (!fetchedInfo) {
		return (
			<div className="px-6 flex items-center">
				<Badge type="Pending" text="MYOB Status: Loading" />
			</div>
		)
	}

	if (!isAuthenticated) {
		return (
			<div className="flex flex-1 flex-col">
				<div className="px-6 flex items-center">
					<Badge type="Issue" text="MYOB Status: Login Required" />
				</div>
				<div className="px-6 flex items-center mt-4">
					<Button size="sm" variant="primary" onClick={initiateLogin}>
						Login With MYOB
					</Button>
				</div>
			</div>
		)
	}

	return (
		<div className="px-6 flex items-center">
			<Badge type="Active" text="MYOB Status: Authenticated" />
		</div>
	)
}
