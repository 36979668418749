import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { quoteLinesFn } from './linesHandlers'
import { quoteAdditionalLinesFn } from './addonsHandlers'
import { ratesFn } from './ratesHandlers'
import { zonesFn } from './zonesHandlers'
import { useUpdateQuote } from './updateQuote'
import {
	IQuoteForm,
	IQuoteAdditionalLinesHandlers,
	IQuoteLinesHandlers,
	IRatesHandlers,
	IZonesHandlers,
	QuoteData,
	IQuoteLine,
	IQuoteAdditionalLines,
	IQuoteZones,
	IRates,
	QuoteDataToUpdate,
	columnsQuoteLines,
	IQuoteFiles,
} from 'models/simple_quotes.model'
import { useServiceRates } from './useServiceRates'
import { totalsFn } from './totalHandlers'
import { useCreateQuote } from './createQuote'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'config'
import { assignDataToEdit } from './assignDataToEdit'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { Roles } from 'models'

interface IUseFormHandler {
	quote_id?: number
	quote_data?: QuoteData
	quote_lines?: IQuoteLine[]
	quote_addons?: IQuoteAdditionalLines[]
	quote_rates?: IRates[]
	quote_zones?: IQuoteZones[]
	quote_files?: IQuoteFiles[]
}

const itemsTabsAdmin = [
	{ label: 'Sections', id: 0 },
	{ label: 'Quote Rates', id: 1 },
	{ label: 'Default Rates Edit', id: 2 },
]

const itemsTabsStandard = [
	{ label: 'Sections', id: 0 },
	{ label: 'Rates', id: 1 },
]

export const useSimpleFormHandler = ({
	quote_id,
	quote_data,
	quote_lines,
	quote_addons,
	quote_rates,
	quote_zones,
	quote_files,
}: IUseFormHandler) => {
	const loggedInUserData = useSelector((store: AppStore) => store.user)

	let itemsTabs = []

	if (loggedInUserData?.userType === Roles.admin && !quote_id) {
		itemsTabs = itemsTabsAdmin
	} else {
		itemsTabs = itemsTabsStandard
	}

	const navigate = useNavigate()
	const { createQuote } = useCreateQuote()
	const {
		updateQuote,
		updateQuoteAddOns,
		updateQuoteLines,
		updateQuoteRates,
		updateQuoteZones,
		updateQuoteFiles,
	} = useUpdateQuote()

	const [editInfoLoaded, setEditInfoLoaded] = useState(false)
	const [backToMainTable, setBackToMainTable] = useState(false)

	const ratesInfo = useServiceRates()

	const validationSchema = Yup.object().shape({
		job_type: Yup.string().required('Job Type Is Required'),
		estimator: Yup.string().required('Estimator is Required'),
		client: Yup.string().required('Client is Required'),
		client_contact: Yup.string().required('Contact is Required'),
		variation_job_id: Yup.string().when('quote_type', (quote_type) => {
			if (quote_type[0] === 'Variation') {
				return Yup.string().required('Variation Job ID is required')
			}
			return Yup.string().nullable()
		}),
		zones: Yup.array().of(
			Yup.object().shape({
				zone_label: Yup.string().required('Section is required'),
			})
		),
		max_zones: Yup.number()
			.required('Max Sections is Required')
			.min(1, 'Max Sections is Required'),
		branch: Yup.string().required('Branch Is Required'),
	})

	const initialValues: IQuoteForm = {
		quote_type: 'New',
		quote_output_type: 'Detailed',
		job_type: '',
		variation_job_id: '',
		PO_Number: '',
		max_zones: 1,
		client: null,
		client_contact: null,
		quote_num: '',
		scope_of_work: '',
		estimator_notes: '',
		operation_notes: '',
		estimator: null,
		branch: '',

		fullAddress: '',
		street: '',
		country: '',
		street2: '',
		city: '',
		postal: '',

		quote_lines: [],

		quote_additional_lines: [],

		// TODO: Do this some other way that isn't UGLY
		terms: `• Scaffold hire commences from the start of erection based on the percentage erected at the end of each week.
• Quote based on plans only; price is subject to physical measurement
• Client responsible for any security measures while scaffold is in place.
• Trees, plants, and gardens must be trimmed by the client to make space for scaffold erection; price does not include disposal of trimmed vegetation. Protrade will take all practical care but will not be responsible for damage to foliage.
• Client to provide clear and unobstructed access to scaffold work area, including parking for trucks during installation and dismantling.
• Maybe times where driveway, garage, and carport may be blocked by scaffold.
• Client must use alternative fall prevention provisions on unguarded edges.
• Scaffolding is sometimes built on fragile roofs which are easily dented or damaged. Protrade will take all practical care but will not be responsible for damage to roofs, gardens, TV aerials, or Sky dishes.
• If requested client to arrange for a Close Proximity Permit from local power authorities and pay any associated costs to make network (street) cables safe, including providing an electrical certificate if required.
• Quote assumes minimal site congestion and good access to the building perimeter for trucks and personnel on the day of installation and dismantling; major delays will incur extra charges.
• Any changes or alterations will be charged at the relevant hourly rate.
• Scaffold will be built in accordance with New Zealand Scaffolding Good Practice Guidelines.
• This quote is subject to the Construction Contracts Act (2002).
• Shrinkwrap offers weather protection but is not waterproof; Protrade is not liable for major natural weather events.
• Shrinkwrap is guaranteed up to 80kph winds.
• Limited access to the perimeter of the building or undulating terrain sites may incur additional charges due to extra labour required for installation and dismantling.
• All scaffolding that remains installed for longer than 1 week is required by law to be inspected; a weekly inspection charge will be included after 2 weeks where applicable.

Payment Conditions

• 70% of EDC will be charged at the point of installation and 30% once off-hired.
• Unless noted, EDC rates assume working during normal hours of 7:00 am - 4:30 pm Monday-Friday.
• Rental commences on the earlier of sign-off or use of each section.
• Retentions may not be held.
• For non-account holders a 50% upfront payment of the total quoted cost (excluding hire) is required before commencement, the balance is payable upon completion or 7 days after the invoice date.
• Interest and collection charges will be applied to overdue accounts.
• Quotation is valid for acceptance and commencement of work for 90 days from the date of the quote.
• Should you wish to proceed after the 90-day period, please request a re-quote.
`,

		erectDismantleTotal: 0,
		additionalTotal: 0,
		weekTotal: 0,
		total: 0,

		rates: ratesInfo.data,
		zones: [],
		file_1: '',
		file_2: '',
		file_3: '',
		file_4: '',
		files: [],
		max_files: 4,
		main_origin: ''
	}

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (values) => {
			try {
				if (!editInfoLoaded) {
					// when the quote is new execute create a quote
					const resultQuote = await createQuote({
						...values,
						estimator: Number(values.estimator),
						client: Number(values.client),
					})
					if (resultQuote?.quote_id) {
						navigate(AppRoutes.privateRoutes.Quotes)
					}
				} else {
					// when the quote is edited execute update a quote
					const quoteData: QuoteDataToUpdate = {
						job_type: values.job_type,
						quote_type: values.quote_type,
						quote_output_type: values.quote_output_type,
						max_zones: values.max_zones,
						max_files: values.max_files,
						client: Number(values.client),
						client_contact: values.client_contact,
						scope_of_work: values.scope_of_work,
						estimator_notes: values.estimator_notes,
						operation_notes: values.operation_notes,
						estimator: Number(values.estimator),
						branch: values.branch,
						main_origin: values.main_origin,

						fullAddress: values.fullAddress,
						street: values.street,
						country: values.country,
						street2: values.street2,
						city: values.city,
						postal: values.postal,

						terms: values.terms,

						erectDismantleTotal: Number(values.erectDismantleTotal),
						additionalTotal: Number(values.additionalTotal),
						weekTotal: Number(values.weekTotal),
						total: Number(values.total),
						variation_job_id: null,
						PO_Number: null,
						file_1: values.file_1,
						file_2: values.file_2,
						file_3: values.file_3,
						file_4: values.file_4,
					}
					updateQuote(quoteData, quote_id as number)
					updateQuoteAddOns(values.quote_additional_lines, quote_id as number)
					updateQuoteLines(values.quote_lines, quote_id as number)
					updateQuoteRates(values.rates, quote_id as number)
					updateQuoteZones(values.zones, quote_id as number)
					updateQuoteFiles(values.files, quote_id as number)

					if (backToMainTable) navigate(AppRoutes.privateRoutes.Quotes)
					else
						navigate(
							AppRoutes.privateRoutes.QuotesDetail.replace(
								':id',
								String(quote_id)
							)
						)
				}
			} catch (error) {
				console.log(error)
			}
		},
	})

	const quoteLinesHandlers: IQuoteLinesHandlers = {
		addNewQuoteLine: () => {
			const lines = quoteLinesFn.addNewQuoteLine(
				formik.values.quote_lines,
				formik.values.quote_output_type
			)
			formik.setFieldValue('quote_lines', lines)
		},
		removeQuoteLine: (index: number) => {
			const lines = quoteLinesFn.removeQuoteLine(
				index,
				formik.values.quote_lines
			)
			formik.setFieldValue('quote_lines', lines)
		},
		updateQuoteLine: async (
			index: number,
			field: string,
			value: string | number | boolean
		) => {
			let newQuoteLines = quoteLinesFn.updateQuoteLine(
				index,
				formik.values.quote_lines,
				field,
				value
			)
			newQuoteLines = quoteLinesFn.checkZones(
				formik.values.zones,
				newQuoteLines
			)
			newQuoteLines = quoteLinesFn.calculateQuoteLines(
				formik.values.rates,
				newQuoteLines,
				field
			)
			formik.setFieldValue('quote_lines', newQuoteLines)
		},
		validateQuoteLinesColumns: () => {
			return columnsQuoteLines.map((column) => column)
		},
	}

	const quoteAdditionalLinesHandlers: IQuoteAdditionalLinesHandlers = {
		addNewQuoteAdditionalLine: (newLine?: IQuoteAdditionalLines) => {
			const newline = quoteAdditionalLinesFn.addNewQuoteLine(
				formik.values.quote_additional_lines,
				newLine
			)
			formik.setFieldValue('quote_additional_lines', newline)
		},
		removeQuoteAdditionalLine: (index: number) => {
			const lines = quoteAdditionalLinesFn.removeQuoteLine(
				index,
				formik.values.quote_additional_lines
			)
			formik.setFieldValue('quote_additional_lines', lines)
		},
		updateQuoteAdditionalLine: async (
			index: number,
			field: string,
			value: string | number,
			manual?: boolean
		) => {
			let lines = quoteAdditionalLinesFn.updateQuoteLine(
				index,
				formik.values.quote_additional_lines,
				field,
				value
			)
			if (field !== 'description') {
				lines = quoteAdditionalLinesFn.calculateLines(
					index,
					lines,
					formik.values.rates,
					field,
					manual
				)
			}
			if (field === 'zone_id') {
				lines = quoteAdditionalLinesFn.checkZones(
					formik.values.zones,
					lines,
					index,
					value
				)
			}

			formik.setFieldValue('quote_additional_lines', lines)
		},
	}

	const ratesHandlers: IRatesHandlers = {
		addNewRate: () =>
			ratesFn.addNewRate(formik.values.rates, formik.setFieldValue),
		removeRate: (index: number) =>
			ratesFn.removeRate(index, formik.values.rates, formik.setFieldValue),
		updateRate: (index: number, field: string, value: string | number) =>
			ratesFn.updateRate(
				index,
				formik.values.rates,
				formik.setFieldValue,
				field,
				value
			),
	}

	const zonesHandlers: IZonesHandlers = {
		addNewZone: (zone_id: number) =>
			zonesFn.addNewZone(zone_id, formik.values.zones, formik.setFieldValue),
		removeZone: (index: number) =>
			zonesFn.removeZone(index, formik.values.zones, formik.setFieldValue),
		updateZone: (index: number, field: string, value: string | number) =>
			zonesFn.updateZone(
				index,
				formik.values.zones,
				formik.setFieldValue,
				field,
				value
			),
	}

	useEffect(() => {
		if (
			quote_data &&
			quote_lines &&
			quote_addons &&
			quote_rates &&
			quote_zones &&
			quote_files &&
			!editInfoLoaded
		) {
			const newData = assignDataToEdit({
				quote_data,
				quote_lines,
				quote_addons,
				quote_rates,
				quote_zones,
				quote_files,
			})
			setTimeout(() => {
				formik.setValues(newData)
				setEditInfoLoaded(true)
			}, 200)
		}
	}, [
		quote_data,
		quote_lines,
		quote_addons,
		quote_rates,
		quote_zones,
		quote_files,
	])

	useEffect(() => {
		if (ratesInfo.data && !editInfoLoaded && !quote_id) {
			formik.setFieldValue('rates', ratesInfo.data)
		}
	}, [ratesInfo.data])

	// Check the zones when the max_zones changes
	useEffect(() => {
		zonesFn.checkZones(
			formik.values.max_zones,
			formik.values.zones,
			formik.setFieldValue
		)
	}, [formik.values.max_zones])

	// Check the quote lines when the zones, rates or estimatedWay changes
	useEffect(() => {
		let newQuoteLines = quoteLinesFn.checkZones(
			formik.values.zones,
			formik.values.quote_lines
		)
		newQuoteLines = quoteLinesFn.calculateQuoteLines(
			formik.values.rates,
			newQuoteLines
		)
		// console.log('wwww')
		formik.setFieldValue('quote_lines', newQuoteLines)
	}, [formik.values.zones, formik.values.rates])

	//Functions to calculate the totals
	useEffect(() => {
		const erectDismantleTotal = totalsFn.calculateEDtotal(
			formik.values.quote_lines
		)

		const additionalTotal = totalsFn.calculateAdditionalTotal(
			formik.values.quote_additional_lines
		)

		const weekTotal = totalsFn.calculateWeekTotal(formik.values.quote_lines)

		const total = erectDismantleTotal + additionalTotal + weekTotal

		formik.setFieldValue('erectDismantleTotal', erectDismantleTotal)
		formik.setFieldValue('additionalTotal', additionalTotal)
		formik.setFieldValue('weekTotal', weekTotal)
		formik.setFieldValue('total', total)
	}, [formik.values.quote_lines, formik.values.quote_additional_lines])

	return {
		formik,
		quoteLinesHandlers,
		quoteAdditionalLinesHandlers,
		ratesHandlers,
		zonesHandlers,
		setBackToMainTable,
		itemsTabs,
	}
}
