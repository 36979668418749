import { pdf } from '@react-pdf/renderer'
import { QuoteData, IQuoteLine, IQuoteAdditionalLines } from 'models'
import { QuotePDFDocument } from './Output'
import { SummaryQuotePDFDocument } from './SummaryOutput'

interface QuotePDFBlodProps {
	quote_data: QuoteData
	quote_lines: IQuoteLine[]
	quote_addons: IQuoteAdditionalLines[]
}

export const MakeBlodQuote = async ({
	quote_data,
	quote_lines,
	quote_addons,
}: QuotePDFBlodProps) => {
	const documentType =
		quote_data.quote_output_type === 'Hire Inclusive'
			? SummaryQuotePDFDocument
			: QuotePDFDocument

	const newBlob = await pdf(
		documentType({ quote_data, quote_lines, quote_addons })
	).toBlob()

	return newBlob
}
