export interface IStaffRow {
	id?: string
	userType: 'Standard' | 'Admin'
	accessToPortal: 'Yes' | 'No'
	accessToMobile: 'Yes' | 'No'
	staff_name: string
	full_name: string
	type: StaffType
	mobile: string
	email: string
	position: string
	street: string
	street_2: string
	city: string
	post_code: string
	pin: string
	start_date: string
	dob: string
	driver_license: string
	license_type: string
	license_class2: string
	endorsement: string
	endorsement_complete_date: string
	endorsement_expiry: string
	photo_front: string
	photo_back: string
	license_assessed_by: string
	induction_date: string
	expiry_date: string
	photo: string
	hs_assessed_by: string
	passport_num: string
	passport_type: string
	passport_issue: string
	passport_expiry: string
	passport_photo: string
	site_safe_assessed_by: string
	first_aid_issue: string
	first_aid_expiry: string
	first_aid_photo: string
	firstaid_assessed_by: string
	cert_num: string
	cert_issue_date: string
	cert_expiry_date: string
	cert_level: string
	cert_photo: string
	scaff_cert_assessed_by: string
	sop_train: string
	height_training: string
	height_training_issue: string
	height_training_expiry: string
	height_training_assessed_by: string
	other_photo: string
	ird_num: string
	last_drug_test: string
	last_drug_test_photo: string
	kiwisaver: string
	employment_contract: string
	status: StaffStatus
	confined_uploaded_by: string | null
	confined_file: string
	confined_date: string
	confined_expiry_date: string
	branch: string
	working_at_heights_level: string
	working_at_heights_refresher_due: string
	safety_nets_faset_file: string
	safety_nets_faset_completed: string
	safety_nets_faset_expires: string
	fall_arrest_23229: boolean
	fall_arrest_23229_refresher_due: string
	fall_arrest_15757: boolean
	fall_arrest_15757_refresher_due: string
	fall_arrest_file: string
	emergency_response_fa_26551: boolean
	emergency_response_fa_26551_refresher_due: string
	emergency_response_fa_26552: boolean
	emergency_response_fa_26552_refresher_due: string
	emergency_response_fa_file: string
	rope_access_equipment_19359: boolean
	rope_access_equipment_19359_refresher_due: string
	rope_access_equipment_19359_file: string
	elevated_walk_platform: boolean
	elevated_walk_platform_refresher_due: string
	elevated_walk_platform_file: string
	incline_lifts_training: boolean
	incline_lifts_training_date_completed: string
	incline_lifts_training_file: string
	forklift_operator_cert: boolean
	forklift_operator_cert_refresher_due: string
	forklift_operator_cert_file: string
	mobile_crane_3795: boolean
	mobile_crane_3795_refresher_due: string
	other_file: string
	emergency_contact_name: string
	emergency_contact_relationship_to: string
	emergency_contact_number: string
	emergency_contact_email: string
}

export enum StaffType {
	'Employee',
	'Crew Leader',
	'Office',
	'Scaffolder',
	'Truck Driver',
	'Application',
	'Contractor',
	'Yard Person',
	'Sales',
	'N/A' = '',
}

export enum StaffStatus {
	Active = 'Active',
	Inactive = 'Inactive',
}

export const StaffTypeOptions = Object.values(StaffType)
	.filter((v) => typeof v === 'string')
	.map((v) => v) as string[]

export const StaffStatusOptions = Object.values(StaffStatus)
	.filter((v) => typeof v === 'string')
	.map((v) => v) as string[]
