import { useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { DataTableServices, ProjectServices } from 'services'
import { Badge, Container, DataTableHeader, PageHeading } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { Link, useLocation } from 'react-router-dom'
import { FolderOpenIcon, PencilIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { CreateProjectsForm } from 'components/Projects'

export const ProjectsMainTable = () => {
	const location = useLocation()
	const { data, isLoading } = ProjectServices.useProjects()
	const [openProjectForm, setOpenProjectForm] = useState(false)

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'project_name',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [],
			},
			{
				filterName: 'status',
				filterInitialValue: 'Active',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Active', 'Inactive'],
			},
			{
				filterName: 'branch',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [],
			},
		],
		additionalGlobalFilterFields: ['phone', 'email'],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	return (
		<div className="card">
			<PageHeading
				title="Projects"
				createBtn="Create Project"
				isEditable={false}
				setOpen={setOpenProjectForm}
			/>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={data}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading}
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					sortField='project_name'
					sortOrder={1}
					header={header}
					stripedRows
					emptyMessage="No Projects found.">
					<Column
						header="Project (Details)"
						field="project_name"
						style={{ maxWidth: '8rem', textAlign: 'center' }}
						body={(row) => (
							<Link
								key={`details${row.id}`}
								to={AppRoutes.privateRoutes.ProjectsDetail.replace(
									':id',
									row.id || ''
								)}
								className="flex items-center">
								<FolderOpenIcon className="h-4 w-4 text-gray-500 mr-2" />
								<span className="hover:text-gray-800">{row.project_name}</span>
							</Link>
						)}
					/>
					<Column header="Phone" field="phone" style={{ minWidth: '10rem' }} />
					<Column header="Email" field="email" style={{ minWidth: '10rem' }} />
					<Column
						filter
						header="Branch"
						field="branch"
						style={{ minWidth: '8rem' }}
						showFilterOperator={false}
						filterPlaceholder="Search by branch name"
					/>
					<Column
						field="status"
						header="Status"
						bodyClassName="p-text-center"
						style={{ width: '6rem', textAlign: 'center' }}
						alignHeader={'center'}
						body={(row) => <Badge type={row.status} text={row.status} />}
						{...FilterColumn.status}
					/>
					<Column
						header="Edit"
						bodyClassName="p-text-center"
						style={{ width: '3rem' }}
						body={(row) => (
							<Link
								to={{
									pathname: AppRoutes.privateRoutes.ProjectsEdit.replace(
										':id',
										row.id
									),
								}}
								state={{ background: location, name: 'editProject' }}>
								<PencilIcon className="text-gray-600 h-4 w-4" />
							</Link>
						)}
					/>
				</DataTable>
			</Container>
			<CreateProjectsForm
				formType="create"
				heading="Create Project"
				open={openProjectForm}
				setOpen={setOpenProjectForm}
			/>
		</div>
	)
}
