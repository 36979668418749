import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline'
import { Dropdown, Input, SideModal, Spinner, TextArea } from 'common'
import { useFormik } from 'formik'
import { item_codes_options } from 'models'
import { useEffect, useRef, useState } from 'react'
import { InvoiceServices, JobsServices, QuoteServices } from 'services'
import { OptionsForDropdown, OptionsForDropdownFilter } from 'utilities'
import * as Yup from 'yup'

const hourlyRateOptions = [
	{
		value: 75,
		label: '$75',
	},
	{
		value: 85,
		label: '$85',
	},
	{
		value: 93,
		label: '$93',
	},
	{
		value: 120,
		label: '$120',
	},
	{
		value: 150,
		label: '$150',
	},
]

interface IProps {
	invoice_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
	isDayworks: boolean
	job_id: number
	total: number
	hours: number
}

interface IinitialValues {
	zone: string
	zone_label: string
	type: string
	description: string
	erect: number
	dismantle: number
	total: number
	PO_Number: string
	hourly_rate: number
	total_hours: number
	task_id: string
	amount_to_invoice: number
	item_code: string
	complete_percent: number
	is_total_manually_changed: boolean
}
export const EdInvoiceEditForm = ({
	invoice_id,
	heading,
	setOpen,
	formType,
	open,
	isDayworks,
	job_id,
	total,
	hours,
}: IProps) => {
	const { data: invoiceData, isLoading: invoiceLoading } =
		InvoiceServices.useEdInvoiceById(invoice_id)
	const { data: TaskData, isLoading: taskIsLoading } =
		JobsServices.useJobTask(job_id)

	const { data: rates, isLoading } = QuoteServices.useServiceRates()

	const { updateEdInvoice } = InvoiceServices.useUpdateEDInvoice()
	const { createEdInvoice } = InvoiceServices.useCreateEDInvoice()

	const [totalLocked, setTotalLocked] = useState(true)

	const initialValues: IinitialValues = {
		total: invoiceData?.total || total || 0,
		erect: invoiceData?.erect || 0,
		dismantle: invoiceData?.dismantle || 0,
		zone: invoiceData?.zone || '',
		zone_label: invoiceData?.zone_label || '',
		type: isDayworks ? 'Day Works' : invoiceData?.type || '',
		description: invoiceData?.description || '',
		PO_Number: invoiceData?.PO_Number || '',
		hourly_rate: invoiceData?.hourly_rate || 0,
		total_hours: invoiceData?.total_hours || hours || 0,
		task_id: invoiceData?.task_id || '',
		amount_to_invoice: 0,
		item_code: invoiceData?.item_code || '',
		complete_percent: invoiceData?.complete_percent || 0,
		is_total_manually_changed: invoiceData?.is_total_manually_changed || false,
	}

	const validationSchema = Yup.object({
		task_id: Yup.number().required('Task is required'),
		amount_to_invoice: Yup.number().test(
			'is-not-greater-than-or-equal',
			'Amount to invoice cannot be greater than or equal to the total invoiceable',
			function (value) {
				const { total } = this.parent
				if (value === 0 && Number(total) === 0) {
					return true
				}
				if (value != null && total != null) {
					return value < total
				}
				return true
			}
		),
		item_code: Yup.string().required('Item Code is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'update' && invoice_id) {
				if (values.amount_to_invoice) {
					await createEdInvoice(invoiceData.job_id, {
						task_id: Number(values.task_id),
						zone: values.zone,
						zone_label: values.zone_label,
						type: values.type,
						description: values.description,
						PO_Number: values.PO_Number,
						complete_percent: 0,
						hourly_rate: values.hourly_rate,
						total: values.amount_to_invoice,
						splitFrom:
							invoiceData.total === invoiceData.erect ? 'erect' : 'dismantle',
						item_code: values.item_code,
					})
					await updateEdInvoice(invoice_id, {
						...invoiceData,
						PO_Number: values.PO_Number,
						description: values.description,
						total: values.total - values.amount_to_invoice,
						zone: values.zone,
						zone_label: values.zone_label,
						type: values.type,
						hourly_rate: values.hourly_rate,
						total_hours: values.total_hours,
						task_id: Number(values.task_id),
						item_code: values.item_code,
						complete_percent: values.complete_percent,
					})
				} else {
					await updateEdInvoice(invoice_id, {
						...invoiceData,
						PO_Number: values.PO_Number,
						description: values.description,
						total: values.total,
						zone: values.zone,
						zone_label: values.zone_label,
						type: values.type,
						hourly_rate: values.hourly_rate,
						total_hours: values.total_hours,
						task_id: Number(values.task_id),
						item_code: values.item_code,
						complete_percent: values.complete_percent,
					})
				}
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	const prevTotalRef = useRef(formik.values.total)
	const prevRateRef = useRef({
		total_hours: formik.values.total_hours,
		hourly_rate: formik.values.hourly_rate,
	})

	useEffect(() => {
		if (prevTotalRef.current !== formik.values.total) {
			formik.setFieldValue('is_total_manually_changed', true)
		}
		prevTotalRef.current = formik.values.total
	}, [formik.values.total])

	useEffect(() => {
		if (isDayworks) {
			const handleTotalCalculation = () => {
				if (!totalLocked) {
					const total = formik.values.total_hours * formik.values.hourly_rate
					formik.setFieldValue('total', Number(total).toFixed(2))
				}
			}

			const { total_hours, hourly_rate } = formik.values
			if (
				prevRateRef.current.total_hours !== total_hours ||
				prevRateRef.current.hourly_rate !== hourly_rate
			) {
				formik.setFieldValue('is_total_manually_changed', false)
			}

			prevRateRef.current = { total_hours, hourly_rate }

			handleTotalCalculation()
		}
	}, [
		formik.values.total_hours,
		formik.values.hourly_rate,
		totalLocked,
		isDayworks,
	])

	// useEffect(() => {
	// 	if (isDayworks) {
	// 		const handleTotalCalculation = () => {
	// 			if (!totalLocked) {
	// 				const total = formik.values.total_hours * formik.values.hourly_rate
	// 				formik.setFieldValue('total', Number(total).toFixed(2))
	// 			}
	// 		}

	// 		handleTotalCalculation()
	// 	}
	// }, [formik.values.total_hours, formik.values.hourly_rate, totalLocked])

	useEffect(() => {
		if (formik.values.type) {
			const type = rates?.find(
				(rate: any) => rate.service === formik.values.type
			)
			if (type) {
				formik.setFieldValue('hourly_rate', type.fee)
				// if (type.service === 'Inspection') {
				// 	formik.setFieldValue('zone', 99)
				// 	formik.setFieldValue('zone_label', 'Additional Items')
				// }
			}
		}
	}, [formik.values.type, rates])

	// useEffect(() => {
	// 	if (formik.values.task_id && !taskIsLoading) {
	// 		const task = TaskData?.find(
	// 			(task: { id: string }) =>
	// 				task.id === String(formik.values.task_id)
	// 		)
	// 		if (!formik.values.type.toLowerCase().includes('delivery') && !formik.values.type.toLowerCase().includes('inspection')) {
	// 			formik.setFieldValue('description', task?.description ?? '')
	// 		}
	// 		// if (formik.values.type !== 'Inspection') {
	// 		// 	formik.setFieldValue('zone', task.zone)
	// 		// 	formik.setFieldValue('zone_label', task.zone_label)
	// 		// }
	// 	}
	// }, [formik.values.task_id, taskIsLoading])

	const handleBlur = (event: any) => {
		let value = event.target.value
		const name = event.target.name

		if (!isNaN(value) && value !== '') {
			value = parseFloat(value).toFixed(2)
			formik.setFieldValue(name, value)
		}
	}

	// if (invoice_id && (invoiceLoading || taskIsLoading || isLoading)) {
	// 	return <Spinner />
	// }

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={
					formik.isSubmitting || invoiceLoading || taskIsLoading || isLoading
				}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Dropdown
							id="task_id"
							label="Task"
							options={OptionsForDropdownFilter(
								TaskData,
								'id',
								['zone_label', 'type', 'description'],
								'job_id',
								job_id!
							)}
							onChange={formik.setFieldValue}
							value={String(formik.values.task_id)}
							error={formik.errors.task_id}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="Section"
							id="zone_label"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Section"
							value={formik.values.zone_label}
							error={formik.errors.zone_label}
							disabled
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="PO Number"
							id="PO_Number"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="e.g. PO-1234"
							value={formik.values.PO_Number}
							error={formik.errors.PO_Number}
						/>
					</div>
				</div>
				{/* <div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						{isDayworks ? (
							<Input
								title="Type"
								id="type"
								type="text"
								handleBlur={formik.handleBlur}
								handleChange={formik.handleChange}
								placeholder="Type"
								value={formik.values.type}
								error={formik.errors.type}
								disabled
							/>
						) : (
							<Dropdown
								id="type"
								label="Type"
								onChange={formik.setFieldValue}
								onBlur={formik.handleBlur}
								value={formik.values.type}
								error={formik.errors.type}
								options={OptionsForDropdown(rates, 'id', 'service')}
							/>
						)}
					</div>
				</div> */}
				<div className="flex items-center justify-between px-2 h-40">
					<div className="w-full ">
						<TextArea
							title="Description"
							id="description"
							type="text"
							className=" resize-none"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Description"
							rows={5}
							value={formik.values.description}
							error={formik.errors.description}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="item_code"
							label="Item Code"
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							options={item_codes_options}
							value={formik.values.item_code}
							error={formik.errors.item_code}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Complete Percent"
							id="complete_percent"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Complete Percent"
							value={formik.values.complete_percent}
							error={formik.errors.complete_percent}
						/>
					</div>
				</div>
				{isDayworks ? (
					<div className="flex items-center justify-between px-2">
						<div className="w-1/2">
							<Dropdown
								label="Hourly Rate"
								id="hourly_rate"
								options={hourlyRateOptions}
								value={formik.values.hourly_rate}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
								error={formik.errors.hourly_rate}
							/>
						</div>
						<div className="w-1/2">
							<Input
								title="Total Hours"
								id="total_hours"
								type="number"
								handleBlur={formik.handleBlur}
								handleChange={formik.handleChange}
								placeholder="Total Hours"
								value={formik.values.total_hours}
								error={formik.errors.total_hours}
							/>
						</div>
					</div>
				) : null}
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2 flex items-end">
						<Input
							id="amount_to_invoice"
							title="Amount to Invoice"
							placeholder="Amount to Invoice"
							type="number"
							handleBlur={handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.amount_to_invoice}
							error={formik.errors.amount_to_invoice}
						/>
					</div>
					<div className="w-1/2 flex items-end">
						<Input
							title="Total Invoiceable"
							id="total"
							type="number"
							handleBlur={handleBlur}
							handleChange={formik.handleChange}
							placeholder="Total"
							value={formik.values.total}
							error={formik.errors.total}
							disabled={!totalLocked}
						/>
						<div>
							<button
								type="button"
								onClick={() => setTotalLocked(!totalLocked)}
								className="ml-2 p-2 mb-2 border rounded-full hover:bg-gray-200 flex items-center justify-center">
								{totalLocked ? (
									<LockClosedIcon className="h-5 w-5 text-gray-700" />
								) : (
									<LockOpenIcon className="h-5 w-5 text-gray-700" />
								)}
							</button>
						</div>
					</div>
				</div>
			</SideModal>
		</>
	)
}
