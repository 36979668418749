import React from 'react'
import { DateSelect } from 'common'
import { IStaffRow } from 'models/staff.model'
import { CreateFile } from 'common'

interface Props {
	values: IStaffRow
	setFieldValue: (
		field: string,
		value: unknown,
		shouldValidate?: boolean | undefined
	) => void
}

export const EmploymentContract: React.FC<Props> = ({ values, setFieldValue }) => {
	return (
		<div>
			<h3 className="px-4 pt-2 text-lg font-semibold leading-5">
				Employment Contract
			</h3>
			<div>
				<div className="w-1/2 pl-2 mt-4">
					<CreateFile
						field="employment_contract"
						setFieldValue={setFieldValue}
						value={values.employment_contract}
					/>
				</div>
			</div>
		</div>
	)
}
