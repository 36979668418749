import { Text, View } from '@react-pdf/renderer'
import { QuoteData } from 'models'
import { stylesClientInfo as styles } from './styles'
import moment from 'moment'

interface ClientInfoProps {
	quote: QuoteData
}

export const ClientInfo = ({ quote }: ClientInfoProps) => {
	const client = quote?.clientData?.client_name

	const street1 = quote?.street
	return (
		<View style={styles.headingContainer}>
			<View style={styles.clientContainer}>
				<View style={styles.headingContact}>
					<View style={styles.headingDisplay}>
						<Text style={styles.headingText}>Client:</Text>
						<Text style={styles.subText}>{client || ' '}</Text>
					</View>
					<View style={styles.headingDisplay}>
						<Text style={styles.subText3}>Site: </Text>
						<View>
							<View>
								{street1 ? <Text style={styles.subText3}>{street1.normalize('NFD').replace(/[\u0300-\u036f]/g, '')}</Text> : null}
							</View>
						</View>
					</View>
				</View>
			</View>
			<View style={styles.headingDisplayWrapper}>
				<View style={styles.headingDisplay}>
					<Text style={styles.headingText}>Date:</Text>
					<Text style={styles.subText}>
						{moment(quote.created_at).format('DD/MM/YYYY')}
					</Text>
				</View>
				<View style={styles.headingDisplay}>
					<Text style={styles.subText2}></Text>
				</View>
				<View style={styles.headingDisplay}>
					<Text style={styles.subText2}></Text>
				</View>
				<View style={styles.headingDisplay}>
					<Text style={styles.headingText}>Quote #:</Text>
					<Text style={styles.subText}>{quote?.quote_num}</Text>
				</View>
			</View>
		</View>
	)
}
