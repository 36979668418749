import { Section, Spinner, TwoColumnDetails } from 'common'
import { AppRoutes } from 'config'
import { useLocation, useParams } from 'react-router-dom'
import { ProjectServices } from 'services'
import { ContactsTable } from 'components/Contacts'
import { Notes } from 'components/Notes'

export const DetailsPage = () => {
	const { id } = useParams()
	const location = useLocation()
	const projectId = parseInt(id || '') || undefined
	const { data: project, isLoading } = ProjectServices.useProjectById(projectId)

	if (isLoading) {
		return <Spinner />
	}

	return (
		<>
			<div className="w-full mx-auto mt-8">
				<TwoColumnDetails
					heading="Project Details"
					editBtn="Edit Project"
					isEditable={true}
					editLink={{
						to: AppRoutes.privateRoutes.ProjectsEdit.replace(':id', id || ''),
						state: {
							background: location,
							name: 'editProject',
						},
					}}>
					<Section title="Project" content={project.project_name} />
					<Section title="Project Phone" content={project.phone} />
					<Section title="Project Email" content={project.email} />
					<Section title="Status" content={project.status} />
					<Section
						title="Main Contact Name"
						content={project.mainContactData?.name}
					/>
					<Section
						title="Main Contact Phone"
						content={project.mainContactData?.phone}
					/>
					<Section
						title="Main Contact Email"
						content={project.mainContactData?.email}
					/>
					<Section title='' content={null}/>
					<Section
						title="Protrade Sales Person Name"
						content={project?.protradeSalesPersonData?.staff_name}
					/>
					<Section
						title="Protrade Sales Person Email"
						content={project?.protradeSalesPersonData?.email}
					/>
				</TwoColumnDetails>
				<Notes type="project" id={id || ''} />
				<ContactsTable type='project' id={projectId} />
			</div>
		</>
	)
}
