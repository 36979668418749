import { Dropdown, Input, SideModal, Spinner } from 'common'
import { useFormik } from 'formik'
import { ContactServices, ProjectServices } from 'services'
import * as Yup from 'yup'

interface IProps {
	project_id?: string | number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

const statusOptions = [
	{ label: 'Active', value: 'Active' },
	{ label: 'Inactive', value: 'Inactive' },
]

const branchOptions = [
	{
		value: 'Hawkes Bay',
		label: 'Hawkes Bay',
	},
	{
		value: 'Gisborne',
		label: 'Gisborne',
	},
	{
		value: 'Wairarapa',
		label: 'Wairarapa',
	},
	{
		value: 'Taupo',
		label: 'Taupo',
	},
	{
		value: 'Dunedin',
		label: 'Dunedin',
	},
	{
		value: 'Cromwell',
		label: 'Cromwell',
	},
]

interface createClientRow {
	project_name: string
	email: string
	phone: string
	status: string
	branch: string
	main_contact: string
	contactName: string
	contactEmail: string
	contactphone: string
}

export const CreateProjectsForm = ({
	project_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data, isLoading } = ProjectServices.useProjectById(project_id)
	const { createProject } = ProjectServices.useCreateProject()
	const { updateProject } = ProjectServices.useUpdateProject()
	const { createProjectContact } = ContactServices.useCreateProjectContact()

	const initialValues: createClientRow = {
		project_name: data?.project_name || '',
		email: data?.email || '',
		phone: data?.phone || '',
		status: data?.status || 'Active',
		branch: data?.branch || '',
		main_contact: data?.main_contact || null,
		contactName: data?.contactName || '',
		contactEmail: data?.contactEmail || '',
		contactphone: data?.contactphone || '',
	}

	const validationSchema = Yup.object({
		project_name: Yup.string().required('Company is required'),
		// email: Yup.string()
		// 	.email('Invalid email address')
		// 	.required('Email is required'),
		phone: Yup.string(),
		branch: Yup.string().required('Branch is required'),
		contactName: Yup.string().required('Main Contact Name is required'),
		contactEmail: Yup.string()
			.email('Invalid email address')
			.required('Main Contact Email is required'),
		contactphone: Yup.string(),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			const { project_name, email, phone, status, branch } = values

			const ProjectData = {
				project_name,
				email,
				phone,
				status,
				branch,
			}

			const projectCreatedResult = await createProject(ProjectData)
			if (!projectCreatedResult.id) {
				console.error('Error creating project')
				return
			}
			const { contactName, contactEmail, contactphone } = values

			const ContactData = {
				name: contactName,
				email: contactEmail,
				phone: contactphone,
				status: 'Active',
				project_id: Number(projectCreatedResult.id),
			}
			const resultContact = await createProjectContact(ContactData)
			console.log(resultContact)
			const newClientUpdateData = {
				...projectCreatedResult.project_data,
				main_contact: resultContact.data.id,
			}
			console.log(newClientUpdateData)
			const updatedContact = await updateProject(
				newClientUpdateData,
				projectCreatedResult.id
			)
			console.log(updatedContact)

			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (project_id && isLoading) {
		return <Spinner />
	}

	return (
		<SideModal
			heading={heading}
			open={open}
			setOpen={setOpen}
			handleSubmit={formik.handleSubmit}
			isLoading={formik.isSubmitting}
			formType={formType}>
			<div className="flex items-center">
				<Input
					title="Project"
					id="project_name"
					type="text"
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					value={formik.values.project_name}
					placeholder=""
					error={formik.errors.project_name}
				/>
			</div>
			<div className="flex items-center">
				<Input
					title="Contact #"
					id="phone"
					type="text"
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					value={formik.values.phone}
					placeholder=""
					error={formik.errors.phone}
				/>
				<Input
					title="Email"
					id="email"
					type="text"
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					value={formik.values.email}
					placeholder=""
					error={formik.errors.email}
				/>
			</div>
			<div className="flex items-center">
				<Dropdown
					label="Status"
					id="status"
					options={statusOptions}
					onChange={formik.setFieldValue}
					onBlur={formik.setFieldTouched}
					value={formik.values.status}
					error={formik.errors.status}
				/>
				<Dropdown
					label="Branch"
					id="branch"
					options={branchOptions}
					value={formik.values.branch}
					onChange={formik.setFieldValue}
					onBlur={formik.setFieldTouched}
					error={formik.errors.branch}
				/>
			</div>
			<div className="flex items-center">
				<Input
					title="Contact Name"
					id="contactName"
					type="text"
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					value={formik.values.contactName}
					placeholder=""
					error={formik.errors.contactName}
				/>
			</div>
			<div className="flex items-center">
				<Input
					title="Contact #"
					id="contactphone"
					type="text"
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					value={formik.values.contactphone}
					placeholder=""
					error={formik.errors.contactphone}
				/>
				<Input
					title="Email"
					id="contactEmail"
					type="text"
					handleChange={formik.handleChange}
					handleBlur={formik.handleBlur}
					value={formik.values.contactEmail}
					placeholder=""
					error={formik.errors.contactEmail}
				/>
			</div>
		</SideModal>
	)
}
