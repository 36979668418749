import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { IStaffRow, StaffStatus, StaffType } from 'models/staff.model'

export const useSetupServer = () => {
	const navigator = useNavigate()
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { putRequest } = useApi()

	/* 	useEffect(() => {
		getRequest(AppRoutes.serverAuthRoutes.checkEnableConfig)
			.then((response) => {
				if (!response) {
					navigator(AppRoutes.privateRoutes.Dashboard)
				}
			})
			.catch(() => {
				navigator(AppRoutes.privateRoutes.Dashboard)
			})
	}, []) */

	const createUser = async (userEmail: string, userName: string) => {
		try {
			const payload: IStaffRow = {
				userType: 'Admin',
				accessToPortal: 'Yes',
				accessToMobile: 'Yes',
				staff_name: userName,
				type: 'Employee' as StaffType,
				branch: '',
				mobile: '',
				email: userEmail,
				position: '',
				street: '',
				street_2: '',
				city: '',
				post_code: '',
				pin: '',
				start_date: '',
				dob: '',
				driver_license: '',
				license_type: '',
				license_class2: '',
				endorsement: '',
				endorsement_complete_date: '',
				endorsement_expiry: '',
				photo_front: '',
				photo_back: '',
				license_assessed_by: '',
				induction_date: '',
				expiry_date: '',
				photo: '',
				hs_assessed_by: '',
				passport_num: '',
				passport_type: '',
				passport_issue: '',
				passport_expiry: '',
				passport_photo: '',
				site_safe_assessed_by: '',
				first_aid_issue: '',
				first_aid_expiry: '',
				first_aid_photo: '',
				firstaid_assessed_by: '',
				cert_num: '',
				cert_issue_date: '',
				cert_expiry_date: '',
				cert_level: '',
				cert_photo: '',
				scaff_cert_assessed_by: '',
				sop_train: '',
				height_training: '',
				height_training_issue: '',
				height_training_expiry: '',
				height_training_assessed_by: '',
				other_photo: '',
				ird_num: '',
				last_drug_test: '',
				last_drug_test_photo: '',
				kiwisaver: '',
				employment_contract: '',
				status: StaffStatus.Active,
				confined_uploaded_by: null,
				confined_file: '',
				confined_date: '',
				confined_expiry_date: '',
				full_name: userName,
				working_at_heights_level: '',
				working_at_heights_refresher_due: '',
				safety_nets_faset_file: '',
				safety_nets_faset_completed: '',
				safety_nets_faset_expires: '',
				fall_arrest_23229: false,
				fall_arrest_23229_refresher_due: '',
				fall_arrest_15757: false,
				fall_arrest_15757_refresher_due: '',
				fall_arrest_file: '',
				emergency_response_fa_26551: false,
				emergency_response_fa_26551_refresher_due: '',
				emergency_response_fa_26552: false,
				emergency_response_fa_26552_refresher_due: '',
				emergency_response_fa_file: '',
				rope_access_equipment_19359: false,
				rope_access_equipment_19359_refresher_due: '',
				rope_access_equipment_19359_file: '',
				elevated_walk_platform: false,
				elevated_walk_platform_refresher_due: '',
				elevated_walk_platform_file: '',
				incline_lifts_training: false,
				incline_lifts_training_date_completed: '',
				incline_lifts_training_file: '',
				forklift_operator_cert: false,
				forklift_operator_cert_refresher_due: '',
				forklift_operator_cert_file: '',
				mobile_crane_3795: false,
				mobile_crane_3795_refresher_due: '',
				other_file: '',
				emergency_contact_name: '',
				emergency_contact_relationship_to: '',
				emergency_contact_number: '',
				emergency_contact_email: ''
			}
			await putRequest(AppRoutes.serverStaffRoutes.createStaff, payload)

			showSuccess('First User Created successful Now Login')
			navigator('/dashboard')
		} catch (error) {
			showError('Something went wrong with the server check the logs')
		}
	}

	return { createUser }
}
