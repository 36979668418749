import { Image, Link, Text, View } from '@react-pdf/renderer'
import * as footer from 'assets/smart_swift_safe.png'
import { stylesFooter } from './styles'

export const Footer = () => {
	return (
		<View style={stylesFooter.footer} fixed>
			<Image style={stylesFooter.logo} src={footer.default} />
			<View>
				<View style={stylesFooter.contact}>
					<Text>
						0800 11 22 39 |{' '}<Link style={stylesFooter.link} src="https://protrade.net.nz/">protrade.net.nz</Link>
					</Text>
				</View>
			</View>
		</View>
	)
}
