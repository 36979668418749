import { el } from '@fullcalendar/core/internal-common'
import {
	CreateFile,
	DateSelect,
	Dropdown,
	Input,
	SideModal,
	Spinner,
	TextArea,
} from 'common'
import { useFormik, Formik } from 'formik'
import moment from 'moment'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { useClients } from 'services/clients'
import { useCreateNotes } from 'services/notes/CreateNotes'
import { useUpdateNotes } from 'services/notes/UpdateNotes'
import { useNoteById } from 'services/notes/getNotesAndFiles'
import { useProjects } from 'services/projects'
import { useStaff } from 'services/staff'
import { OptionsForDropdown } from 'utilities'
/* import * as Yup from 'yup' */

interface IProps {
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
	id: string | number
	type: string
	noteID: string | number
	setNoteID?: any
	setType?: any
	setId?: any
}

interface IInitialValues {
	notes: string
	aditional_notes: string
	fileType: string
	fileDescription: string
	createdDate: string
	fileUrl: string
	fileName: string
	followUpDate: string | null
	isMobileView: 'Yes' | 'No'
	estimatedValue: number
	status: 'Unqualified' | 'Qualified'
}

const yesNoOptions = [
	{ label: 'Yes', value: 'Yes' },
	{ label: 'No', value: 'No' },
]

const statusOptions = [
	{ label: 'Unqualified', value: 'Unqualified' },
	{ label: 'Qualified', value: 'Qualified' },
]

const typeOptions = [
	{ label: 'Project', value: 'project' },
	{ label: 'Client', value: 'client' },
	{ label: 'Personal', value: 'staff' },
]

export const NotesForm = ({
	heading,
	setOpen,
	formType,
	open,
	id,
	type,
	noteID,
	setNoteID,
	setType,
	setId,
}: IProps) => {
	const { UpdateNotes } = useUpdateNotes()
	const { CreateNotes } = useCreateNotes()
	const { data: note_data, isLoading: noteLoading } = useNoteById(noteID)
	const { data: clientsData, isLoading: clientsLoading } = useClients()
	const { data: projectsData, isLoading: projectsLoading } = useProjects()
	const { data: staffData, isLoading: staffLoading } = useStaff()
	const user = useSelector((store: AppStore) => store.user)

	const followUps = ['client', 'project', 'staff']

	const initialValues: IInitialValues = {
		notes: note_data?.notes || '',
		aditional_notes: '',
		fileType: note_data?.fileType || '',
		fileDescription: note_data?.fileDescription || '',
		createdDate: note_data?.createdDate || '',
		fileUrl: note_data?.fileUrl || '',
		fileName: note_data?.fileName || '',
		followUpDate:
			type === 'client'
				? note_data?.followUpDate
					? moment(note_data.followUpDate, 'DD/MM/YYYY').format('DD/MM/YYYY')
					: moment().add(1, 'days').format('DD/MM/YYYY')
				: null,
		isMobileView: type === 'job' ? note_data?.isMobileView || 'Yes' : 'Yes',
		estimatedValue: note_data?.estimatedValue || 0,
		status: note_data?.status || 'Unqualified',
	}
	/* 
	const validationSchema = Yup.object({
		type: Yup.string().required('Type is required'),
		task_ids: Yup.array().required('Required').min(1, 'Task is required'),
		job_id: Yup.string().required('Job is required'),
		team_leader_id: Yup.string().required('Team Leader is required'),
		staff_ids: Yup.array().required('Required').min(1, 'Staff is required'),
	}) */

	const noteTypeOptions = [
		{ label: 'Quoted', value: 'Quote' },
		{ label: 'Job', value: 'Job' },
		{ label: 'Phone Call', value: 'Phone Call' },
		{
			label: 'Email Correspondence',
			value: 'Email Correspondence',
		},
		{ label: 'Meeting', value: 'Meeting' },
		{ label: 'Other', value: 'Other' },
	]

	const filteredNoteTypeOptions =
		type === 'client' || type === 'project'
			? [...noteTypeOptions, { label: 'Lead', value: 'Lead' }]
			: noteTypeOptions

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (!noteID) {
				const payload = {
					notes: values.notes,
					[type + '_id']: id,
					fileType: values.fileType,
					fileDescription: values.fileDescription,
					createdDate: moment().format('DD/MM/YYYY'),
					fileUrl: values.fileUrl,
					fileName: values.fileName,
					followUpDate: values.followUpDate,
					isMobileView: values.isMobileView,
					estimatedValue: values.estimatedValue,
					status: values.status,
				}
				await CreateNotes(payload)
			}
			if (noteID) {
				const additionalNotes = `${moment().format('DD/MM/YYYY')} - ${
					values.aditional_notes
				}`
				const newNotes = `${additionalNotes}\n${values.notes}`
				const payload = {
					notes: values.aditional_notes ? `${newNotes}` : `${values.notes}`,
					[type + '_id']: id,
					fileType: values.fileType,
					fileDescription: values.fileDescription,
					createdDate: values.createdDate,
					fileUrl: values.fileUrl,
					fileName: values.fileName,
					followUpDate: values.followUpDate,
					isMobileView: values.isMobileView,
					estimatedValue: values.estimatedValue,
					status: values.status,
				}
				await UpdateNotes(payload, noteID)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
			if (setNoteID) {
				setNoteID('')
			}
			if (setType) {
				setType('')
			}
			if (setId) {
				setId('')
			}
		},
	})

	useEffect(() => {
		if (!open) {
			// Reset the form when open becomes false
			//   console.log('resetting')
			formik.resetForm()
			if (setNoteID) {
				setNoteID('')
			}
			if (setType) {
				setType('')
			}
			if (setId) {
				setId('')
			}
		}
	}, [open])

	useEffect(() => {
		if (type === 'staff' && !staffLoading && staffData) {
			const userData = staffData.find((staff) => staff.email === user.email)
			if (userData && userData.id) {
				setId(userData?.id)
			}
		}
	}, [type, staffLoading, staffData])

	if (noteLoading || clientsLoading || projectsLoading || staffLoading) {
		return <Spinner />
	}

	if (noteID && Number(noteID) > 0) {
		formType = 'update'
	} else {
		formType = 'create'
	}

	// console.log('values', formik.values)

	const handleDropdownChange = (id: any, val: any) => {
		setType(val)
	}

	const handleIdDropdownChange = (id: any, val: any) => {
		setId(val)
	}

	return (
		<>
			<SideModal
				heading={
					formType === 'create' ? 'Create Notes / File' : 'Edit Notes / File '
				}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="">
					{Boolean(noteID) === false && setType ? (
						<>
							<div className="">
								<Dropdown
									label="Type"
									id="type"
									options={typeOptions}
									value={type}
									onChange={handleDropdownChange}
									onBlur={formik.handleBlur}
								/>
							</div>
							{type === 'client' ? (
								<div className="">
									<Dropdown
										label="Client"
										id="client"
										isLoading={clientsLoading}
										value={id}
										onChange={handleIdDropdownChange}
										onBlur={formik.handleBlur}
										options={OptionsForDropdown(
											clientsData,
											'id',
											'client_name'
										)}
									/>
								</div>
							) : null}
							{type === 'project' ? (
								<div className="">
									<Dropdown
										label="Project"
										id="project"
										isLoading={projectsLoading}
										value={id}
										onChange={handleIdDropdownChange}
										onBlur={formik.handleBlur}
										options={OptionsForDropdown(
											projectsData,
											'id',
											'project_name'
										)}
									/>
								</div>
							) : null}
						</>
					) : null}
					<div className="">
						<Input
							title="Note Title"
							placeholder="Enter a short name"
							id="fileName"
							type="text"
							error={formik.errors.fileName}
							value={formik.values.fileName}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
					<div className="">
						<Dropdown
							label="Note Type"
							id="fileType"
							options={filteredNoteTypeOptions}
							value={formik.values.fileType}
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
						/>
					</div>
					{type === 'job' ? (
						<div className="">
							<Dropdown
								label="Is Mobile View?"
								id="isMobileView"
								options={yesNoOptions}
								value={formik.values.isMobileView}
								onChange={formik.setFieldValue}
								onBlur={formik.handleBlur}
							/>
						</div>
					) : null}
					{formik.values.fileType === 'Lead' ? (
						<div className="">
							<Input
								title="Estimated $ Value"
								placeholder="Enter an estimated $ value"
								id="estimatedValue"
								type="number"
								error={formik.errors.estimatedValue}
								value={formik.values.estimatedValue}
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
							/>
							<Dropdown
								label="Status"
								id="status"
								options={statusOptions}
								value={formik.values.status}
								onChange={formik.setFieldValue}
								onBlur={formik.handleBlur}
							/>
						</div>
					) : null}
					<TextArea
						title="Note"
						id="notes"
						type="text"
						error={formik.errors.notes}
						value={formik.values.notes}
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						disabled={Boolean(noteID)}
					/>
					{Boolean(noteID) ? (
						<TextArea
							title="New Note"
							id="aditional_notes"
							type="text"
							error={formik.errors.aditional_notes}
							value={formik.values.aditional_notes}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					) : null}
					{type !== 'job' && (
						<div className="">
							<Input
								title="File Description"
								placeholder="File Description"
								id="fileDescription"
								type="text"
								error={formik.errors.fileDescription}
								value={formik.values.fileDescription}
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
							/>
						</div>
					)}
					<CreateFile
						field="fileUrl"
						setFieldValue={formik.setFieldValue}
						value={formik.values.fileUrl}
					/>
					{followUps.includes(type) ? (
						<DateSelect
							id="followUpDate"
							title="Follow Up Date"
							onChange={formik.setFieldValue}
							value={formik.values.followUpDate}
							error={formik.errors.followUpDate}
							minDate={moment().add(1, 'days').toString()}
						/>
					) : null}
				</div>
			</SideModal>
		</>
	)
}
