import { useEffect, useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { DataTableServices, JobsServices, QuoteServices } from 'services'
import {
	Container,
	DataTableHeader,
	PageHeading,
	Badge,
	SeeMoreLess,
} from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { Link, useLocation } from 'react-router-dom'
import { PencilIcon, FolderIcon, PlusIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { JobsComponents } from 'components'
import moment from 'moment'
import { PageJobHeading } from 'common/PageJobHeader'

export const JobsMainTable = () => {
	const location = useLocation()
	const { data, isLoading, enableCreateUpdate } = JobsServices.useJobs()
	// const [openJobForm, setOpenJobForm] = useState(false)
	const [openDayWorksJobForm, setOpenDayWorksJobForm] = useState(false)

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		FilterColumn,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'job_status',
				filterInitialValue: 'Invoiced',
				filterInitialMatchMode: FilterMatchMode.NOT_EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Pending Handover',
					'In Progress',
					'Completed',
					'Invoiced',
				],
			},
			{
				filterName: 'status',
				filterInitialValue: 'Active',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Active', 'Inactive'],
			},
			{
				filterName: 'job_type',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [],
			},
			{
				filterName: 'branch',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [],
			},
			{
				filterName: 'protradeSalesPersonData.staff_name',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [],
			},
			{
				filterName: 'on_hire',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Yes', 'No'],
			},
		],
		additionalGlobalFilterFields: [
			'job_num',
			'descriptionOfQuote',
			'clientData.client_name',
			'job_type',
			'site',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	return (
		<div className="card">
			<PageJobHeading
				title="Jobs"
				createBtn="Schedule Visit"
				isEditable={false}
				secondCreateBtn="Create Job"
				secondSetOpen={setOpenDayWorksJobForm}
			/>

			<Container>
				<DataTable
					ref={dataTableReference}
					value={data}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={isLoading}
					dataKey="id"
					sortField="id"
					sortOrder={-1}
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					stripedRows
					emptyMessage="No jobs found.">
					<Column
						field="job_num"
						header="Job #"
						style={{ width: '7rem' }}
						body={(rowData) => (
							<Link
								to={AppRoutes.privateRoutes.JobDetails.replace(
									':id',
									rowData.id
								)}
								className="flex items-center">
								<FolderIcon className="h-4 w-4 mx-2" />
								<># {rowData.job_num}</>
							</Link>
						)}
					/>
					<Column field="clientData.client_name" header="Client" />
					<Column
						field="site"
						header="Site Address"
						style={{ maxWidth: '16rem', textAlign: 'left' }}
					/>
					<Column
						field="descriptionOfQuote"
						header="Description"
						style={{ maxWidth: '20rem', textAlign: 'left' }}
						body={(rowData) => {
							return (
								<SeeMoreLess text={rowData.descriptionOfQuote} maxLength={80} />
							)
						}}
					/>
					<Column
						field="job_type"
						header="Job Type"
						style={{ maxWidth: '150px', textAlign: 'left' }}
						filter
					/>
					<Column
						field="start_date"
						header="Start Date"
						body={(rowData) => (
							<>{moment(new Date(rowData.start_date)).format('DD/MM/YYYY')}</>
						)}
					/>
					<Column field="branch" header="Branch" filter />
					<Column
						filter
						showFilterOperator={false}
						style={{ minWidth: '8rem' }}
						header="Protrade Sales Person"
						field="protradeSalesPersonData.staff_name"
						filterPlaceholder="Search by name"
					/>
					<Column
						field="job_status"
						header="Job Status"
						{...FilterColumn.job_status}
					/>
					<Column field="on_hire" header="On Hire" {...FilterColumn.on_hire} />
					<Column
						field="operation_notes"
						header="Operation Notes"
						style={{ maxWidth: '20rem', textAlign: 'left' }}
						body={(rowData) => {
							if (!rowData.operation_notes) return
							return (
								<SeeMoreLess text={rowData.operation_notes} maxLength={80} />
							)
						}}
					/>
					{/* <Column field="branding" header="Branding" hidden /> */}
					<Column
						field="status"
						header="Status"
						bodyStyle={{ textAlign: 'center' }}
						body={(rowData) => (
							<Badge text={rowData.status} type={rowData.status} />
						)}
						filter
					/>
					{/* <Column
						field="confined_spaces"
						header="Confined Spaces?"
						bodyStyle={{ textAlign: 'center' }}
						body={(rowData) => (
							(rowData.job_type == 'Confined Spaces') ? <Badge text={'Confined Space'} type={'Warning'} /> : <Badge text={'No'} type={'Green'} />
						)}
					/> */}
					{enableCreateUpdate && (
						<Column
							header="Edit"
							body={(rowData) => (
								<Link
									to={{
										pathname: AppRoutes.privateRoutes.JobEdit.replace(
											':id',
											rowData.id
										),
									}}
									state={{ background: location, name: 'editJob' }}>
									<PencilIcon className="text-gray-600 h-4 w-4" />
								</Link>
							)}
						/>
					)}
				</DataTable>
			</Container>
			{/* <JobsComponents.JobForm
				formType="create"
				heading="Create Job"
				open={openJobForm}
				setOpen={setOpenJobForm}
			/> */}
			<JobsComponents.DayWorksForm
				formType="create"
				heading="Create Job"
				open={openDayWorksJobForm}
				setOpen={setOpenDayWorksJobForm}
			/>
		</div>
	)
}
