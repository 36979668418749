import { ConfirmationDialog, Spinner } from 'common'
import { QuoteAcceptLine } from 'components/SimpleQuotes/QuoteAccept'
import { QuoteAcceptAdditionalLine } from 'components/SimpleQuotes/QuoteAccept/QuoteAcceptAdditionalLine'
import { QuoteDetails } from 'components/SimpleQuotes/QuoteAccept/QuoteDetails'
import { QuoteAcceptStatus } from 'models/quote_accept.model'
import {
	QuoteAdditionalLineDTO,
	QuoteLineDTO,
	QuoteLinePhotoDTO,
	SectionedQuoteLineDTO,
} from 'models/quotes.model'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { SimpleQuoteServices } from 'services'
import { numberFormat } from 'utilities'

export const QuoteAcceptancePage = () => {
	const { token } = useParams()
	const [isAccepted, setAccepted] = useState(false)
	const [comments, setComments] = useState('')
	const [orderNumber, setOrderNumber] = useState('')
	const [quote_lines_data, setQuoteLinesData] = useState(
		[] as SectionedQuoteLineDTO[]
	)
	const [quote_additional_lines_data, setQuoteAdditionalLinesData] = useState(
		[] as QuoteAdditionalLineDTO[]
	)

	const { data: quote, isLoading: quote_loading } =
		SimpleQuoteServices.useQuoteByToken(token || '')
	const { data: quote_lines, isLoading: quote_lines_loading } =
		SimpleQuoteServices.useQuoteLinesByToken(token || '')
	const { data: quote_addons, isLoading: quote_addons_loading } =
		SimpleQuoteServices.useQuoteAddonsByToken(token || '')
	const { data: quote_rates, isLoading: quote_rates_loading } =
		SimpleQuoteServices.useQuoteRatesByToken(token || '')
	const { data: quote_files, isLoading: quote_files_loading } =
		SimpleQuoteServices.useQuoteFilesByToken(token || '')
	const { data: quote_zones, isLoading: quote_zones_loading } =
		SimpleQuoteServices.useQuoteZonesByToken(token || '')
	const updateQuoteAcceptStatus =
		SimpleQuoteServices.useUpdateAcceptStatusByToken()

	useEffect(() => {
		if (!quote_lines || !quote_files || !quote) return
		if (quote.quote_output_type === 'Detailed') {
			const uniqueSections = [
				...new Map(
					quote_lines.map((line: { zone_label: string; zone_id: number }) => [
						line.zone_id,
						{
							name: line.zone_label,
							number: line.zone_id,
						},
					])
				).values(),
			]

			const sectioned_quote_lines = uniqueSections.map((section: any) => {
				const filteredLines = quote_lines.filter(
					(line: { zone_label: string }) => line.zone_label === section.name
				)

				const lines = filteredLines.map((line: any) => {
					return {
						sectionNumber: line.zone_id,
						sectionName: line.zone_label,
						itemDescription: line.description,
						quantity: line.quantity,
						price: line.total,
						weekly_hire: line.weekly_hire_fee,
						hire_period: line.hire_period,
						accepted: false,
					}
				})

				const images = quote_files
					.filter((file: any) => file.section === section.number)
					.map((file: any) => {
						const image = {
							imageUrl: file.url,
							includeInQuote: file.include_in_quote,
							imageDescription: file.description,
						} as QuoteLinePhotoDTO

						return image
					}) as QuoteLinePhotoDTO[]

				return {
					name: section.name,
					number: section.number,
					lines,
					images,
				}
			})
			setQuoteLinesData(sectioned_quote_lines)
		}
	}, [quote_lines, quote_files, quote])

	useEffect(() => {
		if (!quote_addons || !quote) return
		if (quote.quote_output_type === 'Detailed') {
			const quote_addon_display = quote_addons.map((line: any) => {
				return {
					itemDescription: line.description,
					quantity: line.duration_quantity,
					price: line.total_cost,
					accepted: false,
					type: line.type,
					sectionName: line.zone_label,
				} as QuoteAdditionalLineDTO
			})
			setQuoteAdditionalLinesData(quote_addon_display)
		}
	}, [quote_addons, quote])

	useEffect(() => {
		if (!quote_lines || !quote_files || !quote_addons || !quote) return
		if (quote.quote_output_type === 'Hire Inclusive') {
			const quote_addon_display = quote_addons.map((addon: any) => ({
				itemDescription: addon.description,
				quantity: addon.duration_quantity,
				price: addon.total_cost,
				accepted: false,
				type: addon.type,
				sectionName: addon.zone_label,
			})) as QuoteAdditionalLineDTO[]

			const uniqueSections = [
				...new Map(
					quote_lines.map((line: { zone_label: string; zone_id: number }) => [
						line.zone_id,
						{
							name: line.zone_label,
							number: line.zone_id,
						},
					])
				).values(),
			]

			const deliveryQuoteAddons = quote_addon_display.filter((addon) =>
				uniqueSections.some(
					(section: any) => section.name === addon.sectionName
				)
			)

			const quote_stages = uniqueSections.map((section: any) => {
				const filteredLines = quote_lines.filter(
					(line: { zone_label: string }) => line.zone_label === section.name
				)

				const images = quote_files
					.filter((file: any) => file.section === section.number)
					.map((file: any) => {
						const image = {
							imageUrl: file.url,
							includeInQuote: file.include_in_quote,
							imageDescription: file.description,
						} as QuoteLinePhotoDTO

						return image
					}) as QuoteLinePhotoDTO[]

				const quote_line_display = filteredLines.map((line: any) => {
					return {
						sectionNumber: line.zone_id,
						sectionName: line.zone_label,
						itemDescription: line.description,
						quantity: line.quantity,
						price: line.total,
						weekly_hire: line.weekly_hire_fee,
						accepted: false,
						hire_period: line.hire_period,
					}
				})

				const stage_total = quote_line_display.reduce((acc: any, line: any) => {
					const line_total =
						Number(line.price) - line.weekly_hire * (line.hire_period || 1)
					return acc + line_total
				}, 0)

				const total_hire_fee = quote_line_display.reduce(
					(acc: any, line: any) => {
						const line_total = line.weekly_hire * (line.hire_period || 1)
						return acc + line_total
					},
					0
				)

				let addonPrice = 0
				const matchingAddon = deliveryQuoteAddons.filter(
					(addon) => addon.sectionName === section.name
				)
				if (matchingAddon) {
					matchingAddon.forEach((addon) => {
						addonPrice += Number(addon.price)
					})
				}

				const hire_period = quote_line_display[0]?.hire_period || 1
				const sectionNumber = quote_line_display[0]?.sectionNumber!
				const weekly_hire = total_hire_fee / hire_period

				const lines = {
					sectionNumber,
					sectionName: section.name,
					itemDescription: `Total Labour, Services, and Delivery incl. ${hire_period} weeks hire`,
					images,
					quantity: 1,
					price: stage_total + total_hire_fee + addonPrice,
					weekly_hire,
					accepted: false,
					hire_period,
					isInclusive: true,
				}

				return {
					name: section.name,
					number: sectionNumber,
					lines: new Array(lines),
					images,
				}
			})

			setQuoteLinesData(quote_stages)

			const remainingQuoteAddons = quote_addon_display.filter(
				(addon) =>
					!uniqueSections.some(
						(section: any) => section.name === addon.sectionName
					)
			)
			setQuoteAdditionalLinesData(remainingQuoteAddons)
		}
	}, [quote_lines, quote_files, quote_addons, quote])

	if (
		quote_loading ||
		quote_lines_loading ||
		quote_addons_loading ||
		quote_rates_loading ||
		quote_files_loading ||
		quote_zones_loading
	) {
		return <Spinner />
	}
	if (!quote) return <h1>Invalid Link</h1>
	// console.log(quote)
	const handleAcceptance = () => {
		if (!token) return
		if (!isAccepted) return
		updateQuoteAcceptStatus.updateAcceptStatusByToken(
			{
				status: QuoteAcceptStatus.ACCEPTED,
				comments: comments,
				purchase_order: orderNumber,
			},
			token
		)
	}

	const handleQuery = () => {
		console.log('refer')
		if (!token) return
		updateQuoteAcceptStatus.updateAcceptStatusByToken(
			{
				status: QuoteAcceptStatus.REFERRED,
				comments: comments,
				purchase_order: orderNumber,
			},
			token
		)
	}

	const handleDecline = () => {
		if (!token) return
		updateQuoteAcceptStatus.updateAcceptStatusByToken(
			{
				status: QuoteAcceptStatus.DECLINED,
				comments: comments,
				purchase_order: orderNumber,
			},
			token
		)
	}

	const subTotalLines = quote_lines_data.reduce((acc, currArray) => {
		const currentSubTotal = currArray.lines.reduce((sum, line) => {
			const linePrice =
				quote.quote_output_type === 'Detailed'
					? Number(line.price) +
					  Number(line.weekly_hire) * Number(line.hire_period || 0)
					: Number(line.price)

			return sum + linePrice
		}, 0)

		return acc + currentSubTotal
	}, 0)

	const subTotalAdditionals = quote_additional_lines_data.reduce(
		(acc, line) => acc + Number(line.price),
		0
	)

	const subTotal = subTotalLines + subTotalAdditionals
	const subTotalGST = subTotal * 0.15
	const total = subTotal + subTotalGST

	// console.log('Quote_Lines', quote_lines)
	// console.log('Quote_Rates', quote_rates)
	// console.log('Quote_Files', quote_files)
	// console.log('Quote_Addons', quote_addons)
	// console.log('Quote_Zones', quote_zones)

	if (!quote_lines_data || !quote_additional_lines_data) return <Spinner />
	// console.log(quote_additional_lines_data)
	// console.log("Q", quote)

	return (
		<div className="quote-acceptance flex flex-col min-h-screen justify-between items-center p-4 sm:w-full">
			<div className="sm:w-4/5 lg:w-3/5">
				<QuoteDetails quote={quote} />

				<div className="quote-items mt-6">
					<h2 className="text-2xl font-semibold text-left mb-4">Quote Items</h2>
					{quote_lines_data?.map(
						(section: SectionedQuoteLineDTO, index: number) => (
							<QuoteAcceptLine
								key={`line-${index}`}
								section={section}
								index={index}
							/>
						)
					)}
				</div>
				<div className="quote-additional-items mt-6">
					<h2 className="text-2xl font-semibold text-left mb-4">
						Additional Items
					</h2>
					{quote_additional_lines_data?.map(
						(line: QuoteAdditionalLineDTO, index: number) => (
							<QuoteAcceptAdditionalLine
								key={`addon-${index}`}
								line={line}
								index={index}
							/>
						)
					)}
				</div>

				<div className="quote-totals mt-6">
					<h2 className="text-2xl font-semibold text-left mb-4">Totals</h2>
					<div className="border border-gray-300 p-4 my-4 rounded-lg grid grid-cols-1 sm:grid-cols-3 gap-4">
						{/* Left Column */}
						<div className="col-span-1 sm:col-span-2"></div>
						{/* Right Column */}
						<div className="col-span-1">
							<table className="w-full">
								<thead>
									<tr className="border border-gray-300">
										<td className="text-left border w-40">Sub Total</td>
									</tr>
								</thead>
								<tbody>
									<tr className="border border-gray-300">
										<td className="text-right font-semibold w-40 px-4">
											{numberFormat.format(subTotal)}
										</td>
									</tr>
								</tbody>
							</table>
							<table className="w-full">
								<thead>
									<tr className="border border-gray-300">
										<td className="text-left border w-40">GST</td>
									</tr>
								</thead>
								<tbody>
									<tr className="border border-gray-300">
										<td className="text-right font-semibold w-40 px-4">
											{numberFormat.format(subTotalGST)}
										</td>
									</tr>
								</tbody>
							</table>
							<table className="w-full">
								<thead>
									<tr className="border border-gray-300">
										<td className="text-left pl-2 border w-40">Total</td>
									</tr>
								</thead>
								<tbody>
									<tr className="border border-gray-300">
										<td className="text-right font-semibold w-40 px-4">
											{numberFormat.format(total)}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>

			<div className="w-4/5 lg:w-3/5 p-4">
				<div className="terms-section font-bold">
					{/* <p className="whitespace-pre-line font-medium">
						*Hire charges start on the date on which the erection of the
						scaffolding is completed and continue until the date of dismantling
						of the scaffolding as advised by the client (off-hire date). Both
						dates will be charged as whole days.
					</p>
					<br/>
					<p className="whitespace-pre-line font-light">
						Alterations or extra work will be charged at $69.70 plus
						GST/hour/man plus hire charges for additional scaffolding material.
						Extra work includes but is not limited to all modifications, moves,
						repairs, partly dismantling or adjustments of the scaffolding.
					</p>
					<br /> */}
					<h2>Additional Conditions</h2>
					<p className="whitespace-pre-line font-medium">{quote.terms}</p>
					<p className="font-medium mt-4">Kind Regards,</p>
					<p className="font-medium italic">{quote.estimatorData.staff_name}</p>
					<p className="font-medium italic">Pricing Estimator</p>
					<p className="font-medium">Mobile: {quote.estimatorData.mobile}</p>
					<p className="font-medium">Email: {quote.estimatorData.email}</p>
				</div>

				{quote.emailStatus != 'Customer Accepted' &&
					quote.emailStatus != 'Customer Declined' &&
					quote.emailStatus != 'Customer Queried' && (
						<div className="accept-section mt-6 mb-5">
							<div className="space-y-4">
								<div>
									<label>Comments</label>
									<textarea
										placeholder="Enter any comments"
										value={comments}
										onChange={(e) => setComments(e.target.value)}
										className="w-full p-2 border rounded"
									/>
								</div>
								<div>
									<label>Purchase Order</label>
									<input
										type="text"
										placeholder="Enter a purchase Order Number"
										value={orderNumber}
										onChange={(e) => setOrderNumber(e.target.value)}
										className="w-full p-2 border rounded  focus:outline-2 focus:outline-black"
									/>
								</div>
								<div className="flex items-center">
									<input
										type="checkbox"
										checked={isAccepted}
										onChange={() => setAccepted(!isAccepted)}
										className="mr-2  focus:outline-2 focus:outline-black"
									/>
									<label>
										I{' '}
										<span className="font-bold italic">
											{quote.contactData.name}
										</span>{' '}
										hereby agree to the terms of above quote as described on{' '}
										<span className="font-bold italic">
											{new Date().toLocaleString('en-NZ', {
												timeZone: 'Pacific/Auckland',
												dateStyle: 'long',
												timeStyle: 'short',
											})}
										</span>
									</label>
								</div>
								<div className="flex space-x-2">
									<a
										href="https://protrade.net.nz/wp-content/uploads/2023/03/ProtradeTermsConditions.pdf"
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-500">
										View Terms and Conditions (PDF)
									</a>
								</div>
								<div className="flex space-x-2">
									<ConfirmationDialog
										icon="info"
										title="Confirmation - Accept"
										body={`Accepting quote "${quote.quote_num}"`}
										triggerButton={
											<button
												disabled={!isAccepted}
												className="w-full py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-gray-300 disabled:transition-all disabled:text-black disabled:italic focus:outline-2 focus:outline-black">
												Accept Quote
											</button>
										}
										confirmButton={
											<Button onClick={async () => handleAcceptance()}>
												Confirm
											</Button>
										}
									/>

									<ConfirmationDialog
										icon="info"
										title="Confirmation - Query quote"
										body={`Queryring quote "${quote.quote_num}"`}
										triggerButton={
											<button className="w-full py-2 bg-orange-500 text-white rounded hover:bg-orange-600 disabled:bg-gray-300 focus:outline-2 focus:outline-black">
												Query Quote
											</button>
										}
										confirmButton={
											<Button onClick={async () => handleQuery()}>
												Confirm
											</Button>
										}
									/>
									<ConfirmationDialog
										icon="info"
										title="Confirmation - Decline"
										body={`Declining quote "${quote.quote_num}"`}
										triggerButton={
											<button className="w-full py-2 bg-red-500 text-white rounded hover:bg-red-600 disabled:bg-gray-300 focus:outline-2 focus:outline-black">
												Decline Quote
											</button>
										}
										confirmButton={
											<Button onClick={async () => handleDecline()}>
												Confirm
											</Button>
										}
									/>
								</div>
							</div>
						</div>
					)}
			</div>
		</div>
	)
}

export default QuoteAcceptancePage
