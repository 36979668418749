import React, { useEffect, useState } from 'react'
import Autocomplete, { ReactGoogleAutocompleteInputProps } from 'react-google-autocomplete'
import { AppConfig } from 'config'
import { MapIcon } from '@heroicons/react/24/outline'
import { IQuoteForm } from 'models/simple_quotes.model'
import { FormikProps } from 'formik'

interface AddressProps {
	streetId2: string
	streetId: string
	countryId: string
	cityId: string
	postalId: string
	street2Val: string
	cityVal: string
	postalVal: string
	countryVal: string
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
	setFieldValue: (field: string, value: string) => void
	branch: string
	displayDistance?: boolean
	main_origin?: string
}

interface Branches {
	[key: string]: string
}

const branches: Branches = {
	'Hawkes Bay': '609 Orchard Road, Camberley, Hastings 4120, New Zealand',
	Gisborne: '213A Stanley Road, Awapuni, Gisborne 4010, New Zealand',
	Wairarapa: '20 Ahumahi Road, Waingawa 5791, New Zealand',
	Taupo: '257 Tauhara Road, Tauhara, Taupō 3330, New Zealand',
	Dunedin: '56 Teviot Street, South Dunedin, Dunedin 9012, New Zealand',
	Cromwell: '19 McNulty Road, Cromwell 9310, New Zealand',
}

export function SearchAddress({
	streetId2,
	streetId,
	cityId,
	postalId,
	countryId,
	street2Val,
	cityVal,
	postalVal,
	countryVal,
	handleChange,
	handleBlur,
	setFieldValue,
	displayDistance = false,
	main_origin,
	branch,
}: AddressProps) {
	const [selectedPlace, setSelectedPlace] = useState<any | null>(null)

	useEffect(() => {
		if (selectedPlace && branch) {
			handlePlaceSelect(selectedPlace, branch)
		}
	}, [selectedPlace, branch])

	const getAddress = (branchT: string): string => {
		const address = branches[branchT]
		if (address) {
			return address
		} else {
			throw new Error(`Branch not found: ${branchT}`)
		}
	}

	const makeHttpRequest = (
		url: string,
		method: string,
		requestData: any,
		headers: any
	) => {
		return new Promise<any>((resolve, reject) => {
			const xhr = new XMLHttpRequest()
			xhr.open(method, `${url}`, true)
			Object.keys(headers).forEach((key) => {
				xhr.setRequestHeader(key, headers[key])
			})
			xhr.onreadystatechange = () => {
				if (xhr.readyState === XMLHttpRequest.DONE) {
					if (xhr.status === 200) {
						resolve(JSON.parse(xhr.responseText))
					} else {
						reject(xhr.statusText)
					}
				}
			}
			xhr.onerror = () => reject(xhr.statusText)
			xhr.send(JSON.stringify(requestData))
		})
	}

	const googleApiKey = AppConfig.GoogleMapsApiKey
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handlePlaceSelect = async (place: any, selectedBranch: string) => {
		const addressComponents = place.address_components
		if (displayDistance) {
			
			const origin = getAddress(selectedBranch)
			
			const destination = place.formatted_address

			const url = `https://routes.googleapis.com/directions/v2:computeRoutes`
			const requestData = {
				origin: {
					address: origin,
				},
				destination: {
					address: destination,
				},
				travelMode: 'DRIVE',
				units: 'METRIC',
			}

			const headers = {
				'Content-Type': 'application/json',
				'X-Goog-Api-Key': googleApiKey,
				'X-Goog-FieldMask':
					'routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline',
			}

			try {
				const response = await makeHttpRequest(url, 'POST', requestData, headers);

				const mainOrigin = (response.routes[0].distanceMeters / 1000).toFixed(1)

				setFieldValue('main_origin', mainOrigin)
			} catch (error) {
				console.error(error)
			}
		}

		let street2 = ''
		let city = ''
		let postalCode = ''
		let country = ''
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		addressComponents.forEach((component: any) => {
			const { types } = component

			if (types.includes('street_number')) {
				if (street2 === '') {
					street2 = component.long_name
				} else {
					street2 += ` ${component.long_name}`
				}
			}

			if (types.includes('route')) {
				if (street2 === '') {
					street2 = component.long_name
				} else {
					street2 += ` ${component.long_name}`
				}
			}

			if (types.includes('sublocality')) {
				if (street2 === '') {
					street2 = component.long_name
				} else {
					street2 += `, ${component.long_name}`
				}
			}

			if (types.includes('locality')) {
				if (city === '') {
					city = component.long_name
				} else {
					city += `, ${component.long_name}`
				}
				cityVal = city
			}
			if (types.includes('country')) {
				if (country === '') {
					country = component.long_name
				} else {
					country += `, ${component.long_name}`
				}
				countryVal = country
			}

			// if (types.includes('administrative_area_level_1')) {
			// 	if (city === '') {
			// 		city = component.long_name
			// 	} else {
			// 		city += `, ${component.long_name}`
			// 	}
			// }

			if (types.includes('postal_code')) {
				postalCode = component.long_name
			}
		})

		setFieldValue('street2', street2)
		setFieldValue('street', place.formatted_address)
		setFieldValue('postal', postalCode)
		setFieldValue('city', city)
		setFieldValue('country', country)
	}

	return (
		<div className="px-2 py-4 mt-10 sm:mt-0">
			<div className="md:grid md:grid-cols-6 md:gap-6">
				<div className="col-span-6">
					<Autocomplete
						key={branch}
						apiKey={googleApiKey}
						placeholder=""
						style={{ width: '100%' }}
						className="autocomplete border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onPlaceSelected={(place) => {
							setSelectedPlace(place)
						}}
						options={{
							types: ['address'],
							componentRestrictions: { country: 'nz' },
						}}
					/>
				</div>
				<div className="col-span-6">
					{displayDistance ? (
						<div>
							<div className="flex gap-1 mb-2">
								<MapIcon className="h-5 w-5" aria-hidden="true" />
								<label
									htmlFor={streetId2}
									className="block text-sm font-medium text-gray-700">
									Distance to Office
								</label>
							</div>

							<input
								type="text"
								name={streetId2}
								id={streetId2}
								autoComplete="street-address"
								className='border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3'
								onChange={handleChange}
								onBlur={handleBlur}
								value={main_origin ? `${main_origin} KM` : ''}
								disabled
							/>
						</div>
					) : null}
				</div>

				<div className="col-span-3">
					<label
						htmlFor={streetId2}
						className="block text-sm font-medium text-gray-700">
						Address
					</label>
					<input
						type="text"
						name={streetId2}
						id={streetId2}
						autoComplete="street-address"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							setFieldValue(streetId2, e.target.value)
							setFieldValue(
								streetId,
								`${e.target.value}, ${cityVal} ${postalVal}, ${countryVal}`
							)
						}}
						onBlur={handleBlur}
						value={street2Val}
					/>
				</div>

				<div className="col-span-6 sm:col-span-6 lg:col-span-4">
					<label
						htmlFor="city"
						className="block text-sm font-medium text-gray-700">
						City
					</label>
					<input
						type="text"
						name={cityId}
						id={cityId}
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							setFieldValue(cityId, e.target.value)
							setFieldValue(
								streetId,
								`${street2Val}, ${e.target.value} ${postalVal}, ${countryVal}`
							)
						}}
						onBlur={handleBlur}
						value={cityVal}
					/>
				</div>
				<div className="col-span-6 sm:col-span-2 lg:col-span-2">
					<label
						htmlFor="postal-code"
						className="block text-sm font-medium text-gray-700">
						Postal Code
					</label>
					<input
						type="text"
						name={postalId}
						id={postalId}
						autoComplete="postal-code"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							setFieldValue(postalId, e.target.value)
							setFieldValue(
								streetId,
								`${street2Val}, ${cityVal} ${e.target.value}, ${countryVal}`
							)
						}}
						onBlur={handleBlur}
						value={postalVal}
					/>
				</div>
			</div>
		</div>
	)
}
