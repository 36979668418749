import { IRates, IRatesHandlers, item_codes_options } from 'models'
import { Button, ConfirmationDialog, Dropdown } from 'common'
import { TrashIcon } from '@heroicons/react/24/solid'
import { SimpleQuoteServices } from 'services'
import { useEffect, useState } from 'react'
import Select from 'react-select'

interface RatesFormProps {
	rates: IRates[]
	ratesHandlers: IRatesHandlers
	admin: boolean
}

export const RatesForm = ({ rates, ratesHandlers, admin }: RatesFormProps) => {
	const { deleteServiceRateById } =
		SimpleQuoteServices.useDeleteServiceRateById()

	const handleDelete = async (index: number, id: number) => {
		if (id) {
			deleteServiceRateById(id)
		}
		ratesHandlers.removeRate(index)
	}

	return (
		<>
			<div className="w-full">
				<table className="w-full divide-y divide-gray-200">
					<thead className="bg-gray-100">
						<tr>
							<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
								Service
							</th>
							<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
								Fee
							</th>
							{admin ? (
								<>
									<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
										Item Code
									</th>
									<th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider text-2xs">
										Delete
									</th>
								</>
							) : null}
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">
						{rates?.map((rate, index) => (
							<tr key={index}>
								<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
									<input
										id={`service${rate?.id}`}
										type="text"
										value={rate?.service}
										className="h-7 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none px-2"
										name="service"
										onChange={(e) =>
											ratesHandlers.updateRate(index, 'service', e.target.value)
										}
									/>
								</td>
								<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
									<input
										id={`fee${rate?.id}`}
										type="number"
										value={rate?.fee}
										className="h-7 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none px-2"
										name="fee"
										onChange={(e) =>
											ratesHandlers.updateRate(index, 'fee', e.target.value)
										}
									/>
								</td>
								{admin ? (
									<>
										<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
											<Select
												id={`item-code${rate?.id}`}
												options={item_codes_options}
												onChange={(selectedOption) =>
													ratesHandlers.updateRate(
														index,
														'item_code',
														selectedOption?.value!
													)
												}
												value={
													rate?.item_code
														? { value: rate.item_code, label: rate.item_code }
														: null
												}
											/>
										</td>
										<td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
											<ConfirmationDialog
												icon="danger"
												title={`Delete ${rate?.service}`}
												body=" Are you sure you want to delete this rate? This action is unrecoverable!"
												triggerButton={
													<button type="button">
														<TrashIcon className="h-4 w-4 inline-flex items-center text-gray-500" />
													</button>
												}
												confirmButton={
													<Button
														size="sm"
														variant="danger"
														onClick={async () =>
															handleDelete(index, rate?.id!)
														}>
														Delete
													</Button>
												}
											/>
										</td>
									</>
								) : null}
							</tr>
						))}
					</tbody>
				</table>
				<br />
				<Button
					size="sm"
					variant="primary"
					className="w-full"
					onClick={() => ratesHandlers.addNewRate()}>
					Add new rate
				</Button>
			</div>
		</>
	)
}
