import { useMemo, useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import {
	DataTableServices,
	VisitServices,
	StaffServices,
	VehicleServices,
	JobsServices,
} from 'services'
import { Container, DataTableHeader, PageHeading } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'

import { VisitForm } from 'components/Visits'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from 'config'
import { PencilIcon } from '@heroicons/react/24/solid'

export const VisitsMainTable = () => {
	const location = useLocation()
	const [openVisitForm, setOpenVisitForm] = useState(false)
	const { data, isLoading } = VisitServices.useVisits()
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()
	const { data: vehicleData, isLoading: vehicleLoading } =
		VehicleServices.useVehicles()
	const { data: taskData, isLoading: taskLoading } = JobsServices.useTask()

	const preprocessedData = useMemo(() => {
		if (!data || !staffData || !vehicleData || !taskData) return []

		return data?.map((visit: any) => {
			const staff_list = visit.staff_ids
				.map((staff_id: any) => {
					if (!staffData || Number(staff_id) === Number(visit.team_leader_id))
						return null
					const staff = staffData.find(
						(item) => Number(item.id) === Number(staff_id)
					)
					return staff?.staff_name || ''
				})
				.filter((name: any) => name)
				.join(', ')

			const vehicle_list = visit.vehicle_ids
				.map((vehicle_id: any) => {
					if (!vehicleData) return null
					const vehicle = vehicleData.find(
						(item: any) => Number(item.id) === Number(vehicle_id)
					)
					return vehicle?.Rego || ''
				})
				.filter((reg: any) => reg)
				.join(', ')

			const task_list = visit.task_ids
				.map((task_id: any) => {
					if (!taskData) return null
					const task = taskData.find(
						(item: any) => Number(item.id) === Number(task_id)
					)
					return task ? `${task.zone_label} - ${task.type}` : ''
				})
				.filter((task: any) => task)
				.join(', ')

			return {
				...visit,
				staff_list,
				vehicle_list,
				task_list,
				branch: visit.jobData.branch,
				job_type: visit.jobData.job_type,
			}
		})
	}, [data, staffData, vehicleData, taskData])

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'status',
				filterInitialValue: 'Active',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Active', 'Inactive'],
			},
			{
				filterName: 'visit_status',
				filterInitialValue: '',
				filterInitialMatchMode: FilterMatchMode.NOT_EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Pending Prestart',
					'Pending Close Of Visit',
					'Completed',
				],
			},
			{
				filterName: 'branch',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Hawkes Bay',
					'Gisborne',
					'Wairarapa',
					'Taupo',
					'Dunedin',
					'Cromwell',
				],
			},
		],
		additionalGlobalFilterFields: [
			'jobData.job_num',
			'teamLeaderData.staff_name',
			'jobData.site',
			'notes',
			'visit_status',
			'type',
			'staff_list',
			'vehicle_list',
			'task_list',
			'jobData.clientData.client_name',
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
		customFilters: [
			{
				field: 'branch',
				value: 'Hawkes Bay',
				color: 'success',
			},
			{
				field: 'branch',
				value: 'Gisborne',
				color: 'danger',
			},
			{
				field: 'branch',
				value: 'Wairarapa',
				color: 'warning',
			},
			{
				field: 'branch',
				value: 'Taupo',
				color: 'success',
			},
			{
				field: 'branch',
				value: 'Dunedin',
				color: 'warning',
			},
			{
				field: 'branch',
				value: 'Cromwell',
				color: 'danger',
			},
		],
	})
	console.log({ preprocessedData })

	return (
		<div className="card">
			<PageHeading
				title="Visit Scheduler"
				createBtn="Schedule Visit"
				isEditable={false}
				setOpen={setOpenVisitForm}
			/>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={preprocessedData}
					loading={isLoading || staffLoading || vehicleLoading || taskLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					dataKey="id"
					sortField="visitDate"
					rowGroupHeaderTemplate={subHeader}
					sortOrder={-1}
					rowGroupMode="subheader"
					groupRowsBy="date"
					filters={filters}
					globalFilterFields={globalFilterFields}
					stripedRows
					header={header}
					emptyMessage="No Visit Scheduler found.">
					<Column
						field="date"
						header="Date"
						body={(rowData: { date: string; job_id: string }) => {
							return (
								<Link
									to={AppRoutes.privateRoutes.JobDetails.replace(
										':id',
										rowData.job_id
									)}>
									{rowData.date}
								</Link>
							)
						}}
					/>
					<Column field="jobData.job_num" header="Job Number" />
					<Column field="jobData.clientData.client_name" header="Client" />
					<Column field="jobData.site" header="Site" />
					<Column field="teamLeaderData.staff_name" header="Team Leader" />
					<Column
						field="staff_list"
						header="Staff"
						body={(row) => {
							const staff = row.staff_list.split(', ').map((staff: any) => (
								<span>
									{staff}
									<br />
								</span>
							))
							return staff
						}}
					/>
					<Column field="actual_time_on" header="Actual Start Time" />
					<Column field="time_on" header="Start Time" />
					<Column field="actual_time_off" header="Actual End Time" />
					<Column field="time_off" header="End Time" />
					<Column
						field="vehicle_list"
						header="Vehicles"
						body={(row) => {
							const vehicles = row.vehicle_list
								.split(', ')
								.map((vehicle: any) => (
									<span>
										{vehicle}
										<br />
									</span>
								))
							return vehicles
						}}
					/>
					<Column
						field="task_list"
						header="Tasks"
						body={(row) => {
							const tasks = row.task_list.split(', ').map((task: any) => (
								<span>
									{task}
									<br />
								</span>
							))
							return tasks
						}}
					/>
					<Column field="branch" header="Branch" {...FilterColumn.branch} />
					<Column field="notes" header="Notes" />
					<Column field="type" header="Type" />
					<Column field="job_type" header="Job Type" />
					<Column
						field="visit_status"
						header="Visit Status"
						{...FilterColumn.visit_status}
					/>
					<Column field="status" header="Status" {...FilterColumn.status} />
					<Column
						header="Edit"
						body={(rowData) => (
							<Link
								to={{
									pathname: AppRoutes.privateRoutes.visitsEdit.replace(
										':id',
										rowData.id
									),
								}}
								state={{ background: location, name: 'editVisit' }}>
								<PencilIcon className="text-gray-600 h-4 w-4" />
							</Link>
						)}
					/>
				</DataTable>
			</Container>
			<VisitForm
				heading="Schedule Visit"
				setOpen={setOpenVisitForm}
				formType="create"
				open={openVisitForm}
			/>
		</div>
	)
}

const subHeader = (data: { date: string }) => (
	<span className="text-gray-900 font-bold">{data.date}</span>
)
