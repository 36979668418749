import { CheckIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { Input, TextArea, Button, ConfirmationDialog } from 'common'
import { AppStore } from 'redux/store'
import { useSelector } from 'react-redux'
import { useUpdateQuoteStatus } from 'services/quotes/updateQuoteStatus'
import { QuoteLineDTO } from 'models'
import * as logo from 'assets/logo.png'
interface Props {
	quote: any
}

export const QuoteDetails = ({ quote }: Props) => {
	const [quote_date, setQuoteDate] = useState<Date>()
	const [quote_expiry, setQuoteExpiry] = useState<Date>()

	useEffect(() => {
		const quote_date = new Date(quote.createdAt)
		const quote_expiry = new Date()
		quote_expiry.setDate(quote_date.getDate() + 90)
		setQuoteDate(quote_date)
		setQuoteExpiry(quote_expiry)
	}, [quote])

	return (
		<div>
			<div className="m-20">
				<img className="mx-auto h-24 w-auto" src={logo.default} alt="Logo" />
			</div>
			<div className="details-section p-4 border rounded border-gray-300">
				<h2 className="text-xl font-semibold mb-4">Quote Details</h2>
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
					<div>
						<p className="text-gray-700 mb-2">Prepared for</p>
						<p className="font-semibold">{quote.contactData.name}</p>
						<p className="text-sm text-gray-500">
							{quote.clientData.client_name}
						</p>
					</div>
					<div>
						<p className="text-gray-700 mb-2">Prepared By</p>
						<p className="font-semibold">{quote.estimatorData.staff_name}</p>
					</div>
				</div>
				<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
					<div>
						<p className="text-gray-700 mb-2">Quote Number</p>
						<p className="font-semibold">{quote.quote_num}</p>
					</div>
					<div>
						<p className="text-gray-700 mb-2">Site Address</p>
						<p className="font-semibold">{quote.street}</p>
					</div>
					<div>
						<p className="text-gray-700 mb-2">Date Created</p>
						<p className="font-semibold">
							{quote_date?.toLocaleDateString('en-NZ')}
						</p>
					</div>
					<div>
						<p className="text-gray-700 mb-2">Valid Until</p>
						<p className="font-semibold">
							{quote_expiry?.toLocaleDateString('en-NZ')}
						</p>
					</div>
				</div>
				<div className="grid gap-4 mt-4">
					<p className="text-gray-700">Description</p>
					{quote.scope_of_work.split('\n').map((item: string) => (
						<div key={item} className="font-semibold">
							{item}
						</div>
					))}
				</div>
				<div className="grid gap-4 mt-4">
					<p>
						Thank you for the opportunity to provide this quotation as detailed.
						Please note, all prices quoted are exclusive of GST and are valid
						for acceptance and commencement of work for 90 days from the date of
						the quote.
					</p>
				</div>
				{(quote.emailStatus == 'Customer Accepted' ||
					quote.emailStatus == 'Customer Declined' ||
					quote.emailStatus == 'Customer Queried') && (
					<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
						<div>
							<p className="text-gray-700 mb-2">Quote Status</p>
							<p className="font-semibold">{quote.emailStatus}</p>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
