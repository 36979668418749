import { Text, View } from '@react-pdf/renderer'

import { styles } from './styles'
import { QuoteData } from 'models'
import moment from 'moment'

interface GreetingsProps {
	quote: QuoteData
}

export const Greetings = ({ quote }: GreetingsProps) => {
	const contactName = quote.contactData.name
	const contactEmail = quote.contactData.email
	const estimatorName = quote.estimatorData.staff_name
	const validity = moment(quote.created_at).add(90, 'days').format('DD/MM/YYYY')

	return (
		<View style={styles.headerContainer}>
			<View style={styles.heading}>
				<View style={styles.headingDisplay}>
					<Text style={styles.headingText}>Attention: </Text>
					<Text style={styles.subText}>{contactName}</Text>
				</View>
				<View style={styles.headingDisplay}>
					<Text style={styles.headingText}>Email: </Text>
					<Text style={styles.subText}>{contactEmail}</Text>
				</View>
				<View style={styles.headingDisplay}>
					<Text style={styles.headingText}>Quote By: </Text>
					<Text style={styles.subText}>{estimatorName}</Text>
				</View>
				<Text style={styles.body}>
					Thank you for the opportunity to provide this quotation as detailed.
					Please note, all prices quoted are exclusive of GST and are valid for
					acceptance and commencement of work for 90 days from the date of the
					quote.
				</Text>
				<View style={styles.headingDisplay}>
					<Text style={styles.headingText}>Valid To: </Text>
					<Text style={styles.subText}>{validity}</Text>
				</View>
			</View>
		</View>
	)
}
