import React from 'react'
import { DateSelect, CreateFile } from 'common'
import { IStaffRow } from 'models/staff.model'

type OtherProps = {
	values: IStaffRow
	setFieldValue: (
		field: string,
		value: string | number | boolean | Date
	) => void
}

export const Other: React.FC<OtherProps> = ({
	values,
	setFieldValue,
}) => {
  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
		const isChecked = e.target.checked;
		setFieldValue(field, isChecked);
	};

	return (
		<div>
			<h3 className="px-4 py-2 text-lg font-semibold leading-5 ">
				Other
			</h3>
			<div>
				<div className="flex items-center px-2">
          <label
            htmlFor="rope_access_equipment_19359"
            className="ml-2 w-1/2 text-sm font-medium text-gray-700">
            Inspect/Maintain Rope Access Equipment US 19359
          </label>
          <input
            id="rope_access_equipment_19359"
            name="rope_access_equipment_19359"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={values.rope_access_equipment_19359}
            onChange={(e) => handleCheckbox(e, 'rope_access_equipment_19359')}
          />
          <div className="ml-4 w-1/3">
            <DateSelect
              title="Refresher Due"
              id="rope_access_equipment_19359_refresher_due"
              value={values.rope_access_equipment_19359_refresher_due}
              onChange={setFieldValue}
            />
          </div>
				</div>
        <div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="rope_access_equipment_19359_file"
						setFieldValue={setFieldValue}
						value={values.rope_access_equipment_19359_file}
					/>
				</div>
				<div className="flex items-center px-2">
          <label
            htmlFor="elevated_walk_platform"
            className="ml-2 w-1/2 text-sm font-medium text-gray-700">
            Elevated Walk Platform
          </label>
          <input
            id="elevated_walk_platform"
            name="elevated_walk_platform"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={values.elevated_walk_platform}
            onChange={(e) => handleCheckbox(e, 'elevated_walk_platform')}
          />
          <div className="ml-4 w-1/3">
            <DateSelect
              title="Refresher Due"
              id="elevated_walk_platform_refresher_due"
              value={values.elevated_walk_platform_refresher_due}
              onChange={setFieldValue}
            />
          </div>
				</div>
        <div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="elevated_walk_platform_file"
						setFieldValue={setFieldValue}
						value={values.elevated_walk_platform_file}
					/>
				</div>
				<div className="flex items-center px-2">
          <label
            htmlFor="incline_lifts_training"
            className="ml-2 w-1/2 text-sm font-medium text-gray-700">
            Incline Lifts Training
          </label>
          <input
            id="incline_lifts_training"
            name="incline_lifts_training"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={values.incline_lifts_training}
            onChange={(e) => handleCheckbox(e, 'incline_lifts_training')}
          />
          <div className="ml-4 w-1/3">
            <DateSelect
              title="Date Completed"
              id="incline_lifts_training_date_completed"
              value={values.incline_lifts_training_date_completed}
              onChange={setFieldValue}
            />
          </div>
				</div>
        <div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="incline_lifts_training_file"
						setFieldValue={setFieldValue}
						value={values.incline_lifts_training_file}
					/>
				</div>
				<div className="flex items-center px-2">
          <label
            htmlFor="forklift_operator_cert"
            className="ml-2 w-1/2 text-sm font-medium text-gray-700">
            Forklift Operator Certificate
          </label>
          <input
            id="forklift_operator_cert"
            name="forklift_operator_cert"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={values.forklift_operator_cert}
            onChange={(e) => handleCheckbox(e, 'forklift_operator_cert')}
          />
          <div className="ml-4 w-1/3">
            <DateSelect
              title="Date Completed"
              id="forklift_operator_cert_refresher_due"
              value={values.forklift_operator_cert_refresher_due}
              onChange={setFieldValue}
            />
          </div>
				</div>
        <div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="forklift_operator_cert_file"
						setFieldValue={setFieldValue}
						value={values.forklift_operator_cert_file}
					/>
				</div>
				<div className="flex items-center px-2">
          <label
            htmlFor="mobile_crane_3795"
            className="ml-2 w-1/2 text-sm font-medium text-gray-700">
            Mobile Crane US 3795
          </label>
          <input
            id="mobile_crane_3795"
            name="mobile_crane_3795"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={values.mobile_crane_3795}
            onChange={(e) => handleCheckbox(e, 'mobile_crane_3795')}
          />
          <div className="ml-4 w-1/3">
            <DateSelect
              title="Date Completed"
              id="mobile_crane_3795_refresher_due"
              value={values.mobile_crane_3795_refresher_due}
              onChange={setFieldValue}
            />
          </div>
				</div>
				<div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="other_file"
						setFieldValue={setFieldValue}
						value={values.other_file}
					/>
				</div>
			</div>
		</div>
	)
}
