import { Font, StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
	headerContainer: {
		flexDirection: 'row',
		marginTop: 10,
		borderTopColor: '#F3F4F6',
		borderTopWidth: 1,
	},
	heading: {
		marginTop: 10,
	},
	headingText: {
		fontFamily: 'Open Sans',
		fontSize: 9,
	},
	subText: {
		fontFamily: 'Open Sans',
		fontSize: 9,
	},
	headingDisplay: {
		display: 'flex',
		flexDirection: 'row',
	},
	body: {
		marginBottom: 8,
		fontFamily: 'Open Sans',
		fontSize: 9,
	},
	closingContainer: {
		paddingTop: 20,
    // position: 'absolute',
    // bottom: 10,
    // left: 0,
    // right: 0,
    // display: 'flex',
		// paddingBottom: 70,
		// paddingLeft: 50,
    // flexDirection: 'column',
  },
})

Font.register({
	family: 'Open Sans',
	fonts: [
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
		},
		{
			src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
			fontWeight: 600,
		},
	],
})
