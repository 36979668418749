import React from 'react'
import { DateSelect, CreateFile } from 'common'
import { IStaffRow } from 'models/staff.model'

type FallArrestProps = {
	values: IStaffRow
	setFieldValue: (
		field: string,
		value: string | number | boolean | Date
	) => void
}

export const FallArrest: React.FC<FallArrestProps> = ({
	values,
	setFieldValue,
}) => {
  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
		const isChecked = e.target.checked;
		setFieldValue(field, isChecked);
	};

	return (
		<div>
			<h3 className="px-4 py-2 text-lg font-semibold leading-5 ">
				Fall Arrest
			</h3>
			<div>
				<div className="flex items-center px-2">
          <label
            htmlFor="fall_arrest_23229"
            className="ml-2 w-1/4 text-sm font-medium text-gray-700">
            Fall Arrest US 23229
          </label>
          <input
            id="fall_arrest_23229"
            name="fall_arrest_23229"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={values.fall_arrest_23229}
            onChange={(e) => handleCheckbox(e, 'fall_arrest_23229')}
          />
          <div className="ml-4 w-1/3">
            <DateSelect
              title="Refresher Due"
              id="fall_arrest_23229_refresher_due"
              value={values.fall_arrest_23229_refresher_due}
              onChange={setFieldValue}
            />
          </div>
				</div>
				<div className="flex items-center px-2">
          <label
            htmlFor="fall_arrest_15757"
            className="ml-2 w-1/4 text-sm font-medium text-gray-700">
            Fall Arrest US 15757
          </label>
          <input
            id="fall_arrest_15757"
            name="fall_arrest_15757"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
            checked={values.fall_arrest_15757}
            onChange={(e) => handleCheckbox(e, 'fall_arrest_15757')}
          />
          <div className="ml-4 w-1/3">
            <DateSelect
              title="Refresher Due"
              id="fall_arrest_15757_refresher_due"
              value={values.fall_arrest_15757_refresher_due}
              onChange={setFieldValue}
            />
          </div>
				</div>
				<div className="w-1/2 pl-2">
					<h4 className="text-md font-normal leading-5 px-2">File</h4>
					<CreateFile
						field="fall_arrest_file"
						setFieldValue={setFieldValue}
						value={values.fall_arrest_file}
					/>
				</div>
			</div>
		</div>
	)
}
