import { useState } from 'react'
import {
	DateSelect,
	Dropdown,
	Input,
	SideModal,
	Spinner,
	TextArea,
} from 'common'
import { useFormik } from 'formik'
import { active_inactive_options } from 'models'
import { job_status_options, job_status_options_Edit } from 'models/jobs.model'
import moment from 'moment'
import { useEffect } from 'react'
import {
	AddressServices,
	ClientServices,
	ContactServices,
	JobsServices,
	StaffServices,
} from 'services'
import {
	numberFormat,
	OptionsForDropdown,
	OptionsForDropdownFilter,
} from 'utilities'
import * as Yup from 'yup'
import { VariationTaskForm } from './VariationForm'
import Autocomplete from 'react-google-autocomplete'
import { AppConfig, AppRoutes } from 'config'
import {
	ExclamationCircleIcon,
	PlusCircleIcon,
} from '@heroicons/react/24/solid'
import { CreateClientsForm } from 'components/Clients'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { ContacsForm } from 'components/Contacts'

const jobTypeOptions = [
	{
		value: 'Day Works',
		label: 'Day Works',
	},
	{
		value: 'Safety Nets Commercial',
		label: 'Safety Nets Commercial',
	},
	{
		value: 'Safety Nets Residential',
		label: 'Safety Nets Residential',
	},
	{
		value: 'Edge Protection',
		label: 'Edge Protection',
	},
	{
		value: 'Edge Combo',
		label: 'Edge Combo',
	},
	{
		value: 'MYOB',
		label: 'MYOB',
	},
	{
		value: 'Shrinkwrap',
		label: 'Shrinkwrap',
	},
	{
		value: 'Scrim',
		label: 'Scrim',
	},
	{
		value: 'Bidum Cloth',
		label: 'Bidum Cloth',
	},
]

const brandingOptions = [
	{
		value: 'Protrade',
		label: 'Protrade Scaffold',
	},
]

const branchOptions = [
	{
		value: 'Hawkes Bay',
		label: 'Hawkes Bay',
	},
	{
		value: 'Gisborne',
		label: 'Gisborne',
	},
	{
		value: 'Wairarapa',
		label: 'Wairarapa',
	},
	{
		value: 'Taupo',
		label: 'Taupo',
	},
	{
		value: 'Dunedin',
		label: 'Dunedin',
	},
	{
		value: 'Cromwell',
		label: 'Cromwell',
	},
]

const hourlyRateOptions = [
	{
		value: 75,
		label: '$75',
	},
	{
		value: 85,
		label: '$85',
	},
	{
		value: 93,
		label: '$93',
	},
	{
		value: 120,
		label: '$120',
	},
	{
		value: 150,
		label: '$150',
	},
]

const sqmRateOptions = [
	{
		value: 1.8,
		label: '$1.80',
	},
	{
		value: 2.2,
		label: '$2.20',
	},
	{
		value: 2.5,
		label: '$2.50',
	},
	{
		value: 3.0,
		label: '$3.00',
	},
	{
		value: 3.85,
		label: '$3.85',
	},
	{
		value: 6.0,
		label: '$6.00',
	},
	{
		value: 13.0,
		label: '$13.00',
	},
	{
		value: 19.0,
		label: '$19.00',
	},
]

interface Branches {
	[key: string]: string
}

const branches: Branches = {
	'Hawkes Bay': '609 Orchard Road, Camberley, Hastings 4120, New Zealand',
	Gisborne: '213A Stanley Road, Awapuni, Gisborne 4010, New Zealand',
	Wairarapa: '20 Ahumahi Road, Waingawa 5791, New Zealand',
	Taupo: '257 Tauhara Road, Tauhara, Taupō 3330, New Zealand',
	Dunedin: '56 Teviot Street, South Dunedin, Dunedin 9012, New Zealand',
	Cromwell: '19 McNulty Road, Cromwell 9310, New Zealand',
}

interface AddressDTO {
	client_id?: number
	street_number: string
	route: string
	sublocality: string
	locality: string
	country: string
	postal_code: string
	formatted_address: string
}

interface IProps {
	job_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IinitialValues {
	client_id: number
	job_type: string
	branding: string
	site: string
	start_date: string
	end_date: string
	job_status: string
	operation_notes: string
	status: string
	PO_Number: string
	Requester: string
	type: string
	description: string
	total_hours: number
	percentage_erect: number
	percentage_dismantle: number
	percentage_complete: number
	LastEditDate: string
	hire_rate: number | null
	task_value: number | null
	branch: string
	sqm_rate: number
	hourly_rate: number
	protrade_sales_person: string
	distance: string
}

export const DayWorksForm = ({
	job_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const googleApiKey = AppConfig.GoogleMapsApiKey
	const [jobType, setJobType] = useState('')
	const [hourlyRate, setHourlyRate] = useState('')
	const [sqmRate, setSqmRate] = useState('')
	const [clientForm, setClientForm] = useState(false)
	const [validationSchema, setValidationSchema] = useState({})
	const { data: clientsData, isLoading: clientsLoading } =
		ClientServices.useClients()
	const { data: contactsData } = ContactServices.useContacts()
	const { createTask } = JobsServices.useCreateTask()
	// const { createTask } = JobsServices.
	// const { data: addressData } = AddressServices.useAddressesByClientId(formik.values.client_id);
	const { data: addressData, isLoading: addressLoading } =
		AddressServices.useAddresses()
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()
	const [activeStaffData, setActiveStaffData] = useState<any>([])
	const [contactForm, setContactForm] = useState(false)
	const [selectedPlace, setSelectedPlace] = useState<any | null>(null)

	useEffect(() => {
		if (!staffLoading) {
			const activeStaff = staffData
				?.filter((staff) => staff.status === 'Active')
				?.sort((a, b) => a.staff_name.localeCompare(b.staff_name))
			setActiveStaffData(activeStaff)
		}
	}, [staffData, staffLoading])

	const { createJob } = JobsServices.useCreateJob()
	const { update } = JobsServices.useUpdateJob()
	const { data: jobData, isLoading: jobLoading } = JobsServices.useJobById(
		job_id || undefined
	)
	const userState = useSelector((store: AppStore) => store.user)
	const navigate = useNavigate()

	const initialValues: IinitialValues = {
		client_id: jobData?.client_id || '',
		job_type: jobData?.job_type || 'Day Works',
		branding: 'Protrade',
		site: jobData?.site || '',
		start_date: jobData?.start_date
			? moment(jobData.start_date).format('DD/MM/YYYY').toString()
			: moment().format('DD/MM/YYYY').toString(),
		end_date: jobData?.end_date
			? moment(jobData.end_date).format('DD/MM/YYYY').toString()
			: '',
		job_status: jobData?.job_status || 'Pending Handover',
		operation_notes: jobData?.operation_notes || '',
		status: jobData?.status || 'Active',
		description: '',
		percentage_erect: 0,
		percentage_dismantle: 0,
		percentage_complete: 0,
		total_hours: 0,
		LastEditDate: '',
		PO_Number: '',
		Requester: '',
		type: '',
		hire_rate: 0,
		task_value: 0,
		branch: '',
		sqm_rate: jobData?.clientData?.sqm_rate || 1.8,
		hourly_rate: jobData?.clientData?.hourly_rate || 93,
		protrade_sales_person: jobData?.protrade_sales_person || '',
		distance: jobData?.distance || '',
	}

	const formik = useFormik<IinitialValues>({
		initialValues,
		validationSchema: Yup.object(validationSchema),
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create') {
				const JobData = {
					client_id: values.client_id,
					job_type: values.job_type,
					branding: values.branding,
					site: values.site,
					start_date: values.start_date,
					end_date: values.end_date,
					job_status: values.job_status,
					descriptionOfQuote: values.description,
					branch: values.branch,
					protrade_sales_person: values.protrade_sales_person,
					distance: values.distance,
				}
				const jobResponse = await createJob(JobData)
				const {
					PO_Number,
					Requester,
					description,
					percentage_erect,
					percentage_dismantle,
					total_hours,
					task_value,
					hire_rate,
					sqm_rate,
					hourly_rate,
				} = values

				const data = {
					PO_Number,
					Requester,
					description,
					percentage_erect,
					percentage_dismantle,
					total_hours,
					LastEditDate: moment(values.LastEditDate, 'DD/MM/YYYY').toDate(),
					task_value,
					hire_rate,
					task_type: 'Task',
					type: values.job_type === 'MYOB' ? 'Standard time' : values.job_type,
					zone_label: 'Section 1',
					zone: 1,
					created_by: userState.name || '',
					sqm_rate,
					hourly_rate,
				}

				await createTask(Number(jobResponse.data.job_id), data)

				navigate(
					AppRoutes.privateRoutes.JobDetails.replace(
						':id',
						jobResponse.data.job_id || ''
					)
				)
			}
			if (formType === 'update' && job_id) {
				const JobData = {
					site: values.site,
					start_date: values.start_date,
					end_date: values.end_date,
					job_status: values.job_status,
					operation_notes: values.operation_notes,
					status: values.status,
				}
				await update(job_id, JobData)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		setValidationSchema({
			client_id: Yup.string().required('The client is required'),
			job_type: Yup.string().required('The job type is required'),
			branch: Yup.string().required('The branch is required'),
			// Requester: Yup.string().required('Requester is required'),
			// type: Yup.string().required('Type is required'),
			end_date: Yup.string().optional(), // was string().required('Finish Date is required')
			description: Yup.string().required('Description is required'),
			// percentage_erect: Yup.number().typeError(
			// 	'Percentage Erect must be number'
			// ),
			// percentage_dismantle: Yup.number().typeError(
			// 	'Percentage Dismantle must be number'
			// ),
			// hire_rate: Yup.number()
			// 	.typeError('Hire Rate must be number')
			// 	.required('Weekly Hire Rate is required'),
			total_hours: Yup.number()
				.typeError('Total Hours must be number')
				.required('Total Hours is required'),
			task_value: Yup.number()
				.typeError('Task Value must be number')
				.required('Task Value is required'),
			protrade_sales_person: Yup.string().required(
				'Protrade Sales Person is required'
			),
			site: Yup.string().required('Site is required'),
		})

		formik.setFieldValue('job_status', 'In Progress')
	}, [formik.values.job_type])

	useEffect(() => {
		if (addressData && addressData.length > 0) {
			const address: AddressDTO | undefined = addressData.find(
				(address: any) => address.client_id === formik.values.client_id
			)
			if (address !== null && address !== undefined) {
				formik.setFieldValue('site', address.formatted_address)
			}
		}

		if (
			formik.values.job_type === 'Day Works' ||
			formik.values.job_type === 'MYOB'
		) {
			if (clientsData && clientsData.length > 0) {
				const client = clientsData.find(
					(client: any) => client.id === formik.values.client_id
				)
				if (client) {
					formik.setFieldValue('hourly_rate', client.hourly_rate)
					formik.setFieldValue('sqm_rate', client.sqm_rate)
				}
			}
		} else {
			if (formik.values.job_type === 'Safety Nets Residential') {
				formik.setFieldValue('sqm_rate', 6.0)
			} else if (formik.values.job_type === 'Safety Nets Commercial') {
				formik.setFieldValue('sqm_rate', 13.0)
			} else if (formik.values.job_type === 'Edge Protection') {
				formik.setFieldValue('sqm_rate', 19.0)
			} else if (
				formik.values.job_type === 'Shrinkwrap' ||
				formik.values.job_type === 'Bidum Cloth'
			) {
				formik.setFieldValue('sqm_rate', 3.85)
			} else if (formik.values.job_type === 'Scrim') {
				formik.setFieldValue('sqm_rate', 3.0)
			}
		}
	}, [formik.values.client_id, formik.values.job_type])

	useEffect(() => {
		if (selectedPlace && formik.values.branch) {
			handlePlaceSelect(selectedPlace, formik.values.branch)
		}
	}, [selectedPlace, formik.values.branch])

	const makeHttpRequest = (
		url: string,
		method: string,
		requestData: any,
		headers: any
	) => {
		return new Promise<any>((resolve, reject) => {
			const xhr = new XMLHttpRequest()
			xhr.open(method, `${url}`, true)
			Object.keys(headers).forEach((key) => {
				xhr.setRequestHeader(key, headers[key])
			})
			xhr.onreadystatechange = () => {
				if (xhr.readyState === XMLHttpRequest.DONE) {
					if (xhr.status === 200) {
						resolve(JSON.parse(xhr.responseText))
					} else {
						reject(xhr.statusText)
					}
				}
			}
			xhr.onerror = () => reject(xhr.statusText)
			xhr.send(JSON.stringify(requestData))
		})
	}

	const getAddress = (branchT: string): string => {
		const address = branches[branchT]
		if (address) {
			return address
		} else {
			throw new Error(`Branch not found: ${branchT}`)
		}
	}

	const handlePlaceSelect = async (place: any, selectedBranch: string) => {
		const origin = getAddress(selectedBranch)

		const destination = place.formatted_address

		const url = `https://routes.googleapis.com/directions/v2:computeRoutes`
		const requestData = {
			origin: {
				address: origin,
			},
			destination: {
				address: destination,
			},
			travelMode: 'DRIVE',
			units: 'METRIC',
		}

		const headers = {
			'Content-Type': 'application/json',
			'X-Goog-Api-Key': googleApiKey,
			'X-Goog-FieldMask':
				'routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline',
		}

		try {
			const response = await makeHttpRequest(url, 'POST', requestData, headers)
			const distance = (response.routes[0].distanceMeters / 1000).toFixed(1)
			formik.setFieldValue('distance', distance)
		} catch (error) {
			console.error(error)
		}
	}

	if ((job_id && clientsLoading) || jobLoading || addressLoading) {
		return <Spinner />
	}

	const client = clientsData?.filter(
		(client: any) => client.id === formik.values.client_id
	)
	const contacts = contactsData?.filter(
		(contact: any) => client?.[0]?.id === contact.client_id
	)

	// console.log(clientsData)

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				{formType === 'create' && (
					<>
						<div className="flex items-center px-3 pb-4 pt-2">
							<div className="flex flex-col w-full">
								<Dropdown
									label="Client"
									id="client_id"
									options={OptionsForDropdownFilter(
										clientsData,
										'id',
										'client_name',
										'status',
										'Active'
									)}
									value={formik.values.client_id}
									onChange={formik.setFieldValue}
									onBlur={formik.setFieldTouched}
									error={formik.errors.client_id}
								/>
								<div className="flex items-center pl-2">
									<PlusCircleIcon className="w-6 h-6 text-indigo-500" />
									<button
										type="button"
										className="pl-1 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
										onClick={() => setClientForm(true)}>
										Add New Client
									</button>
								</div>
							</div>
							<div className="w-full pb-6">
								<Dropdown
									label="Job Type"
									id="job_type"
									options={jobTypeOptions}
									value={formik.values.job_type}
									onChange={formik.setFieldValue}
									onBlur={formik.setFieldTouched}
									error={formik.errors.job_type}
								/>
							</div>
						</div>
						<div className="flex items-center px-3 pb-4 pt-2">
							<div className="w-1/2">
								<Dropdown
									id="Requester"
									label="Contact"
									options={OptionsForDropdown(contacts, 'name', 'name')}
									value={formik.values.Requester}
									onChange={formik.setFieldValue}
									onBlur={formik.handleBlur}
									error={formik.errors.Requester}
								/>
								<div className="flex items-center pl-2 ">
									<PlusCircleIcon className="w-6 h-6 text-indigo-500" />
									<button
										type="button"
										className={clsx(
											!(client?.[0]?.id ? true : false)
												? 'text-gray-200'
												: 'text-gray-600 hover:text-gray-800',
											'pl-1 font-semibold leading-5 text-sm'
										)}
										disabled={client?.[0]?.id ? false : true}
										onClick={() => setContactForm(true)}>
										Add New Contact
									</button>
								</div>
							</div>
						</div>
						<div className="flex flex-col">
							<div className="flex items-center px-3">
								{/* <div className='pt-6 w-full'>
									<Dropdown
										label="Branding"
										id="branding"
										options={brandingOptions}
										value={formik.values.branding}
										onChange={formik.setFieldValue}
										onBlur={formik.setFieldTouched}
										disabled
									/>
								</div> */}

								<div className="w-full px-2 py-2 h-full">
									<label className="block mb-1 text-sm font-medium text-gray-700">
										Address Search
									</label>
									<Autocomplete
										key={formik.values.branch}
										apiKey={googleApiKey}
										placeholder=""
										style={{ width: '100%' }}
										className="autocomplete border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-[40px] rounded-md shadow-sm pl-3 "
										onPlaceSelected={(place) => {
											formik.setFieldValue('site', place.formatted_address)
											setSelectedPlace(place)
										}}
										options={{
											types: ['address'],
											componentRestrictions: { country: 'nz' },
										}}
									/>
								</div>
							</div>
							<div className="flex items-center px-6 pb-3">
								<div>
									<span className="font-semibold">
										Current Selected Address:
									</span>
									<br /> {formik.values.site}
									<br />{' '}
									{formik.errors.site ? (
										<p
											className="mt-1 text-sm text-red-600 flex items-center"
											id="dropdown-error">
											{formik.errors.site && (
												<>
													<ExclamationCircleIcon
														className="w-5 h-5 text-red-500"
														aria-hidden="true"
													/>
													<span className="ml-2">{formik.errors.site}</span>
												</>
											)}
										</p>
									) : null}
								</div>
							</div>
						</div>
					</>
				)}

				<>
					<div className="flex items-center px-3">
						<div className="w-1/2">
							<DateSelect
								title="Start Date"
								id="start_date"
								value={formik.values.start_date}
								onChange={formik.setFieldValue}
							/>
						</div>
						{/* <DateSelect
							title="Finish Date"
							id="end_date"
							value={formik.values.end_date}
							onChange={formik.setFieldValue}
							error={formik.errors.end_date}
						/> */}
					</div>
					<div className="flex items-center px-3">
						<div className="w-1/2">
							<Dropdown
								label="Job Status"
								id="job_status"
								options={
									formType === 'create'
										? job_status_options
										: job_status_options_Edit
								}
								value={formik.values.job_status}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
								disabled={formType === 'create'}
							/>
						</div>
						<div className="w-1/2">
							<Dropdown
								label="Branch"
								id="branch"
								options={branchOptions}
								value={formik.values.branch}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
								error={formik.errors.branch}
							/>
						</div>
					</div>
					<div className="flex items-center px-3">
						<Dropdown
							label="Hourly Rate"
							id="hourly_rate"
							options={hourlyRateOptions}
							value={formik.values.hourly_rate}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							error={formik.errors.hourly_rate}
						/>
						<Dropdown
							label="SQM Rate"
							id="sqm_rate"
							options={sqmRateOptions}
							value={formik.values.sqm_rate}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							error={formik.errors.sqm_rate}
						/>
					</div>
					<div className="flex items-center px-3">
						<Dropdown
							id="protrade_sales_person"
							label="Protrade Sales Person"
							options={OptionsForDropdown(activeStaffData, 'id', 'staff_name')}
							value={formik.values.protrade_sales_person}
							onChange={formik.setFieldValue}
							error={formik.errors.protrade_sales_person}
						/>
					</div>
				</>
				<VariationTaskForm
					formik={formik}
					formType="create"
					clientsData={clientsData}
					contactsData={contactsData}
					client_id={formik.values.client_id}
				/>
				<CreateClientsForm
					open={clientForm}
					setOpen={setClientForm}
					heading="Create New Client"
					formType="create"
				/>
				<ContacsForm
					formType="create"
					heading="Create Contact"
					client_id={formik.values.client_id}
					open={contactForm}
					setOpen={setContactForm}
				/>
			</SideModal>
		</>
	)
}
